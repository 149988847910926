import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import "./Descargas.css";

// import qr from "../../../assets/images/QR_Code.png";
import DownloadCard from "./DownloadCard";
import Welcomi_rounded from "../../../assets/images/welcomi_rounded.png"
import Captain_rounded from "../../../assets/svgs/captain_rounded.svg"
import Guard_rounded from "../../../assets/svgs/guard_rounded.svg"
import Constants from "../../../utils/Constants"
import { Helmet } from "react-helmet";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Descargas = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {children}
          <Helmet>
            <title>Descargas | Welcomi</title>
            <meta name="description"
              content="Descarga gratis Welcomi, Welcomi Captain o Welcomi Guard." />
            <base href="/descargas"></base>
          </Helmet>

          <div className="title">
            <h2
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Ya estas un paso más cerca de formar parte de la familia{" "}
              <span className="text-color-primary">Welcomi</span>.
            </h2>
          </div>


          <DownloadCard
            title="Eres capitán de una zona residencial?"
            description="Esta aplicación va dirigida a administradores de zonas residenciales. En ella podrás administrar tu zona residencial desde tu celular."
            app_img={Captain_rounded}
            href_ios={Constants.download_links.ios_captain}
            href_android={Constants.download_links.android_captain}
          />

          <DownloadCard
            title="Para residentes de zonas residenciales o particulares"
            description="Si eres residente de una zona residencial o tienes tu hogar particular, esta es la aplicación que debes descargar. ¿Qué esperas? Vive tu hogar y comunidad ahora desde la palma de tu mano."
            app_img={Welcomi_rounded}
            href_ios={Constants.download_links.ios_resident_visitor}
            href_android={Constants.download_links.android_resident_visitor}
          />

          <DownloadCard
            title="Eres personal de vigilancia de una zona residencial?"
            description="Welcomi Guard te ayuda con tus labores. Un control de accesos echo a la medida, comunicados y mucho más."
            app_img={Guard_rounded}
            href_ios={Constants.download_links.ios_guard}
            href_android={Constants.download_links.android_guard}
          />

        </div>
      </div>
    </section>
  );
};

Descargas.propTypes = propTypes;
Descargas.defaultProps = defaultProps;

export default Descargas;

import React from "react"
import { Helmet } from "react-helmet"
import { Element } from "react-scroll"

import HeroR from "./HeroR"
import Prices from "../Pricing/Prices"
import TimeLineR from "./TimeLineR"
import ContactNow from "../Landings/ContactNow"
import ReactPlayer from 'react-player'
import MktMailBoxR from './MktMailBoxR'
import DefinitionsR from "./DefinitionsR"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import "./Security_R.css"
import { Typography } from "@material-ui/core"
import RequestPlanFormR from "./RequestPlanFormR"
// import LifeStyle_R from "./LifeStyle_R"
// import ContactBox_R from "./ContactBox_R"
// import ReactPixel from 'react-facebook-pixel'

const HomeResidencial = () => {
  //  Deberia sacar el valor de u y el valor del id a un .env
  //  TUTORIAL: https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965
  //  EJEMPLO:     const postUrl = `https://genhybridsystems.us1.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`

  const postUrl = `https://app.us20.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`

  return (
    <>
      <Helmet>
        <title>Welcomi | Reinventamos la seguridad residencial</title>
        <meta
          name="description"
          content="Control de accesos, comunicados y mucho más. Welcomi es la mejor opción para tu zona residencial, pruebala ahora, es gratis!!"
        />
        {/* <!-- Set the base URL for all relative URLs within the document --> */}
        {/* <base href="https://welcomi.app/"></base> */}
      </Helmet>
      <HeroR className="illustration-section-01" />


      <div >
        <h3 style={{
          color: "white",
          textAlign: "center",
          marginTop: 0,
          marginBottom: 0,
          backgroundColor: 'rgb(250, 141, 29)'
        }}>Recibe más información en tu correo o WhatsApp!</h3>
        <MailchimpSubscribe
          url={postUrl}
          render={({ subscribe, status, message }) => (
            <MktMailBoxR
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </div>

      <Typography variant="h3" style={{
        color: "white",
        textAlign: "center",
        backgroundColor: 'rgb(250, 141, 29)'
      }}>
        Conoce Welcomi
      </Typography>

      <div className="player-wrapper">
        <ReactPlayer
          url={"https://www.youtube.com/watch?v=csD2_ncVtT8"}
          style={{ marginBottom: 25 }}
          progressInterval={true}
          className="react-player"
        />
      </div>

      {/* <button
            type="button"
            onClick={() =>
                ReactPixel.trackCustom('SeRegistro', {
                  content_name: 'Really Fast Running Shoes',
                  content_category: 'Apparel & Accessories > Shoes',
                  content_ids: ['1234'],
                  content_type: 'product',
                  value: 0.5,
                  currency: 'USD',
                })
                // ReactPixel.track('SeRegistro', {
                //     content_name: 'Really Fast Running Shoes',
                //     content_category: 'Apparel & Accessories > Shoes',
                //     content_ids: ['1234'],
                //     content_type: 'product',
                //     value: 0.5,
                //     currency: 'USD',
                // })
            }
        >
            Track
        </button> */}

      <DefinitionsR />
      {/* <ContactBox_R /> */}
      <TimeLineR />
      {/* <Element name="Prices" className="element">
        <Prices no_inner={true} />
      </Element> */}
      <Element name="RequestPlanForm" className="element">
        <RequestPlanFormR />
      </Element>
      <ContactNow />
    </>
  )
}

export default HomeResidencial
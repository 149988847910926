import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import "../../../components/Residencial/AdStyles/General.css"
import Constants from '../../../utils/Constants';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

const DPollContainer = (props) => {
    const classes = useStyles();

    const {
        poll_image,
        profile_image,
        posted_by,
        created_at,
        poll_description,
        poll_question,
        answers
    } = props

    const date = created_at;
    const hour = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div className='postWithImageWelcomiCard'>

                {
                    poll_image &&
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <img
                            src={poll_image}
                            alt="img"
                            style={{
                                width: "100%",
                                height: "10%",
                                resize: "cover",
                                borderTopRightRadius: 20,
                                borderTopLeftRadius: 20,
                            }}
                        />
                    </div>
                }

                <div style={styles.footer}>
                    <div>

                        <Avatar
                            alt="Welcomi user"
                            src={profile_image}
                            className={classes.large}
                        />
                    </div>

                    <div style={styles.textContainer}>
                        <div styles={styles.nameContainer}>
                            <Typography
                                style={styles.nameText}
                            >
                                {posted_by}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="caption" >
                                {date.getDate() + "/" + Constants.months[date.getMonth()] + "/" + date.getFullYear() + " " + hour + ":" + min}
                            </Typography>
                        </div>

                    </div>
                </div>

                <div
                    style={{
                        marginLeft: 10,
                        marginRight: 10
                    }}
                >

                    <div>
                        <Typography variant="h6" >
                            {poll_question}
                        </Typography>
                    </div>

                    <div>
                        <Typography variant="caption" style={{ color: "gray" }} >
                            {poll_description}
                        </Typography>
                    </div>

                    <div style={{ marginTop: 25 }}>
                        {
                            answers.map((answ, index) => (
                                <div>
                                    <Typography
                                        key={answ.id}
                                        variant='subtitle2'
                                        style={{
                                            fontStyle: "italic",
                                            textAlign: "center"
                                        }}
                                    >
                                        {answ.votes} residentes han votado por "{answ.choice}"
                                    </Typography>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DPollContainer;

const styles = {
    footer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 5
    },
    nameContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    nameText: {
        fontSize: 24,
        fontWeight: "bold",
        color: Constants.colors.primaryColor,
        width: "80%"
    },
    textContainer: {
        width: "78%",
        flexDirection: "column",
        display: "flex"
    },
}
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';
import EmailIcon from '@material-ui/icons/Email';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const ALocalities = () => {
    const history = useHistory();

    return (
        <div>
            <p>Menu de localidades</p>
            <List component="nav" aria-label="main">
                <ListItemLink
                    onClick={() => history.push("/localities/all")}
                >
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Listado de localidades" />
                </ListItemLink>

                <ListItemLink
                    onClick={() => history.push("/mails_mailchimp")}
                >
                    <ListItemIcon>
                        <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Emails faltantes en Mailchimp" />
                </ListItemLink>
            </List>
        </div>
    );
}

export default ALocalities;
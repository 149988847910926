
import ReactGA from "react-ga";

export default function AndroidTrack() {
    ReactGA.event({
        category: "Android download button",
        action: "User pressed Android download button",
        label: "Android Download",
        value: 1
    })
}
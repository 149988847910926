import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from 'react-router-dom';
import { AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import WelcomiResidencial from "../assets/svgs/W_Residencial.svg"

const useStyles = makeStyles({
    root: {
        flexGrow: 1,

    },
    content: {
        // flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
});

const LayoutDemo = ({ children }) => {
    const classes = useStyles();
    const history = useHistory();

    const [value, setValue] = React.useState(0);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;


    return (
        <>
            <AppBar position="static"
                style={{
                    backgroundColor: "white",
                    position: 'fixed',
                    top: 0,
                }}>
                <Toolbar>
                    <div className={classes.title}>
                        <img
                            src={WelcomiResidencial}
                            alt={"Welcomi Residencial"}
                            style={{ width: "20%" }}
                        />
                    </div>


                    <Typography variant="caption" >
                        Demostración
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* main content */}
            <main
                lassName={classes.content}
                style={{
                    marginTop: isMobile ? "15%" : "8%"
                }}
            >
                {children}
            </main>

            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                style={{
                    height: 70,
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    // paddingBottom: 15
                }}
            >
                <BottomNavigationAction
                    label="Recents"
                    icon={<RestoreIcon />}
                    onClick={() => {
                        history.push("/")
                    }}
                    style={{ paddingTop: 0 }}
                />
                <BottomNavigationAction
                    label="Favorites"
                    icon={<FavoriteIcon />}
                    onClick={() => {
                        history.push("/community")
                    }}
                    style={{ paddingTop: 0 }}
                />

                <BottomNavigationAction
                    label="Nearby"
                    icon={<LocationOnIcon />}
                    onClick={() => {
                        history.push("/General")
                    }}
                    style={{ paddingTop: 0 }}
                />
            </BottomNavigation>
        </>
    );
}

export default LayoutDemo;
import React, { useContext } from 'react';
import { AdminContext } from '../../../Context/AdminContext';

const ADashboard = () => {
    const { admin } = useContext(AdminContext)

    return (
        <div>
            <h1>{admin.email}</h1>
        </div>
    )
}

export default ADashboard;
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import ReviewingRequest from "../../../assets/svgs/ReviewingRequest.svg"
const propTypes = {
    children: PropTypes.node,
    ...SectionProps.types,
};

const defaultProps = {
    children: null,
    ...SectionProps.defaults,
};


const RequestPlanForm_R_Thanks = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const outerClasses = classNames(
        "section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    return (
        <section {...props} className={outerClasses}>
            <div className="container">

                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <h2>Solicitud enviada</h2>
                    <img src={ReviewingRequest} alt="Welcomi lifestyle" style={{ fill: 'red', width: "70%", alignSelf: "center" }} />
                    <p>Muchas gracias, hemos recibido tu solicitud, un agente se comunicara contigo en las próximas 24 horas hábiles para indicarte los pasos siguientes.</p>
                </div>

            </div>
        </section>
    )
}

export default RequestPlanForm_R_Thanks;
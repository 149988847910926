import React from "react";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { Button } from "@material-ui/core";
import Constants from "../../../utils/Constants"
import "./ContactNow.css"
import WappTrack from "../../../cases/GoogleAnalytics.js/WappTrack";
const ContactNow = () => {

    return (
        <div>

            {/* <div className="contactNowButton">
                <Button
                    variant='contained'
                    style={{
                        backgroundColor: Constants.colors.primaryColor,
                        color: "white",
                        fontWeight: "bold",
                        width: "60%"
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/precios'
                    }}
                >
                    Unirme a Welcomi ahora
                </Button>
            </div> */}

            <div className="contactNowIcons">


                <div className="wappBttns">
                    <a
                        // href="https://wa.me/message/SFNH574RQRI5J1"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                            e.preventDefault();
                            WappTrack()
                            window.location.href = 'https://wa.me/message/SFNH574RQRI5J1'
                        }}
                    >
                        <WhatsAppIcon
                            titleAccess="Contactar Whatsapp"
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize="large"
                            style={{ color: "white" }}
                        />
                        <p style={{ color: "white" }}>Envianos Whatsapp</p>
                    </a>
                </div>

                <div className="callBttns">
                    <a
                        href="tel:+528332181663"
                        rel="noopener noreferrer"
                    >
                        <PhoneInTalkIcon
                            titleAccess="Contactar Welcomi"
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize="large"
                            style={{ color: "white" }}
                        />
                        <p style={{ color: "white", fontWeight: "bold" }}>Llamanos ahora!</p>
                    </a>
                </div>
            </div>
        </div >
    )
}

export default ContactNow;
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import GetProfile from '../components/Captain/Dashboard/GetProfile';
import CLogin from '../components/Captain/Login/CLogin';
import { CaptainContext } from '../Context/CaptainContext';
import AppRoute from '../utils/AppRoute';

const CaptainNav = () => {

    const { verifyAuth, isLoggedIn } = useContext(CaptainContext);

    const [loading, setLoading] = useState(true);
    return (
        !isLoggedIn ?
            <>
                <Redirect to='/' />
                <AppRoute
                    exact
                    path="/"
                    component={CLogin} />
            </>
            :
            <>
                <Redirect to="/profile" />
                <AppRoute
                    path="/profile"
                    component={GetProfile}
                />
            </>
    );
}

export default CaptainNav;
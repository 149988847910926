import React from "react";
import { SectionProps } from "../../../utils/SectionProps";
import "./Hero_ResidentialLanding.css"
import Constants from "../../../utils/Constants";
import IOSTrack from "../../../cases/GoogleAnalytics.js/IOSTrack";
import AndroidTrack from "../../../cases/GoogleAnalytics.js/AndroidTrack";
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
const propTypes = {
    ...SectionProps.types,
};

const defaultProps = {
    ...SectionProps.defaults,
};


const HeroResidentialLanding = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
}) => {

    const handleTrack = (trackEvent) => {
        if (trackEvent === "iosDownloadTrack") {
            IOSTrack()
        }

        if (trackEvent === "AndroidDownloadTrack") {
            AndroidTrack()
        }
    }
    return (
        // <section {...props} className={outerClasses}>
        // <div className={innerClasses}>
        <div className="landingFullHeightTopBanner top_bg">

            <div style={{ marginTop: "5%" }}>
                <div className="banner_row">

                    <div className="landing_banner_col">
                        <h2 className="landing_banner_title spacer">Welcomi es un <span className="text-color-primary">control de accesos</span> revolucionado para la <span className="text-color-primary">seguridad</span> residencial.</h2>

                        <div>
                            <p className="landing_banner_text spacer">Mantén el orden de quien entra y quien sale en tu residencial, olvídate de las largas filas en la caseta de acceso y nunca más permitas que un intruso acceda a tu localidad.</p>
                        </div>

                        <div style={{ marginLeft: "3%", alignSelf: "flex-end", display: "flex", justifyContent: "center" }}>

                            <button
                                className="viewMoreBttn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleTrack("AndroidDownloadTrack")
                                    window.location.href = Constants.download_links.android_captain;
                                }}
                            >
                                <AndroidIcon
                                    fontSize="small"
                                    style={{ color: "white" }}
                                />
                                Descarga Android
                            </button>

                            <button
                                className="startNowBttn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleTrack("iosDownloadTrack")
                                    window.location.href = Constants.download_links.ios_captain;
                                }}
                            >
                                <AppleIcon
                                    fontSize="small"
                                    style={{ color: "white" }}
                                />
                                Descarga Apple
                            </button>
                        </div>
                    </div>

                </div>


                <div className="banner_row" />
            </div>
        </div>
    )
}

HeroResidentialLanding.propTypes = propTypes;
HeroResidentialLanding.defaultProps = defaultProps;

export default HeroResidentialLanding;
import { CircularProgress, Modal, Avatar, Typography, List, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { functions } from '../../../Fire';
import Constants from '../../../utils/Constants';
import Zoom from 'react-img-zoom'
import Invitations_SelectCar from './Invitations_SelectCar';
import { GuardContext } from '../../../Context/GuardContext';
import { updateData } from '../../../cases/Firecases/updateData';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "80%",
        maxHeight: "80%",
        // height: "80%",
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    largeAvatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
}));

const Invitations_Modal = ({
    cbClose,
    user_uid,
    inv_data,
    inv_id,
    isSavedGuest
}) => {
    const { guard, guardDocument, guardConf, guardActiveLocality } = useContext(GuardContext)

    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    const [loading, setLoading] = useState(true)
    const [granting, setGranting] = useState(false)
    const [guestData, setGuestData] = useState(null)
    const [isValidated, setIsValidate] = useState(false)
    const [enteredBy, setEnteredBy] = useState(null)
    const [annotation, setAnnotation] = useState("")

    useEffect(() => {
        getGuestData()
    }, [])

    const getGuestData = async () => {
        if (!isSavedGuest) {

            await functions.httpsCallable("userProfileInfoByUID")({
                user_uid: user_uid
            })
                .then(answ => {
                    setGuestData(answ.data)
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    alert("No fue posible encontrar información del usuario, intente nuevamente.")
                    cbClose()
                })
        }

        if (isSavedGuest) {
            await functions.httpsCallable("userProfileInfoByUIDSavedResident")({
                user_uid: user_uid
            })
                .then((answ) => {
                    setGuestData(answ.data)
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    alert("No fue posible encontrar información del usuario, intente nuevamente.")
                    cbClose()
                })
        }
    }

    const cbUserEnteredby = (guestEnteredBy) => {
        if (guestEnteredBy.type === "ExtraCar" || guestEnteredBy.type === "TaxiService") {
            if (!guestEnteredBy.plate) {
                alert("Introduce al menos la placa del vehículo del visitante.")
                return
            }
        }
        setIsValidate(true)
        setEnteredBy(guestEnteredBy)
    }

    const grantAccess = async () => {
        setGranting(true)
        const data = {
            "aalpha.access_kind": "computer",
            "aalpha.checkout_register": guardConf.setup.checkout_register,
            "aalpha.status": !isSavedGuest ? "accepted" : "accepted_saved_guest", // if visitor didn't accept the invitation
            "data.annotation": annotation,
            "data.is_here": true,
            "data.is_here_date": new Date(),
            "data.is_here_auth": guardDocument.doc_data.personal_info.name,
            "data.is_here_auth_email": guardDocument.doc_data.account_info.email,
            "data.is_here_auth_range": "guard",
            "data.is_here_auth_uid": guard.uid,
            'data.modified_at': new Date(),
            "data.user_entered_by": enteredBy,
            "receiver.ID_image": guestData.ID_image,
            "receiver.profile_image": !isSavedGuest ? guestData.profile_image : null,
        }

        let response = await updateData("invitations", inv_id, data)

        let done = response[0]
        let error = response[1]

        if (done) {
            if (inv_data.aalpha.type === "invitation_residential") {
                functions.httpsCallable("w_pushNotificationByUserUID")({
                    user_uid: inv_data.sender.user_uid,
                    heading_en: "Your guest is here🥳!",
                    heading_es: "Tu visita ha llegado🥳!",
                    deeplink: "welcomi://app/Stack_MyHomes",
                    data_en: `Have a good time with ${guestData.name} 😊!!!`,
                    data_es: `Pasa un rato agradable con ${guestData.name} 😊!!!`
                })
            }

            if (inv_data.aalpha.type === "invitation_worker_residential") {
                functions.httpsCallable("w_pushNotificationByUserUID")({
                    user_uid: inv_data.sender.user_uid,
                    heading_en: "Your worker is here 👨🏻‍🔧!",
                    heading_es: "Tu trabajador ha llegado 👨🏻‍🔧!",
                    deeplink: "welcomi://app/Stack_MyHomes",
                    data_en: "",
                    data_es: ""
                })
            }

            !isSavedGuest &&
                functions.httpsCallable("w_pushNotificationByUserUID")({
                    user_uid: guestData.uid,
                    heading_en: "You're Welcomi 🧡",
                    heading_es: "Welcomi 🧡",
                    deeplink: "welcomi://app/Stack_MyHomes",
                    data_en: `We hope you are enjoying the Welcomi experience as much as we enjoy having you here.`,
                    data_es: `Esperamos que estes disfrutando de la experiencia de Welcomi asi como nosotros disfrutamos de tenerte. Bienvenido a ${guardActiveLocality[0].locality_name}.`
                })

            alert("Éxito ✅, acceso registrado.")
            cbClose()
        }

        if (error) {
            console.log(error)
            alert("Error ❌, acceso no registrado, intenta nuevamente.")
            cbClose()
        }
    }

    return (
        <Modal
            open={true}
            onClose={() => {
                !granting && cbClose()
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                {
                    loading &&
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <CircularProgress color='primary' />
                    </div>
                }

                {
                    !loading &&
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>

                            <div style={{ width: "50%" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {
                                        !isSavedGuest &&
                                        <Avatar alt="Welcomi img perfil" src={guestData.profile_image} className={classes.largeAvatar} />
                                    }

                                    <div className='leftTopSeparator'>
                                        <Typography
                                            variant='h6'
                                            color="primary"
                                        >
                                            {guestData.name}
                                        </Typography>

                                        <Typography
                                            variant='subtitle1'
                                        >
                                            {guestData.gender}
                                        </Typography>
                                    </div>
                                </div>

                                <List
                                    component="div"
                                    disablePadding
                                    style={{ marginTop: 15 }}
                                >
                                    <Invitations_SelectCar
                                        cars={guestData.cars.length ? guestData.cars : []}
                                        cbUserEnteredby={cbUserEnteredby}
                                        cbValidate={() => setIsValidate(false)}
                                    />
                                </List>

                                <TextField
                                    name="annotation"
                                    id='annotation'
                                    label="Anotación (opcional)"
                                    type='text'
                                    onChange={(e) => setAnnotation(e.target.value)}
                                    value={annotation}
                                    fullWidth
                                />
                            </div>


                            <div style={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "flex-end"
                            }}>
                                <Zoom
                                    img={guestData.ID_image}
                                    zoomScale={3}
                                    width={400}
                                    height={400}
                                />
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            <Button
                                variant='contained'
                                disabled={!isValidated || granting}
                                style={{
                                    backgroundColor: !isValidated ? null : Constants.colors.primaryColor,
                                    color: "white"
                                }}
                                onClick={grantAccess}
                            >
                                Dar Acceso
                            </Button>
                            {
                                granting &&
                                <CircularProgress color='primary' />
                            }
                        </div>
                    </div>
                }
            </div>

        </Modal>
    );
}

export default Invitations_Modal;

const styles = {
    // fineText: {
    //     fontWeight: "bold",
    //     color: Constants.colors.primaryColor
    // },
}
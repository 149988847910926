import React from 'react';
import { Redirect } from 'react-router-dom';
import DCommunity from '../components/Demo/Community/DCommunity';
import DGeneral from '../components/Demo/General/DGeneral';
import DPosts from '../components/Demo/Posts/DPosts';
import LayoutDemo from '../layouts/LayoutDemo';
import AppRoute from '../utils/AppRoute';

const DemoNav = () => {
    return (
        <LayoutDemo>
            <Redirect to="/" />

            <AppRoute
                exact
                path='/'
                component={DPosts}
            />

            <AppRoute
                exact
                path='/community'
                component={DCommunity}
            />

            <AppRoute
                exact
                path='/general'
                component={DGeneral}
            />
        </LayoutDemo>

    );
}

export default DemoNav;
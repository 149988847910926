import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import "../../../components/Residencial/AdStyles/General.css"
import Constants from '../../../utils/Constants';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

const DPostsContainer = (props) => {
    const classes = useStyles();

    const {
        likes,
        photo_URL,
        date: created_at,
        message,
        profile_image,
        posted_by,
    } = props


    const date = created_at;
    const hour = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div className='postWithImageWelcomiCard'>

                {
                    photo_URL &&
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <img
                            src={photo_URL}
                            alt="img"
                            style={{
                                width: "100%",
                                height: "10%",
                                resize: "cover",
                                borderTopRightRadius: 20,
                                borderTopLeftRadius: 20,
                            }}
                        />
                    </div>
                }


                <div style={styles.footer}>
                    <div>

                        <Avatar
                            alt="Welcomi user"
                            src={profile_image}
                            className={classes.large}
                        />
                    </div>

                    <div style={styles.textContainer}>
                        <div styles={styles.nameContainer}>
                            <Typography
                                style={styles.nameText}
                            >
                                {posted_by}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="caption" >
                                {date.getDate() + "/" + Constants.months[date.getMonth()] + "/" + date.getFullYear() + " " + hour + ":" + min}
                            </Typography>
                        </div>

                        <div>
                            <Typography variant='body1' style={{ marginTop: 15 }}>
                                {message}
                            </Typography>
                        </div>

                        <div
                            style={{
                                marginTop: 15,
                                marginBottom: 15,
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginRight: 5,
                                display: "flex"
                            }}>
                            <Typography variant='subtitle1'>{likes}</Typography>
                            <FavoriteIcon
                                style={{ color: Constants.colors.primaryColor }}
                            />
                        </div>
                    </div>


                </div>
            </div >
        </div>
    );
}

export default DPostsContainer;

const styles = {
    footer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 5
    },
    nameContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    nameText: {
        fontSize: 24,
        fontWeight: "bold",
        color: Constants.colors.primaryColor,
        width: "80%"
    },
    textContainer: {
        width: "78%",
        flexDirection: "column",
        display: "flex"
    },
}
import Fire from "../../Fire"


function delay(t) {
    return new Promise(resolve => { setTimeout(resolve, t); })
}

const keepTrying = async (triesRemaining, storageRef) => {
    // console.log(triesRemaining)
    if (triesRemaining < 0) {
        return Promise.reject("out of tries")
    }

    return await Fire.getDownloadLinkFromRefURL(storageRef).then((url) => {
        return url
    })
        .catch(error => {
            switch (error.code) {
                case 'storage/object-not-found':
                    return delay(2000).then(() => {
                        return keepTrying(triesRemaining - 1, storageRef)
                    })
                default:
                    // console.log(error)
                    return Promise.reject(error)
            }
        })
}

export async function uploadImage(image, imageName, imageResizedName) {

    var link = null;
    var thrownError = null;


    await imageName.put(image)
        .then(async () => {
            await keepTrying(10, imageResizedName)
                .then(url => {
                    link = url;
                    // console.log(url)
                })
                .catch(error => {
                    // console.log(error)
                    thrownError = error
                })
        })
        .catch((error) => {
            // console.log(error)
            switch (error.code) {
                case "storage/unauthorized":
                    thrownError = ["Unauthorized User", "No tienes los permisos suficientes para esta accion."]
                    break;

                case "storage/canceled":
                    thrownError = ["Upload Canceled", "Carga cancelada."]
                    break;

                case "storage/unauthenticated":
                    thrownError = ["Unauthenticated User", "Inicia sesión para poder acceder a esta funcion."]
                    break;

                case "storage/retry-limit-exceeded":
                    thrownError = ["Retry time limit exceeded", "Vuelve a intentar, el tiempo limite fue excedido."]
                    break;

                case "storage/unknown":
                    thrownError = ["unknown error", "Error desconocido, intente en un momento o contactese con Welcomi."]
                    break;

                default:
                    thrownError = ["unknown error", "Error desconocido, intente en un momento o contactese con Welcomi."]
                    break;
            }

        })

    return [link, thrownError]
}
const Constants = {
    download_links: {
        ios_captain: "https://apps.apple.com/mx/app/welcomi-captain/id1534985144",
        ios_guard: "https://apps.apple.com/mx/app/welcomi-guard/id1533897671",
        ios_resident_visitor: "https://apps.apple.com/mx/app/welcomi/id1533608084/",
        android_captain: "https://play.google.com/store/apps/details?id=com.welcomi_captain",
        android_guard: "https://play.google.com/store/apps/details?id=com.welcomi_guard",
        android_resident_visitor: "https://play.google.com/store/apps/details?id=com.welcomi"
    },
    colors: {
        primaryColor: "#ffa31a",
        secondaryColor: "#0DBCD3",
        cancelColor: "#BF3232",
    },
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

}

export default Constants;
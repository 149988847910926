import { CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Access from '../components/Guard/Dashboard/Access';
import Dashboard from '../components/Guard/Dashboard/Dashboard';
import GLogin from '../components/Guard/Login/GLogin';
import GLogout from '../components/Guard/Login/GLogout';
import { GuardContext } from '../Context/GuardContext';
import LayoutGuard from '../layouts/LayoutGuard';
import AppRoute from '../utils/AppRoute';
import Constants from '../utils/Constants';

function GuardNav() {

    const { verifyAuth, isLoggedIn } = useContext(GuardContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        validateSession();
    }, [])

    const validateSession = async () => {
        await verifyAuth();
        setLoading(false)
    }

    if (loading) {
        return <CircularProgress style={{ color: Constants.colors.primaryColor }} />
    }


    return (
        !isLoggedIn ?
            <>
                <Redirect to='/' />
                <AppRoute
                    exact
                    path="/"
                    component={GLogin} />
            </>
            :
            <>
                <Redirect to='/dashboard' />
                <LayoutGuard>
                    <AppRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                    />

                    <AppRoute
                        exact
                        path="/access"
                        component={Access}
                    />

                    <AppRoute
                        exact
                        path="/signout"
                        component={GLogout}
                    />
                </LayoutGuard>
            </>
    );
}

export default GuardNav;
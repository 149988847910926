import React from 'react';

const DGeneral = () => {
    return (
        <div>
            <p>DGeneral</p>
        </div>
    );
}

export default DGeneral;
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import misionpng from "../../assets/images/misionbueno.png";
import visionpng from "../../assets/images/visionbueno.png";
import "../../components/Residencial/AdStyles/about.css";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const About = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const [pressed_mision, set_pressed_mision] = useState(true);
  const [pressed_vision, set_pressed_vision] = useState(false);

  const showMision = () => {
    set_pressed_mision(true);
    set_pressed_vision(false);
  };

  const showVision = () => {
    set_pressed_mision(false);
    set_pressed_vision(true);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {children}
          <div className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
            <div className="App">
              {/* <h1>Acerca de Nosotros</h1> */}
              <div className="menu_row">
                <div className="el_col">
                  <img
                    alt=""
                    src={misionpng}
                    className={
                      pressed_mision
                        ? "mision-logo"
                        : "mision-logo brighterLogo"
                    }
                    onClick={showMision}
                  />
                </div>
                <div className="el_col">
                  <img
                    alt=""
                    src={visionpng}
                    className={
                      pressed_vision
                        ? "vision-logo"
                        : "vision-logo brighterLogo"
                    }
                    onClick={showVision}
                  />
                </div>
              </div>

              {pressed_mision && (
                <div className="margen">
                  <p>
                    {" "}
                    Conectar todas las partes de la vida en una localidad,
                    siempre buscando la mayor practicidad y adaptabilidad. Tener
                    una constante mejora en la vida de todos nuestros usuarios,
                    desde residentes hasta administradores. Ser una plataforma
                    social que permita la integración e interacción de una forma
                    segura y fácil para toda la comunidad.{" "}
                  </p>
                </div>
              )}

              {pressed_vision && (
                <div className="margen">
                  <p>
                    {" "}
                    Welcomi siendo el estándar para manejar y conectar una
                    comunidad de cualquier tipo o tamaño, adaptándose hasta a la
                    más mínima diferencia, esto gracias a su modularidad.
                    Integración con todos los elementos del internet de las
                    cosas en un mundo aún más conectado que el actual,
                    actualizándose constantemente y adaptándose a las nuevas
                    tendencias tecnológicas. Ser una herramienta social que
                    facilite la vida de las personas y la vida de su entorno.{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;

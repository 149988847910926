import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import PricingCard1 from "./PricingCard1";
import { Helmet } from "react-helmet";
import RequestPlanFormR from "../Home/RequestPlanFormR";
import {
  Element,
} from "react-scroll";
import PricingCard2 from "./PricingCard2";
const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Prices = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const outerClasses = classNames(
  //   "section",
  //   topOuterDivider && "has-top-divider",
  //   bottomOuterDivider && "has-bottom-divider",
  //   hasBgColor && "has-bg-color",
  //   invertColor && "invert-color",
  //   className
  // );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  let offer1 = [
    { available: true, feature: "Para máximo 100 casas/departamentos.", bold: true },
    { available: true, feature: "Control de accesos para visitantes y trabajadores." },
    { available: true, feature: "Escribir comunicados generales." },
    { available: true, feature: "Reservaciones de áreas comunes." },
    { available: true, feature: "Historiales temporales." },
  ]


  // { available: true, feature: "Para 101 a 250 casas/departamentos.", bold: true },
  // { available: true, feature: "Para 251 a 500 casas/departamentos.", bold: true },
  // { available: true, feature: "Para 401 a 750 casas/departamentos.", bold: true },
  // { available: true, feature: "De 751 casas/departamentos o más.", bold: true },
  let offer2 = [
    { available: true, feature: "Control de finanzas: morosos, ingresos, gastos, balance general y más." },
    { available: true, feature: "Control de accesos para visitantes, trabajadores y servicios rápidos." },
    { available: true, feature: "Comunicados,encuestas, historiales, plantillas, paquetería, reservaciones, multimedia, notificaciones push." },
    { available: true, feature: "Registro de Tags peatonales, vehículares u otros." },
    { available: true, feature: "Invitaciones frecuentes, invitaciones especiales." },
    { available: true, feature: "Contacto al personal, reglamento de localidad." },
    { available: true, feature: "Asigna tareas a los trabajadores de tu localidad." },
  ]

  return (
    <>
      <Helmet>
        {!props.no_inner &&
          <title>Precios | Welcomi</title>
        }
        <meta name="description"
          content="Puedes empezar con nuestro plan gratuito y despues incrementarlo en caso de ser necesario." />
        <base href="/precios"></base>
      </Helmet>

      <div className={props.no_inner ? null : innerClasses}>
        <div className="tabladePrecios">
          <div className="container">
            {/* <h1>Precios</h1> */}
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h2>Puedes comenzar con el plan <span className="text-color-primary">gratuito</span>.</h2>
            </div>
          </div>


          <div className="pricingCard1Width">
            <PricingCard1
              title="Plan gratuito"
              description="Empieza ahora, descubre lo que Welcomi ofrece a tu zona residencial."
              offer={offer1}
              price={'Gratis, descargala ahora!'}
              licensesText={"Se otorga 1 licencia capitán, 1 licencia guardia, 100 licencias de residente."}
            />
          </div>


          <div className="pricingCard2Width">
            <PricingCard2
              offer={offer2}
            />
          </div>

        </div>


        <Element name="RequestPlanForm" className="element">
          <RequestPlanFormR />
        </Element>
      </div>
    </>
  );
};

Prices.propTypes = propTypes;
Prices.defaultProps = defaultProps;

export default Prices;

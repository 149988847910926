import React, { useState } from "react";
import catchErrors from "../cases/Firecases/catchErrors";
import Fire, { db } from "../Fire";

export const AdminContext = React.createContext();

export function AdminProvider(props) {
  const [admin, setAdmin] = useState(null);
  const [adminDocument, setAdminDocument] = useState(null);
  const [adminActiveLocality, setAdminActiveLocality] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const login = async (email, password, setError) => {
    await Fire.handleLogin(email, password)
      .then(async (admin) => {
        setAdmin(admin);
        setIsLoggedIn(true);
        getAdminDocument(admin.uid);
      })
      .catch((error) => {
        catchErrors(error, setError);
        setAdmin(null);
        setIsLoggedIn(false);
      });
  };

  const logout = async () => {
    await Fire.handleSignOut()
      .then(() => {
        setAdmin(null);
        setAdminDocument(null);
        setAdminActiveLocality(null);
        setIsLoggedIn(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyAuth = async () => {
    await Fire.verifyAuth()
      .then((admin) => {
        setAdmin(admin);
        setIsLoggedIn(true);
        getAdminDocument(admin.uid);
      })
      .catch((error) => {
        logout();
      });
  };

  const getAdminDocument = async (adm_uid = null) => {
    const snapshot = await db
      .collection("administrators")
      .doc(adm_uid ? adm_uid : admin.uid)
      .get()
      .catch((error) => {
        logout();
        alert("Datos del administrador no encontrados.");
      });

    if (!snapshot.exists) {
      logout();
      alert("Datos del guardia no encontrados, por favor intente desde la aplicación en su smartphone.");
    }

    if (snapshot.exists) {
      await setAdminDocument({ doc_id: snapshot.id, doc_data: snapshot.data() });
    }
  };

  return (
    <AdminContext.Provider
      value={{
        admin,
        adminDocument,
        adminActiveLocality,
        login,
        logout,
        verifyAuth,
        getAdminDocument,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
}

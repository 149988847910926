import { Button, CircularProgress, Modal, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { updateData } from '../../../cases/Firecases/updateData';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "80%",
        maxHeight: "80%",
        // height: "80%",
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const Invitations_DenyModal = ({
    cbClose,
    inv_id
}) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    const [denyMsj, setDenyMsj] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const denyAccess = async () => {
        setLoading(true)
        setErrorMessage(null)
        let data = {
            "access_denied.denied": true,
            "access_denied.denied_date": new Date(),
            "access_denied.message": denyMsj,
            "data.modified_at": new Date()
        }

        let response = await updateData("invitations", inv_id, data)

        let done = response[0]
        let error = response[1]

        if (done) {
            setLoading(false)
            cbClose()
        }

        if (error) {
            setErrorMessage("Error, intente en un momento")
            setLoading(false)
        }
    }

    return (
        <Modal
            open={true}
            onClose={cbClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>

                <TextField
                    name='denyreasson'
                    id='denyreasson'
                    label="Explicación para el historial (opcional)"
                    type='text'
                    onChange={(e) => setDenyMsj(e.target.value)}
                    value={denyMsj}
                    fullWidth
                    helperText={errorMessage}
                    error={errorMessage ? true : false}
                />

                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 15
                }}>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{
                            color: "white"
                        }}
                        onClick={denyAccess}
                    >
                        Continuar
                    </Button>
                    {
                        loading &&
                        <CircularProgress color='primary' />
                    }
                </div>
            </div>
        </Modal>
    );
}

export default Invitations_DenyModal;
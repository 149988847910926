import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import "../AdStyles/General.css"
import Constants from '../../../utils/Constants';
import { Button, Typography } from '@material-ui/core';

const propTypes = {
    children: PropTypes.node,
    ...SectionProps.types,
};

const defaultProps = {
    children: null,
    ...SectionProps.defaults,
};

const SelectLogin = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const outerClasses = classNames(
        "section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    return (
        <section {...props} className={outerClasses}>
            <div className='container'>

                <div className='welcomiCard'>
                    <Typography
                        variant="h4"
                        style={{ color: Constants.colors.primaryColor }}
                        gutterBottom>
                        Inicia Sesión
                    </Typography>

                    {/* <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                        <Button
                            variant="contained"
                            style={styles.loginButton}
                            onClick={(e) => {
                                e.preventDefault()
                                window.location.href = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://captain.localhost:3000" : "https://captain.welcomi.app";
                            }}
                        >
                            Iniciar como cápitan
                        </Button>

                        <Typography style={{ textAlign: "center", color: "gray" }}>
                            Si eres administrador de una zona residencial, esta es tu opción.
                        </Typography>
                    </div> */}


                    <div style={{ marginTop: "5%", marginBottom: "3%" }}>
                        <Button
                            variant="contained"
                            style={styles.loginButton}
                            onClick={(e) => {
                                e.preventDefault()
                                window.location.href = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://user.localhost:3000" : "https://user.welcomi.app";
                            }}
                        >
                            Iniciar como usuario
                        </Button>

                        <Typography style={{ textAlign: "center", color: "gray" }}>
                            Si eres residente o visitante, esta es tu opción.
                        </Typography>
                    </div>
                </div>

            </div>
        </section>
    );
}

SelectLogin.propTypes = propTypes;
SelectLogin.defaultProps = defaultProps;

export default SelectLogin;

const styles = {
    loginButton: {
        backgroundColor: "#ffa31a",
        color: "white",
        fontWeight: "bold",
        width: "100%",
    },
}
import React from "react"
import "./Definitions_R.css"
import DefinitionsRCard from "./DefinitionsRCard"
import Security from "../../../assets/svgs/Security.svg"
import AccessControl from "../../../assets/svgs/AccessControl.svg"
import Communication from "../../../assets/svgs/Communication.svg"
import Organization from "../../../assets/svgs/Organization.svg"
import MobilDesktop from "../../../assets/svgs/Design_MobileDesktop.svg"
import Payments from "../../../assets/svgs/Design_Payments.svg"
// import Payments from "../../../assets/images/Design_Payments.png"
import { accesscontrol_body, communication_body, democratization_body, eco_body } from "./modal_bodies"
import Constants from "../../../utils/Constants"
import { Typography } from "@material-ui/core"
// import a
const DefinitionsR = () => {
  return (
    <div className="background">
      <div style={{ marginLeft: "3%", paddingTop: "3%", paddingBottom: "3%" }}>
        <Typography variant="h3" style={{ color: "white", textAlign: "center", marginBottom: 25 }}>
          ¿Qué ofrecemos?
        </Typography>
        <div className="cardOrientation">

          <DefinitionsRCard
            title={"Finanzas"}
            definition={`Lleva el control de los ingresos, adeudos y gastos del residencial.`}
            definition2={`Obten en tiempo real el balance general. Añade deudas y pagos al instante. Notificaciones diarias a morosos.`}
            img={Payments}
            imgalt="Welcomi Finanzas"
            buttonTitle={"Conocer más"}
            buttonColor={"#0DBCD3"}
          // modal={accesscontrol_body}
          />
          <DefinitionsRCard
            title={"Control de accesos"}
            definition={`Eficiencia, seguridad, rapidez. Un control de accesos revolucionado, olvida las largas filas en la caseta de acceso.`}
            definition2={`Sistema de códigos QR, de IDs o de invitaciones para el visitante.`}
            img={AccessControl}
            imgalt="Welcomi Control de accesos"
            buttonTitle={"Conocer más"}
            buttonColor={"#0DBCD3"}
            modal={accesscontrol_body}
          />

          <DefinitionsRCard
            title={"Organización"}
            definition={"Olvídate del papeleo, administra y organiza tu residencial con nuestra plataforma."}
            definition2={"Pagos, reservaciones, tags y mucho más."}
            img={Organization}
            imgalt="Welcomi Organizacion"
            buttonTitle={"Conocer más"}
            buttonColor={"#481AFF"}
            modal={eco_body}
          />

          <DefinitionsRCard
            title={"Comunicación"}
            definition={"Publica comunicados para enterar de las últimas noticias a los residentes."}
            definition2={"Crea encuestas en tu residencial en las cuales podrán participar los residentes."}
            img={Communication}
            imgalt="Welcomi Comunicacion"
            buttonTitle={"Conocer más"}
            buttonColor={"#C4C4C4"}
            modal={communication_body}
          />

          <DefinitionsRCard
            title={"Seguridad"}
            definition={"Sabemos que es lo más importante, Welcomi añade una nueva capa de seguridad a tu residencial."}
            definition2={"Aprovechamos las herramientas digitales para brindar una mayor seguridad."}
            img={Security}
            imgalt="Welcomi Seguridad"
            buttonTitle={"Conocer más"}
            buttonColor={"#F25F23"}
            modal={democratization_body}
          />
          <DefinitionsRCard
            title={"Plataformas"}
            definition={"Podrás disfrutar de estas y muchas otras funcionalidades desde tu smartphone, tablet o computadora."}
            definition2={"No lo pienses más, comienza ahora descargando la app o contáctanos a contacto@welcomi.app"}
            img={MobilDesktop}
            imgalt="Welcomi Organizacion"
            buttonTitle={"Empieza ahora"}
            buttonColor={Constants.colors.primaryColor}
          // modal={eco_body}
          />
        </div>
      </div>
    </div>
  )
}

export default DefinitionsR
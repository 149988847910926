import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import CaptainRounded from '../../../assets/svgs/captain_rounded.svg';
import { CaptainContext } from '../../../Context/CaptainContext';
import { SectionProps } from '../../../utils/SectionProps';

const propTypes = {
    children: PropTypes.node,
    ...SectionProps.types,
};

const defaultProps = {
    children: null,
    ...SectionProps.defaults,
};


const CLogin = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const outerClasses = classNames(
        "section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const { login } = React.useContext(CaptainContext)

    const [email, setEmail] = React.useState('');
    const [pwd, setPwd] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const submit = async () => {
        setLoading(true)
        setError('')
        if (!email || !pwd) {
            setError("Por favor, rellene todos los campos");
            setLoading(false)
            return
        }

        await login(email, pwd, setError);
        setLoading(false)
    }

    return (
        <div className='welcomiCard'>
            <div className="container">
                <img
                    src={CaptainRounded}
                    alt="Welcomi Guard"
                    style={{ width: "15%", height: "15%", marginRight: "10px" }}
                />
                <Typography
                    variant="h4"
                    style={{ alignSelf: "center" }}
                    color='primary'
                    gutterBottom>
                    Inicio de sesión para capitanes.
                </Typography>

                <div className="textInputSpacer">
                    <TextField
                        name='email'
                        id="email"
                        label="Email"
                        variant="outlined"
                        type='email'
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        fullWidth
                    />
                </div>

                <div>
                    <TextField
                        name='pwd'
                        id="pwd"
                        label="Contraseña"
                        variant="outlined"
                        type='password'
                        onChange={e => setPwd(e.target.value)}
                        value={pwd}
                        fullWidth
                    />
                </div>
                {
                    error &&
                    <Typography style={styles.error}>
                        {error}
                    </Typography>
                }

                <div className='alignRight'>
                    <Button
                        variant="contained"
                        style={styles.submitButton}
                        onClick={submit}
                    >
                        Iniciar
                    </Button>
                    {
                        loading &&
                        <CircularProgress color='primary' />
                    }
                </div>

                <Typography style={{ textAlign: "center", color: "gray" }}>
                    No tienes cuenta? Registrate, es gratis!
                </Typography>
            </div>
        </div>
    );
}

CLogin.propTypes = propTypes;
CLogin.defaultProps = defaultProps;
export default CLogin;

const styles = {
    submitButton: {
        backgroundColor: "#ffa31a",
        color: "white",
        fontWeight: "bold",
    },
    error: {
        color: "red",
        textAlign: "center"
    }
}
import React, { useState } from 'react';
import { Button, Typography } from "@material-ui/core";
import { updateData } from '../../../../cases/Firecases/updateData';

const MarkAsAdded = ({ captain_id }) => {

    const [added, setAdded] = useState(false)

    const mark = async () => {
        // mark as added to mailchimp
        const data = {
            "account_info.on_mail_distribution_list": true,
            "account_info.updated_at": new Date()
        }

        const response = await updateData("captains", captain_id, data)

        const done = response[0]
        const error = response[1]

        if (done) {
            setAdded(true)
        }

        if (error) {
            alert("Error ❌. No se pudo completar la acción.")
        }
    }

    return (
        <div>
            {
                !added ?
                    <Button
                        variant='contained'
                        color="secondary"
                        onClick={mark}
                    >Marcar como añadido</Button>
                    :
                    <Typography>
                        Añadido
                    </Typography>
            }
        </div>
    );
}

export default MarkAsAdded;
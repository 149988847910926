import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import {
  Box,
  Drawer as MuiDrawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Avatar,
  CssBaseline
} from '@mui/material'

import {
  ChevronLeft,
  ChevronRight,
  HelpOutlineRounded,
  AssignmentTurnedInRounded,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles"


// import classNames from "classnames"
import { SectionProps } from "../../../utils/SectionProps"
import CaptainRounded from "../../../assets/svgs/captain_rounded.svg"
import GuardRounded from "../../../assets/svgs/guard_rounded.svg"
import WelcomiRounded from "../../../assets/svgs/welcomi_rounded.svg"
import "./Guias.css"


/* ---------- Drawer Setup ---------- */
const drawerWidth = 340

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
})
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));
// ------------------------------------------


const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
}
const defaultProps = {
  children: null,
  ...SectionProps.defaults,
}

const GuiasListMenu = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const outerClasses = classNames(
  //   "section",
  //   topOuterDivider && "has-top-divider",
  //   bottomOuterDivider && "has-bottom-divider",
  //   hasBgColor && "has-bg-color",
  //   invertColor && "invert-color",
  //   className
  // )

  // const innerClasses = classNames(
  //   "section-inner",
  //   topDivider && "has-top-divider",
  //   bottomDivider && "has-bottom-divider"
  // )

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }))


  const [open, setOpen] = React.useState(window.screen.width < 768 ? false : true)
  const toggleDrawer = () => {
    setOpen(!open)
    toggleOpenNested({nest1: false, nest2: false, nest3: false})
  }

  const [openNested, toggleOpenNested] = useState({nest1: false, nest2: false, nest3: false})

  const handleToggleNested = (nest) => {
    toggleOpenNested({...openNested, [nest]: openNested[nest] ? false : true})
  }

  const classes = useStyles()

  const guides = [
    {
      title: "Welcomi",
      content: [
        { title: "Subir identificación", path: "subir_identificacion" }
      ],
      open: openNested.nest1,
      action: () => handleToggleNested('nest1'),
      icon: WelcomiRounded
    },
    {
      title: "Welcomi Guard",
      content: [
        { title: "Guía para convertirse en guardia", path: "convertirse_guardia" }
      ],
      open: openNested.nest2,
      action: () => handleToggleNested('nest2'),
      icon: GuardRounded
    },
    {
      title: "Welcomi Captain",
      content: [
        { title: 'Registrar áreas comunes', path: 'registrar_areas_comunes' },
        { title: 'Registrar residentes', path: 'registrar_residentes' },
        { title: 'Registrar guardias', path: 'registrar_guardias' },
    ],
      open: openNested.nest3,
      action: () => handleToggleNested('nest3'),
      icon: CaptainRounded
    }
  ]

  const renderWelcomiGuides = (guides_arr) =>
    guides_arr.map((article, key) => (
      <>
        <ListItemButton sx={{paddingLeft: '8px'}} className={classes.nested} key={`guide-${key + 1}`}>
          <ListItemIcon>
              <Avatar alt={article.title} src={article.icon} />
          </ListItemIcon>
          <p
            style={{fontSize: '16px', flexGrow: 1, margin: '0 12px'}}
            onClick={article.action}>
              {article.title}
          </p>
          {article.open ? (
            <ExpandLess onClick={article.action} />
          ) : (
            <ExpandMore onClick={article.action} />
          )}
        </ListItemButton>

        <Collapse in={article.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {article.content.map(item => (
                <Link to={`/guias/${item.path}`}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText
                  secondary={item.title}
                />
              </ListItemButton>
            </Link>
              ))}
          </List>
        </Collapse>
      </>
    ))

  const general = [
    {title: '¿Qué es Welcomi?', path: 'que_es_welcomi', icon: <HelpOutlineRounded />},
    {title: 'Registrar mi residencial', path: 'registrar_residencial', icon: <AssignmentTurnedInRounded />},
    // {title: 'Sistema de invitaciones', path: 'sistema_de_invitaciones'},
    // {title: 'Descentralización de la seguridad', path: 'descentralizacion_seguridad'},
  ]

  const renderGeneralCases = (guidesArr) => (
    guidesArr.map((article, index) => (
      <Link key={`guide-${index}`} to={`/guias/${article.path}`}>
          <ListItem disablePadding sx={{ display: "block", marginBottom: 0}}>
            <ListItemButton>
              <ListItemIcon sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: '#f5861f'
              }}>
                {article.icon}
              </ListItemIcon>
              <p style={{
                alignSelf: 'center',
                color: 'black',
                fontSize: '16px',
                lineHeight: '100%',
                display: open ? 'block' : 'none',
                margin: '12px 0'
              }}>{article.title}</p>
            </ListItemButton>
      </ListItem>
      </Link>
    ))
  )

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            {open && <ListItemText onClick={toggleDrawer} sx={{ color: "gray" }} primary="Guías" />}
            <IconButton onClick={toggleDrawer}>
              {!open ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List sx={{paddingTop: 0, paddingBottom: 0, marginBottom: 0}}>{renderGeneralCases(general)}</List>
          {/* <Divider /> */}
          <List sx={{paddingTop: 0, paddingBottom: 0}}>{renderWelcomiGuides(guides)}</List>
        </Drawer>
    </Box>
    )

}


GuiasListMenu.propTypes = propTypes
GuiasListMenu.defaultProps = defaultProps

export default GuiasListMenu
import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link to="/contacto">Contacto</Link>
        </li>
        <li>
          <Link to="/about">Acerca de Nosotros</Link>
        </li>
        <li>
          <Link to="/privacidad">Aviso de Privacidad</Link>
        </li>
        <li>
          <Link to="/terminosycondiciones">Terminos y Condiciones</Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;

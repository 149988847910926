import React from "react"

const GuiaConvertirseGuardia = () => {
  return (
    <div>
      <h2>Guía para convertirse en guardia</h2>

      <h6>Requisitos:</h6>
      <ul>
        <li>Descargar la aplicación de Welcomi Guard, la encontrarás en el menú de "Descargas".</li>
        <li><b>Antes de crear tu cuenta</b> necesitas el "identificador" de la localidad a la que te registraras, este te lo proporcionara la administración del residencial en el que trabajas.</li>
        <li>Ser trabajador laborando en una localidad que cuente con el servicio de Welcomi.</li>
      </ul>

      <p>1.- Crea tu cuenta dentro de la aplicación.</p>
      <p>2.- Introduce el identificador y escribe tu celular de contacto.</p>
      <p>3.- Completa tus datos personales.</p>
      <p>Listo <span role='img' aria-label='check'>✅</span>, solamente debes esperar a que la administración acepte tu solicitud.</p>
    </div>
  )
}
export default GuiaConvertirseGuardia

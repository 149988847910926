import React, { useState } from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Slider from '@material-ui/core/Slider';
import Constants from '../../../utils/Constants';
import {
    Link,
} from "react-scroll";
const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 100,
        label: "100"
    },
    {
        value: 250,
        label: '250',
    },
    {
        value: 500,
        label: '500',
    },
    {
        value: 750,
        label: '750',
    },
    {
        value: 1000,
        label: '1000+',
    },
];

function valuetext(value) {
    return `${value}`;
}

const PricingCard2 = (props) => {

    const [houses, setHouses] = useState(50)

    const handleChange = (e, val) => {
        setHouses(val)
    }
    const listFeatures = () => (
        props.offer.map((feature, key) => (
            <>
                <div style={{ flexDirection: "row", display: "flex" }} key={key}>
                    {
                        feature.available ?
                            <CheckCircleIcon
                                titleAccess="Check"
                                fontSize="small"
                                style={{ color: "green", alignSelf: "center" }}
                                className="check"
                            />
                            :
                            <CancelIcon
                                titleAccess="Cross"
                                fontSize="small"
                                style={{ color: "red" }}
                                className="cross"
                            />
                    }

                    <Typography style={{ fontSize: 16, marginTop: 10, marginBottom: 10, marginLeft: 10, fontWeight: feature.bold ? "bold" : "normal" }}>{feature.feature}</Typography>
                </div>

            </>

        ))
    )

    return (
        <Card elevation={5}>
            <CardContent>
                <Typography variant="h5" component="div" className="text-color-primary">
                    Plan Plus
                </Typography>

                <Typography
                    style={{ fontSize: 18, color: "gray", marginTop: 10, marginBottom: props.downloadable ? 15 : 0, marginLeft: 2 }}
                    gutterBottom
                >
                    Podrás hacer uso de las funcionalidades aquí listadas y de muchas más.
                </Typography>

                {listFeatures()}

                {/* <Typography id="discrete-slider-custom" gutterBottom style={{ marginTop: "3%" }}>
                    Cantidad de casas o departamentos:
                </Typography>

                <Slider
                    defaultValue={50}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    min={1}
                    max={1050}
                    style={{ marginTop: "4%", color: Constants.colors.primaryColor }}
                    onChange={handleChange}
                /> */}

                {/* {
                    houses <= 100 &&
                    <>
                        <p className='pricesTitle'>Plan Plus</p>
                        <Typography variant="h6" component="div" className="text-color-primary priceNumber">
                            $100 USD por mes
                        </Typography>
                        <p className='priceDesc'>Un solo pago por todo el residencial</p>
                    </>
                } */}

                {/* {
                    houses > 100 && houses < 250 &&
                    <>
                        <p className='pricesTitle'>Plan Plus</p>
                        <Typography variant="h6" component="div" className="text-color-primary priceNumber">
                            $250 USD por mes
                        </Typography>
                        <p className='priceDesc'>Un solo pago por todo el residencial</p>
                    </>
                } */}
                {/* {
                    (houses > 250 && houses < 500) &&
                    <>
                        <p className='pricesTitle'>Plan Plus</p>
                        <Typography variant="h6" component="div" className="text-color-primary priceNumber">
                            $500 USD por mes
                        </Typography>
                        <p className='priceDesc'>Un solo pago por todo el residencial</p>
                    </>
                } */}
                {/* {
                    (houses > 500 && houses < 750) &&
                    <>
                        <p className='pricesTitle'>Plan Plus</p>
                        <Typography variant="h6" component="div" className="text-color-primary priceNumber">
                            $750 USD por mes
                        </Typography>
                        <p className='priceDesc'>Un solo pago por todo el residencial</p>
                    </>
                } */}
                {/* {
                    (houses > 750) &&
                    <>
                        <p className='pricesTitle'>Plan Plus</p>

                        <Typography variant="subtitle1" component="div" className="text-color-primary priceNumber">
                            Para residenciales mayores a 750 viviendas tenemos un plan especial!!
                        </Typography>
                    </>
                } */}

                <div className="buttonCenter">
                    <Link
                        activeClass="active"
                        to="RequestPlanForm"
                        spy={true}
                        smooth={true}
                        duration={1000}
                    >
                        <Button
                            variant="contained"
                            style={styles.downloadButton}
                        > Solicitar</Button>
                    </Link>
                </div>
            </CardContent>
        </Card>
    )
}

export default PricingCard2;
const styles = {
    downloadButton: {
        display: "flex",
        width: "95%",
        backgroundColor: "#ffa31a",
        alignSelf: "center",
        color: "white",
        fontWeight: "bold",
    }
}
import React from "react";
import { Controller, useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./Contact.css";
import {
  TextField,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';

const ContactForm: React.FC = () => {
  const { register, control, errors, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
      subject: "",
      message: "",
    },
  });

  const toastifySuccess = (): void => {
    toast("Solicitud enviada", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
    });
  };

  const onSubmit = (data) => {
    // Send form email
    let templateParams = {
      name: data.name,
      email: data.email,
      telefono: data.phone_number,
      subject: data.subject,
      message: data.message,
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      "template_by4wc1m",
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID
    );
    reset();
    toastifySuccess();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        // margin: "0%",
        width: "100%",
        // height: "90%",//theme.spacing(16)
        // justifyContent: "center"
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="ContactForm">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className={classes.root}>
              <Paper elevation={3}>
                <form
                  id="contact-form"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate={true}
                  className="container_inside_paper"
                >
                  <div>
                    <TextField
                      name="name"
                      label="Nombre(s) y apellidos"
                      type="text"
                      margin="normal"
                      fullWidth
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Introduzca su nombre y apellidos",
                        },
                      })}
                    />
                    {errors.name && (
                      <span className="errorMessage">
                        {errors.name.message}
                      </span>
                    )}
                  </div>

                  <div>
                    <TextField
                      name="email"
                      label="Email"
                      type="email"
                      inputRef={register({
                        required: {
                          value: true,
                          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Por favor introduzca su direccion email",
                        },
                        maxLength: {
                          value: 320,
                          message: "Por favor use menos de 320 caracteres",
                        },
                      })}
                      margin="normal"
                      fullWidth
                    />
                    {errors.email && (
                      <span className="errorMessage">
                        {errors.email.message}
                      </span>
                    )}
                  </div>


                  <div>
                    <TextField
                      name="phone_number"
                      label="Numero de contacto"
                      type="tel"
                      margin="normal"
                      fullWidth
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Introduzca su telefono o celular de contacto",
                        },
                      })}
                    />
                    {errors.phone_number && (
                      <span className="errorMessage">
                        {errors.phone_number.message}
                      </span>
                    )}
                  </div>

                  <div>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel>Asunto</InputLabel>
                      <Controller
                        rules={{ required: true }}
                        control={control}
                        name="subject"
                        as={
                          <Select value={""}>
                            {/* <MenuItem value="">
                              <em>Ninguno</em>
                            </MenuItem> */}

                            <MenuItem value={"Duda"}>Dudas</MenuItem>

                            <MenuItem value={"FallaApp"}>
                              Falla en aplicación
                            </MenuItem>

                            <MenuItem value={"Contacto"}>Contacto</MenuItem>

                            <MenuItem value={"Queja"}>Queja</MenuItem>
                          </Select>
                        }
                      />
                    </FormControl>
                  </div>


                  <div>
                    <TextField
                      name="message"
                      label="Mensaje"
                      multiline
                      rows={4}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Escriba un mensaje.",
                        },
                      })}
                    />
                    {errors.message && (
                      <span className="errorMessage">
                        {errors.message.message}
                      </span>
                    )}
                  </div>

                  <div className="buttonRight">
                    <Button
                      style={{
                        backgroundColor: "#ffa31a",
                        width: "auto",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>

            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

import { firebaseConfig } from "./Firebase/firebaseConfig"
import firebase from "firebase/app"
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics"
import "firebase/functions"

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const fbfs = firebase.firestore;
export const functions = firebase.functions();

class Fire {
    // Login
    handleLogin = (email, pwd) => {
        return new Promise((resolve, reject) => {
            auth.signInWithEmailAndPassword(email, pwd)
                .then(user => {
                    resolve(user.user)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    // Handle SignUp
    handleSignUp = (email, password) => {
        return new Promise((resolve, reject) => {
            auth
                .createUserWithEmailAndPassword(email, password)
                .then(() => {
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    //SignOut
    handleSignOut = () => {
        return new Promise((resolve, reject) => {
            auth
                .signOut()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    // Verify Auth
    verifyAuth = () => {
        return new Promise((resolve, reject) => {
            auth.onAuthStateChanged(user => {
                if (user) {
                    // console.log(user)
                    resolve(user)
                } else {
                    reject()
                }
            })
        })
    }


    //Get user Document
    getUserDocument = () => {
        return new Promise((resolve, reject) => {
            db.collection("users")
                .doc(this.user.uid)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        resolve()
                    }
                    if (!doc.exists) {
                        resolve();
                    }
                })
                .catch(error => {
                    reject()
                })
        })
    }

    /*Set Data
  This works when I want to set the id of the document (name of the document)
  */
    setData = (coleccion, document, data) => {
        return new Promise((resolve, reject) => {
            db.collection(coleccion)
                .doc(document)
                .set({ ...data })
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    //Update Data
    updateData = (coleccion, document, data) => {
        return new Promise((resolve, reject) => {
            db.collection(coleccion)
                .doc(document)
                .update({ ...data })
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /*Delete document*/
    deleteDocument = (coleccion, documento) => {
        return new Promise((resolve, reject) => {
            db.collection(coleccion)
                .doc(documento)
                .delete()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    //Get download url from storage ref
    getDownloadLinkFromRefURL = (storagePath) => {
        var storageRef = storage.refFromURL(storagePath)

        return new Promise(async (resolve, reject) => {
            storageRef
                .getDownloadURL()
                .then((url) => {
                    resolve(url)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    //To delete images by reference
    deleteImages = (storagePath) => {
        // var storageRef = storage.ref()

        // var imageRef = storageRef.child(storagePath)

        let storageRef = storage.refFromURL(storagePath)

        return new Promise(async (resolve, reject) => {
            storageRef.delete()
                .then(() => {
                    //Imagen borrada
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })

        })
    }
}

Fire = new Fire();
export default Fire;
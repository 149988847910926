import { Button } from '@material-ui/core';
import React from 'react';
import { useContext } from "react";
import { GuardContext } from "../../../Context/GuardContext";

const GLogout = () => {

    const { logout } = useContext(GuardContext);


    return (
        <div>

            <Button
                color='primary'
                variant='contained'
                onClick={logout}
            >
                Cerrar Sesión
            </Button>
        </div>
    );
}

export default GLogout;
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField } from '@material-ui/core';
import { db } from '../../../../Fire';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AReviewPPContainer from './AReviewPPContainer';
import AReviewIDContainer from './AReviewIDContainer';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const options = ['Foto de perfil', 'Foto de identificación'];

const AReview = () => {
    const classes = useStyles();

    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [firstDoc, setFirstDoc] = React.useState(null);
    const [lastDoc, setLastDoc] = React.useState(null);
    const limit = 10;

    useEffect(() => {

        return () => {
            setUsers([]);
            setLastDoc(null);
            setFirstDoc(null);
        }
    }, [])

    const retrieveNextPP = async () => {
        await db.collection("users")
            .where("account_info.profile_image_verified", "==", "0") // "0" -> not verified, "verified" -> verified, "rejected" -> rejected
            .orderBy("account_info.created_date", "asc")
            .startAfter(lastDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const retrievePreviousPP = async () => {
        await db.collection("users")
            .where("account_info.profile_image_verified", "==", "0")
            .orderBy("account_info.created_date", "desc")
            .startAfter(firstDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs.reverse()))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const retrieveNextID = async () => {
        await db.collection("users")
            .where("account_info.ID_image_verified", "==", "0") // "0" -> not verified, "verified" -> verified, "rejected" -> rejected
            .orderBy("account_info.created_date", "asc")
            .startAfter(lastDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const retrievePreviousID = async () => {
        await db.collection("users")
            .where("account_info.ID_image_verified", "==", "0")
            .orderBy("account_info.created_date", "desc")
            .startAfter(firstDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs.reverse()))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const setDocs = async (docs) => {
        if (docs.length === 0) {
            alert("No hay más usuarios para revisar")
        }

        if (docs.length > 0) {
            setLastDoc(docs[docs.length - 1])
            setFirstDoc(docs[0])

            let snapshot_users = []
            docs.forEach((doc) => {
                snapshot_users.push({ doc_id: doc.id, doc_data: doc.data() })
            })

            setUsers(snapshot_users)
        }
    }

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Valida la foto de perfil o la foto de identificación de los usuarios
            </Typography>


            <div className="textInputSpacerMedium">
                <Autocomplete
                    value={value}
                    fullWidth
                    disableClearable={true}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                        setUsers([])
                        setFirstDoc(null);
                        setLastDoc(null);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        setUsers([])
                        setFirstDoc(null);
                        setLastDoc(null);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            label="Selecciona una opción"
                            variant="outlined"
                        />}
                />
            </div>


            <div className="alignRight">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (users.length > 0) {
                            setUsers([])
                            setLastDoc(null);
                            setFirstDoc(null)
                            return
                        }

                        if (value === options[0]) {
                            retrieveNextPP();
                        }

                        if (value === options[1]) {
                            retrieveNextID();
                        }
                    }}
                >
                    Empezar
                </Button>
            </div>

            {
                users.length > 0 ?
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Nombre</StyledTableCell>
                                        <StyledTableCell>Imagen perfil</StyledTableCell>
                                        <StyledTableCell>ID</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        users.map((user) => (
                                            <StyledTableRow key={user.doc_id}>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant='subtitle1'>
                                                        {user.doc_data.personal_info.name}
                                                    </Typography>

                                                    <Typography variant='body1'>
                                                        {user.doc_data.account_info.email}
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        {user.doc_id}
                                                    </Typography>
                                                </StyledTableCell>

                                                <StyledTableCell scope='row'>
                                                    <AReviewPPContainer
                                                        user={user}
                                                    />
                                                </StyledTableCell>

                                                <StyledTableCell align='center'>
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <AReviewIDContainer
                                                            user={user}
                                                        />
                                                    </div>

                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div
                            style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}
                        >
                            <Button
                                variant='contained'
                                color="primary"
                                onClick={() => {
                                    if (value === options[0]) {
                                        retrievePreviousPP();
                                    }

                                    if (value === options[1]) {
                                        retrievePreviousID();
                                    }
                                }}
                            >Anterior</Button>

                            <Button
                                variant='contained'
                                color="primary"
                                onClick={() => {
                                    if (value === options[0]) {
                                        retrieveNextPP();
                                    }

                                    if (value === options[1]) {
                                        retrieveNextID();
                                    }
                                }}
                            >Siguiente</Button>
                        </div>
                    </div>
                    :
                    <div>
                        <Typography variant="subtitle1" gutterBottom>
                            Sin usuarios por el momento
                        </Typography>
                    </div>
            }
        </div >
    )
}

export default AReview;
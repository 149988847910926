import React from 'react'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import './RequestPlanForm_R.css'
import { useForm } from 'react-hook-form'
import { Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Welcomi_rounded from '../../../assets/images/welcomi_rounded.png'
import Constants from '../../../utils/Constants'
import emailjs from 'emailjs-com'
import EnviarFormularioTrack from '../../../cases/GoogleAnalytics.js/EnviarFormularioTrack'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    width: 300,
  },
  margin_message: {
    margin: theme.spacing(1, 1, 3),
    // width: 300
  },
  groupPlanButtons: {
    margin: theme.spacing(1),
  },
}))

const RequestPlanFormR = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const classes = useStyles()

  const { register, /* control, */ errors, handleSubmit, reset /* , watch */ } =
    useForm({
      defaultValues: {
        name: '',
        email: '',
        phone_number: '',
        city: '',
        message: '',
        apartments_quantity: 0,
      },
    })

  const handleTrack = (trackEvent) => {
    trackEvent === 'enviarFormularioTrack' && EnviarFormularioTrack() // Esto es lo mismo que un if()
  }

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  )

  const onSubmit = async (data) => {
    handleTrack('enviarFormularioTrack')

    let templateParams = {
      name: data.name,
      email: data.email,
      phone_number: data.phone_number,
      city: data.city,
      selectedPlan: data.apartments_quantity,
      message: data.message,
    }

    await emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        'template_ld175im',
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID,
      )
      .then(() => {
        window.location.href = '/requestform_submitted'
        reset()
      })
      .catch((e) => {
        alert('No se pudo enviar la solicitud, intente nuevamente.')
      })

    // e.preventDefault()
    // handleTrack("callTrack")
  }

  return (
    <section {...props} className={outerClasses} style={{width: '90%', marginInline: '5%'}}>
      <div className='container' style={{paddingInline: 0}}>
        {/* <div className={innerClasses}> */}
        <Paper elevation={3}>
          <div className='container_inside_paper'>
            <div className='requestPlanTitleContainer'>
              <h3 className='requestPlanTitle'>
                UNETE A LA COMUNIDAD{' '}
                <span className='text-color-primary'>WELCOMI</span>, SOLICITA TU
                PLAN AHORA
              </h3>
            </div>

            <form
              id='request-plan-contact-form'
              onSubmit={handleSubmit(onSubmit)}
              noValidate={true}
            >
              <div className='requestPlanFormContainer'>
                <div className='requestPlanForm'>
                  <div>
                    <TextField
                      name='name'
                      label='Nombre(s) y apellidos'
                      type='text'
                      className={classes.margin}
                      variant='outlined'
                      inputRef={register({
                        required: {
                          value: true,
                          message: 'Introduzca su nombre y apellidos',
                        },
                      })}
                      style={{minWidth: 300, marginInline: 'auto', width: '100%'}}
                    />
                    {errors.name && (
                      <span className='errorMessage'>
                        {errors.name.message}
                      </span>
                    )}

                    <TextField
                      name='email'
                      label='Email'
                      type='email'
                      className={classes.margin}
                      variant='outlined'
                      inputRef={register({
                        required: {
                          value: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: 'Por favor introduzca su direccion email',
                        },
                        maxLength: {
                          value: 320,
                          message: 'Por favor use menos de 320 caracteres',
                        },
                      })}
                      style={{minWidth: 300, marginInline: 'auto', width: '100%'}}
                    />
                    {errors.email && (
                      <span className='errorMessage'>
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  <div>
                    <TextField
                      name='phone_number'
                      label='Numero de contacto'
                      type='tel'
                      margin='normal'
                      className={classes.margin}
                      variant='outlined'
                      inputRef={register({
                        required: {
                          value: true,
                          message:
                            'Introduzca su telefono o celular de contacto',
                        },
                      })}
                      style={{minWidth: 300, marginInline: 'auto', width: '100%'}}
                    />
                    {errors.phone_number && (
                      <span className='errorMessage'>
                        {errors.phone_number.message}
                      </span>
                    )}

                    <TextField
                      name='city'
                      label='Ciudad, estado y país'
                      type='text'
                      margin='normal'
                      className={classes.margin}
                      variant='outlined'
                      inputRef={register({
                        required: {
                          value: true,
                          message: 'Introduzca su ciudad o país',
                        },
                      })}
                      style={{minWidth: 300, marginInline: 'auto', width: '100%'}}
                    />
                    {errors.city && (
                      <span className='errorMessage'>
                        {errors.city.message}
                      </span>
                    )}
                  </div>

                  <TextField
                    name='apartments_quantity'
                    label='Cantidad de viviendas'
                    type='number'
                    margin='normal'
                    className={classes.margin_message}
                    variant='outlined'
                    fullWidth
                    inputRef={register({
                      required: {
                        value: true,
                        message: 'Introduzca cantidad de viviendas',
                      },
                    })}
                    style={{minWidth: 300, marginInline: 'auto', width: '100%'}}
                  />
                  {errors.apartments_quantity && (
                    <span className='errorMessage'>
                      {errors.apartments_quantity.message}
                    </span>
                  )}
                  <TextField
                    name='message'
                    label='Mensaje (opcional)...'
                    type='text'
                    margin='normal'
                    className={classes.margin_message}
                    fullWidth
                    variant='outlined'
                    multiline
                    rows={4}
                    inputRef={register({
                      required: {
                        value: false,
                        // message: "Introduzca su ciudad o país",
                      },
                    })}
                    style={{minWidth: 300, marginInline: 0, width: '100%'}}
                  />
                </div>

                <div className='requestPlanSendContainer'>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ maxWidth: '70%' }}>
                      <img src={Welcomi_rounded} alt='Welcomi' style={{marginBlock: 24, width: 200, maxWidth: '100%'}} />
                    </div>
                  </div>

                  <Button
                    style={{
                      backgroundColor: Constants.colors.primaryColor,
                      width: 'auto',
                      color: 'white',
                      fontWeight: 'bold',
                      marginTop: '2%',
                      marginBottom: '2%',
                    }}
                    type='submit'
                    // onClick={() => handleTrack("enviarFormularioTrack")}
                  >
                    Enviar solicitud
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Paper>
        {/* </div > */}
      </div>
    </section>
  )
}

export default RequestPlanFormR

import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { db } from '../../../../Fire';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Constants from '../../../../utils/Constants';
import Paper from '@material-ui/core/Paper';
import MarkAsAdded from './MarkAsAdded';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: Constants.colors.secondaryColor,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 100,
    },
});

const AMailchimp = () => {
    const classes = useStyles();

    const [captains, setCaptains] = useState([])
    const [firstDoc, setFirstDoc] = React.useState(null);
    const [lastDoc, setLastDoc] = React.useState(null);
    const limit = 5;

    useEffect(() => {
        retrieveNextCaptains()
        return () => {
            setCaptains([])
            setLastDoc(null)
            setFirstDoc(null)
        }
    }, [])

    const retrieveNextCaptains = async () => {
        await db.collection("captains")
            .where("account_info.on_mail_distribution_list", "==", false)
            .orderBy("account_info.created_date", "desc")
            .startAfter(lastDoc === null ? "" : lastDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const retrievePreviousCaptains = async () => {
        await db.collection("captains")
            .where("account_info.on_mail_distribution_list", "==", false)
            .orderBy("account_info.created_date", "desc")
            .startAfter(firstDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs.reverse()))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const setDocs = async (docs) => {
        if (docs.length === 0) {
            alert("No hay más capitanes para revisar")
        }

        if (docs.length > 0) {
            setLastDoc(docs[docs.length - 1])
            setFirstDoc(docs[0])

            let snapshot_captains = []
            docs.forEach((doc) => {
                snapshot_captains.push({ doc_id: doc.id, doc_data: doc.data(), kind: "captain" })
            })

            setCaptains(snapshot_captains)
        }
    }

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Capitanes no añadidos a mailchimp
            </Typography>

            {
                captains.length > 0 ?
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell >Información</StyledTableCell>
                                        <StyledTableCell style={{ width: 250 }}>Acción</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        captains.map((captain) => (
                                            <StyledTableRow key={captain.doc_id}>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant='h4'>
                                                        {captain.doc_data.personal_info.name}
                                                    </Typography>

                                                    <Typography variant='body1'>
                                                        {captain.doc_data.account_info.email || null}
                                                    </Typography>

                                                    <Typography variant='body1'>
                                                        User uid: {captain.doc_id || null}
                                                    </Typography>

                                                </StyledTableCell>

                                                <StyledTableCell scope='row'>
                                                    <MarkAsAdded
                                                        captain_id={captain.doc_id}
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>

                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div
                            style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}
                        >
                            <Button
                                variant='contained'
                                color="primary"
                                onClick={retrievePreviousCaptains}
                            >Anterior</Button>

                            <Button
                                variant='contained'
                                color="primary"
                                onClick={retrieveNextCaptains}
                            >Siguiente</Button>
                        </div>
                    </div>
                    :
                    <div>
                        <Typography variant="subtitle1" gutterBottom>
                            Todos los capitanes han sido añadidos
                        </Typography>
                    </div>
            }
        </div>
    );
}

export default AMailchimp;
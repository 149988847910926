import React, { useEffect } from "react";
import catchErrors from "../cases/Firecases/catchErrors";
import Fire, { db } from "../Fire";

export const UserContext = React.createContext();

export function UserProvider(props) {
  const [user, setUser] = React.useState(null);
  const [userDocument, setUserDocument] = React.useState(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const login = async (email, password, setError) => {
    // Firebase login request
    await Fire.handleLogin(email, password)
      .then(async (user) => {
        // //  On web we are not validating user range
        setUser(user);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        catchErrors(error, setError);
        setUser(null);
        setIsLoggedIn(false);
      });
  };

  const logout = async () => {
    await Fire.handleSignOut().then(() => {
      setIsLoggedIn(false);
      setUser(null);
      setUserDocument(null);
    });
  };

  const verifyAuth = async () => {
    await Fire.verifyAuth()
      .then(async (user) => {
        setUser(user);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        setUser(null);
        setIsLoggedIn(false);
      });
  };

  const getUserDocument = async (user_uid = null) => {
    const snapshot = await db
      .collection("users")
      .doc(user_uid !== null ? user_uid : user.uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) {
          logout();
          alert("Datos del usuario no encontrados, por favor intente desde la aplicación en su smartphone.");
        }

        if (snapshot.exists) {
          setUserDocument({ doc_id: snapshot.id, doc_data: snapshot.data() });
          // console.log({ doc_id: snapshot.id, doc_data: snapshot.data() });
        }
      })
      .catch((error) => {
        logout();
        alert("Datos del usuario no encontrados, por favor intente desde la aplicación en su smartphone.");
      });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        isLoggedIn,
        userDocument,
        login,
        logout,
        getUserDocument,
        verifyAuth,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}


export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE,
  authDomain: "welcomi.firebaseapp.com",
  databaseURL: "https://welcomi.firebaseio.com",
  projectId: "welcomi",
  storageBucket: "welcomi.appspot.com",
  messagingSenderId: "57776759303",
  appId: "1:57776759303:web:92b632b43598045f3540a2",
  measurementId: "G-Y2ZZ5JLKX5"
};


import { Button, CircularProgress, Modal, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useState } from 'react';
import { updateData } from '../../../cases/Firecases/updateData';
import { GuardContext } from '../../../Context/GuardContext';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "80%",
        maxHeight: "80%",
        // height: "80%",
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const FastServicesModal = ({ cbClose, inv_data, inv_id }) => {
    const { guardDocument, guard } = useContext(GuardContext);

    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    const [granting, setGranting] = useState(false)
    const [name, setName] = useState(inv_data.visitor_name || "")
    const [vehicleBrand, setVehicleBrand] = useState(inv_data.vehicle_brand || "")
    const [vehicleModel, setVehicleModel] = useState(inv_data.vehicle_model || "")
    const [vehiclePlate, setVehiclePlate] = useState(inv_data.vehicle_plate || "")

    const grantAccess = async () => {

        if (!name) {
            alert("Introduzca el nombre del visitante")
            return
        }

        let data = {
            "is_here": true,
            "is_here_date": new Date(),
            "is_here_auth": guardDocument.doc_data.personal_info.name,
            "is_here_auth_email": guardDocument.doc_data.account_info.email,
            "is_here_auth_id": guard.uid,
            "is_here_auth_range": "guard",
            "vehicle_brand": vehicleBrand ? vehicleBrand : null,
            "vehicle_model": vehicleModel ? vehicleModel : null,
            "vehicle_plate": vehiclePlate ? vehiclePlate : null,
            "visitor_name": name ? name : null,
        }

        console.log(data)

        let response = await updateData("fast_services", inv_id, data)

        let done = response[0];
        let error = response[1]

        if (done) {
            setGranting(false)
            cbClose()
        }

        if (error) {
            alert("Error ❌, sucedio un error, intente nuevamente.")
        }
    }

    return (
        <Modal
            open={true}
            onClose={() => {
                !granting && cbClose()
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>

                <TextField
                    name="name"
                    id="name"
                    label='Nombre del visitante'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    autoFocus
                />

                <TextField
                    name="vehiclePlate"
                    id="vehiclePlate"
                    label='Placa del vehículo'
                    value={vehiclePlate}
                    onChange={(e) => setVehiclePlate(e.target.value)}
                    fullWidth
                />

                <TextField
                    name="vehicleBrand"
                    id="vehicleBrand"
                    label='Marca del vehículo'
                    value={vehicleBrand}
                    onChange={(e) => setVehicleBrand(e.target.value)}
                    fullWidth
                />

                <TextField
                    name="vehicleModel"
                    id="vehicleModel"
                    label='Modelo del vehículo'
                    value={vehicleModel}
                    onChange={(e) => setVehicleModel(e.target.value)}
                    fullWidth
                />

                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <Button
                        variant='contained'
                        disabled={granting}
                        color='primary'
                        style={{
                            color: "white",
                            marginTop: 15
                        }}
                        onClick={grantAccess}
                    >
                        Dar Acceso
                    </Button>
                    {
                        granting &&
                        <CircularProgress color='primary' />
                    }
                </div>

            </div>
        </Modal>
    );
}

export default FastServicesModal;
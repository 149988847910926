import React, { useEffect } from 'react';


const AccessCameras = () => {

    useEffect(() => {


    }, [])
    return (
        <div
            className='splitContainer'
        >
            <p>Access cameras</p>
        </div>
    );
}

export default AccessCameras;
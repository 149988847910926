import { Button } from "@material-ui/core"
import React, { useState } from "react"
import Constants from "../../../utils/Constants"
import "./Definitions_R.css"
import { Link } from "react-scroll"
import InfoModal from "../Modals/InfoModal"
const DefinitionsRCard = ({
  title,
  definition,
  definition2,
  img,
  imgalt,
  buttonTitle,
  buttonColor,
  modal
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div
      style={{ width: "100%" }}
      className="mt-0 mb-16 "//reveal-from-bottom
      data-reveal-delay="30"
    >
      <div className="card" style={{
        backgroundColor: "white",
        borderRadius: 15,
        boxShadow: "2px 5px 9px #000000",
      }}>
        <div style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 30, paddingBottom: 10 }}>
          <div style={{ height: 200, justifyContent: "center", display: "flex" }}>
            <img src={img} alt={imgalt} style={{ width: "95%" }} />
          </div>
          <div className="definition_cardText" >
            <div>
              <h4 style={{ color: Constants.colors.primaryColor }}>{title}</h4>
            </div>
            <p className="cardDefinition" >{definition}</p>
            <p className="cardDefinition" >{definition2}</p>
          </div>
          <div style={{ height: "10%", justifyContent: "center", display: "flex" }}>
            {
              buttonTitle === "Empieza ahora" &&
              <Link
                activeClass="active"
                className="Hero_ResidentialLanding"
                to="Prices"
                spy={true}
                smooth={true}
                duration={1000}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: buttonColor,
                    marginTop: "1%",
                    marginBottom: "3%",
                    borderRadius: 30,
                    color: "white",
                    fontWeight: "bold"
                  }}
                // onClick={(e) => {
                //     e.preventDefault()
                //     window.location.href = '/precios'
                // }}
                >
                  {buttonTitle}
                </Button>
              </Link>
            }

            {
              buttonTitle === "Conocer más" &&
              <Button
                variant="contained"
                style={{
                  backgroundColor: buttonColor,
                  marginTop: "1%",
                  marginBottom: "3%",
                  borderRadius: 30,
                  color: "white",
                  fontWeight: "bold"
                }}
                onClick={() => setShowModal(true)}
              // onClick={(e) => {
              //     e.preventDefault()
              //     window.location.href = '/precios'
              // }}
              >
                {buttonTitle}
              </Button>
            }
          </div>
        </div>
      </div>
      {
        showModal &&
        <InfoModal
          body={modal}
          callback={() => setShowModal(false)}
        />
      }
    </div>
  )
}

export default DefinitionsRCard
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import ContactForm from "./contactForm";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Helmet } from "react-helmet";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const Contact = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {children}
          <Helmet>
            <title>Contacto | Welcomi</title>
            <meta name="description"
              content="Contactanos por medio del chat, en nuestras redes sociales o en contacto@welcomi.app, ventas@welcomi.app." />
            <base href="/contacto"></base>
          </Helmet>
          <h2>Contacto</h2>

          <div className="paragraph">
            <div className="contactIcons">

              <a href="https://wa.me/message/SFNH574RQRI5J1" rel="noopener noreferrer">
                <WhatsAppIcon
                  titleAccess="Contactar Whatsapp"
                  fontSize="large"
                  style={{ color: "black" }}
                />
              </a>

              <a
                href="https://www.facebook.com/Welcomiapp-342707920364422"
                target="_blank"
                rel="noopener noreferrer">

                <FacebookIcon
                  titleAccess="Contactar facebook"
                  fontSize="large"
                  style={{ color: "black" }}
                />
              </a>


              <a
                href="https://twitter.com/welcomiapp"
                target="_blank"
                rel="noopener noreferrer">
                <TwitterIcon
                  titleAccess="Contactar twitter Welcomi"
                  fontSize="large"
                  style={{ color: "black" }}
                />
              </a>

              <a
                href="https://www.instagram.com/welcomi.app/"
                target="_blank"
                rel="noopener noreferrer">
                <InstagramIcon
                  titleAccess="Contactar instagram Welcomi"
                  fontSize="large"
                  style={{ color: "black" }}
                />
              </a>


            </div>
            <p>
              Para dudas o reporte de problemas:{" "}
              <a className="email_label" href={"mailto:contacto@welcomi.app"}>contacto@welcomi.app</a>
            </p>

            <p>
              Para cotizaciones y ventas:{" "}
              <a className="email_label" href={"mailto:ventas@welcomi.app"}>ventas@welcomi.app</a>
            </p>

          </div>
          <ContactForm />
          {/* <div>
          <ContactBox_R />
          </div> */}
        </div>
      </div>
    </section>
  );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;

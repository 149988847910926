import React, { useEffect, useState } from 'react';
import Fire, { auth, db, fbfs, storage } from '../../../Fire';
import classNames from "classnames";
import DownloadCard from '../Downloads/DownloadCard';
import Constants from '../../../utils/Constants';
import Welcomi_rounded from "../../../assets/images/welcomi_rounded.png"
import CircularProgress from '@material-ui/core/CircularProgress';
import RegistroUpdateProfile from '../Register/RegistroUpdateProfile';
import { uploadImage } from '../../../cases/uploads/uploadImage';
import RegistroQR from '../Register/RegistroQR';
import { useHistory } from 'react-router-dom';

const RegisterByEmail = (props, {
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    hasBgColor,
    invertColor,
}) => {
    const outerClasses = classNames(
        "section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );
    const history = useHistory();

    const [loading, setLoading] = useState(true)
    const [isLogged, setIsLogged] = useState(false);
    const [userDocument, setUserDocument] = useState(null);

    useEffect(() => {
        if (auth.isSignInWithEmailLink(props.match.params.email)) {
            setIsLogged(true)
            // Validar si el usuario ya ha creado su userDocument
            getUserDocument(auth.currentUser.uid)
        } else {
            auth.signInWithEmailLink(props.match.params.email, window.location.href)
                .then((result) => {
                    setIsLogged(true)

                    // Validar si el usuario ya ha creado su userDocument
                    getUserDocument(result.user.uid)
                }).catch(error => {
                    // console.log(error)
                    setIsLogged(false)
                    setLoading(false)
                })
        }
    }, [])

    //Validate if user has document
    const getUserDocument = async (user_uid) => {
        let snapshot = await db
            .collection("users")
            .doc(user_uid)
            .get()
            .catch(error => {
                alert("Sucedio un error, solicita que te envien el link de registro nuevamente.")
            })

        if (!snapshot.exists) {
            await setUserDocument(null)
        }

        if (snapshot.exists) {
            setUserDocument({ doc_id: snapshot.id, doc_data: snapshot.data() })
        }

        setLoading(false)
    }


    const createProfile = async (data, gender, birthdate, set_loading, setError) => {
        let user_data = {
            devices: [],
            locality_info: [],
            legal: {
                termsnconditions: true,
                privacy: true
            },
            configurations: {
                integrate_with_calendar: false,
            },
            personal_info: {
                name: data.first_name.trim() + " " + data.last_name.trim() + " " + data.second_last_name.trim(),
                name_lowercase: data.first_name.toLowerCase().trim() + " " + data.last_name.toLowerCase().trim() + " " + data.second_last_name.toLowerCase().trim(),
                first_name: data.first_name.trim(),
                last_name: data.last_name.trim() + " " + data.second_last_name.trim(),
                birthdate: birthdate,
                gender: gender,
                cars: []
            },
            account_info: {
                searchable: true,
                invitable_by_mail: true,  // User decide if he/she is able to be invited by others manully writing his/her main
                ID_image: null,
                ID_image_verified: "0",     //0 -> not verified, 1 -> accepted, 2 -> rejected
                profile_image: null,
                profile_image_verified: "0", //0 -> not verified, 1 -> accepted, 2 -> rejected
                email: auth.currentUser.email,
                range: "Visitor",
                created_date: fbfs.FieldValue.serverTimestamp(),
                last_login: null,
                last_logout: null,
                banning_reason: "",
                is_banned: false,
                is_active: true,         // If user decides to deactivate its acount for a while
                modified_at: null,
                following: 0,
                followers: 0,
                locality_reports: 0,    // times a locality reported user, Para descentralizar la seguridad, cada mala situacion es pq el usuario hizo algo malo en alguna localidad
                created_in: "web",
            },
        }

        let datePicture = new Date();
        //Hour_minutes_seconds-day-month-year
        let hour = String(datePicture.getHours());
        let min = String(datePicture.getMinutes());
        let sec = String(datePicture.getSeconds());
        let dd = String(datePicture.getDate()).padStart(2, "0");
        let mm = String(datePicture.getMonth() + 1).padStart(2, "0");
        let yyyy = datePicture.getFullYear();

        const pp_name = storage.ref(`Users/PP/${auth.currentUser.uid}/${"PP-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec}`)
        const pp_resized_name = storage.ref(`Users/PP/${auth.currentUser.uid}/${"PP-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec + "_500x500"}`)

        let response = await uploadImage(data.profilePicture[0], pp_name, pp_resized_name)

        let pp_link = response[0]
        let pp_error = response[1]

        if (pp_link) {
            user_data.account_info.profile_image = pp_link
        }

        if (pp_error) {
            setError("profilePicture",
                {
                    type: "manual",
                    message: "No se pudo cargar la foto de perfil. Intente nuevamente."
                })
            set_loading(false)
            try {
                await Fire.deleteImages(pp_name).catch(error => {
                    // console.log(error)
                })
            } catch { }
            try {
                await Fire.deleteImages(pp_resized_name).catch(error => {
                    // console.log(error)
                })
            } catch { }

            return
        }

        //ID image
        const ID_name = storage.ref(`Users/IDs/${auth.currentUser.uid}/${"ID-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec}`)
        const ID_resized_name = storage.ref(`Users/IDs/${auth.currentUser.uid}/${"ID-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec + "_500x500"}`)

        let response2 = await uploadImage(data.idPicture[0], ID_name, ID_resized_name)

        let ID_link = response2[0]
        let ID_error = response2[1]

        if (ID_link) {
            user_data.account_info.ID_image = ID_link
        }

        if (ID_error) {
            setError("idPicture",
                {
                    type: "manual",
                    message: "No se pudo cargar la foto de tu identificación. Intente nuevamente."
                })
            set_loading(false)
            try {
                await Fire.deleteImages(ID_name).catch(error => {
                    // console.log(error)
                })
            } catch { }
            try {
                await Fire.deleteImages(ID_resized_name).catch(error => {
                    // console.log(error)
                })
            } catch { }

            return
        }

        await Fire.setData("users", auth.currentUser.uid, user_data)
            .then(async () => {
                set_loading(false)

                auth.currentUser.updateProfile({
                    displayName: data.first_name.trim() + " " + data.last_name.trim() + " " + data.second_last_name.trim(),
                    photoURL: pp_link,
                })
            })
            .catch(error => {
                set_loading(false)

                setError("idPicture",
                    {
                        type: "manual",
                        message: "Ocurrio un error, verifique su señal e intentelo nuevamente."
                    })
            })

        setUserDocument(true)
    }

    const signOut = async () => {
        Fire.handleSignOut()
        history.push("/");
    }

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                {children}
                {
                    loading ?
                        <div style={{ justifyContent: "center", display: "flex" }}>
                            <CircularProgress style={{ 'color': Constants.colors.primaryColor }} />
                        </div>
                        :
                        isLogged ?
                            userDocument ?
                                <RegistroQR email={auth.currentUser.email} finish={signOut} />
                                :
                                <RegistroUpdateProfile onSubmitToFirebase={createProfile} />
                            :
                            <div>
                                <h3 style={{ textAlign: "center" }}>Atención, el enlace ha sido utilizado, es inválido o caduco :(</h3>
                                <DownloadCard
                                    title="Descarga Welcomi!"
                                    description="Si tienes tu casa particular o eres residente de una zona residencial, esta es la aplicación que debes descargar. ¿Qué esperas? Vive tu hogar y enterate de lo que sucede en tu comunidad ahora. Tenemos muchas funcionalidades para ti y tu familia."
                                    app_img={Welcomi_rounded}
                                    href_ios={Constants.download_links.ios_resident_visitor}
                                    href_android={Constants.download_links.android_resident_visitor}
                                />
                            </div>
                }
            </div>
        </section>
    )
};

export default RegisterByEmail;

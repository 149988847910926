import React from 'react';

export const accesscontrol_body = (
    <div>
        <h4 id="simple-modal-title">Control de accesos</h4>
        <p id="simple-modal-description">
            Es la herramienta perfecta para el cuerpo de seguridad, un control totalmente digital y moderno.
        </p>

        <p id="simple-modal-description">
            El control de accesos de Welcomi está altamente centrado en la seguridad de la localidad, le brinda una herramienta al cuerpo de seguridad donde puede estar al pendiente de quienes son los invitados que llegaran al residencial, a que hora, si se les permite la entrada después de cierto tiempo, quien los invito, quien se encuentra dentro de la localidad y mucho más.
        </p>

        <p id="simple-modal-description">
            Nuestro principal objetivo es llevar la seguridad a otro nivel, dentro de Welcomi encontrarás mucho más funcionalidades.
        </p>
    </div>
);

export const democratization_body = (
    <div>

        <h4 id="simple-modal-title">Democratización</h4>
        <p id="simple-modal-description">
            Tradicionalmente la seguridad es responsabilidad total del cuerpo de seguridad.
        </p>

        <p id="simple-modal-description">
            Welcomi brinda la capacidad de que ahora todos los residentes participen en ella desde su smartphone de una manera natural y sin comprometer la privacidad.
        </p>

        <p id="simple-modal-description">
            La experiencia para el residente es única e inigualable y le hace sentir más seguro en su zona residencial.
        </p>

        <p id="simple-modal-description">
            Welcomi ofrece al cuerpo de seguridad un sistema descentralizado, por ejemplo, si un visitante tiene antecedentes ilícitos en otro residencial, el cuerpo de seguridad se enterará con anticipación previa a la llegada de este visitante, de esta manera podrá estar preparado para abordar la situación e incluso prevenir algún percance.
        </p>
    </div>
);

export const communication_body = (
    <div>
        <h4 id="simple-modal-title">Sistema de comunicación</h4>
        <p id="simple-modal-description">
            Olvida el desorden en el grupo del residencial o de los boletines pegados en las paredes por todo el residencial.
        </p>

        <p id="simple-modal-description">
            Diseñamos un sistema de comunicación eficiente el cual hace llegar los comunicados oficiales a los residentes de manera instantánea, organizada y digital.
        </p>
    </div>
);


export const eco_body = (
    <div>
        <h4 id="simple-modal-title">Medio ambiente</h4>
        <p id="simple-modal-description">
            Estamos comprometidos con el medio ambiente, por esto cada vez añadimos
            más funcionalidades para evitar el papeleo que se genera en una zona
            residencial.
        </p>

        <p id="simple-modal-description">
            Dentro de nuestra plataforma encontrarás un sin fin de funcionalidades
            que te ayudan a organizar tu zona residencial de manera digital y muy
            sencilla.
        </p>
    </div>
);
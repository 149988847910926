import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import "./Registro.css"
const RegistroQR = ({
    params,
    email,
    finish
}) => {

    // useEffect(() => {
    //     console.log(email)
    // }, [])
    return (
        <div>
            <h2>Tu identificador para <span className="text-color-primary">Welcomi</span>!!</h2>

            <p>Este código QR te servirá para ingresar a las distintas localidades que utilicen Welcomi cuando el residente no te haya creado una invitación.</p>

            <p>Guárdalo en un lugar seguro. Si lo pierdes, no te preocupes, lo encontrarás en tu perfil en la aplicación siempre, solo debes iniciar sesión con tu cuenta.</p>

            <p>Dentro de la aplicación podrás llevar el registro de todas tus visitas. Tenemos funciones para tu casa aún cuando no vivas en un residencial, descargala ahora!</p>

            <div className="QRContainer">
                <QRCode
                    value={JSON.stringify({
                        email: email,
                        invitation_id: null,
                        type: null
                    })}
                    ecLevel="H"
                    bgColor="#FFF"
                    fgColor="#000"
                    // style={{ width: "700px", height: 500 }}
                    logoWidth={100}
                    logoHeight={100}
                    size={325}
                    logoImage={require("../../../assets/images/welcomi-certified.png")}
                />
            </div>

            <div className="buttonTerminar">
                <button onClick={finish}>Terminar</button>
            </div>
        </div>
    )

}

export default RegistroQR;

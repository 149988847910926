import React from "react"
import ReactPlayer from 'react-player'

import Guia_RegistarResiencial from "../../../assets/images/Guia_RegistrarResiencial.jpeg"
import Guia_RegistarResiencial2 from "../../../assets/images/Guia_RegistrarResiencial2.jpeg"
import Guia_RegistarResiencial3 from "../../../assets/images/Guia_RegistrarResiencial3.PNG"

const GuiaRegistrarResidencial = () => {
  return (
    <div style={{ marginRight: "5%", marginLeft: "2%" }}>
      <h2>Registrar mi residencial en Welcomi</h2>
      <h6>Requisitos:</h6>
      <ul>
        <li>Descargar la aplicación de Welcomi Captain, la encontrarás en la sección de "Descargas".</li>
        <li>Crear una cuenta dentro de la aplicación.</li>
        <li>Ser el administrador o el representante de la zona residencial que estas por registrar.</li>
      </ul>
      <ReactPlayer
          url='https://www.youtube.com/watch?v=QiYe7suTHyY&t=17s' // Guía Registrar Guardias
          style={{ marginInline: 'auto', marginBlock: 32, maxWidth: '100%' }}
          progressInterval={true}
          className="react-player"
      />
      <p>Una vez hayas registrado tu cuenta verás la siguiente ventana. Da click en el botón "Crear localidad" y selecciona el tipo de residencial.</p>
      <view style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={Guia_RegistarResiencial}
          style={{ width: "25%", height: "25%" }}
          alt='Guía Registrar Residencial'
        />
      </view>
      <p>Completa todos los campos solicitados y da click en "Crear localidad"</p>
      <view style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={Guia_RegistarResiencial2}
          style={{ width: "25%", height: "25%" }}
          alt='Guía Registrar Residencial 2'
        />
        <img
          src={Guia_RegistarResiencial3}
          style={{ width: "25%", height: "25%" }}
          alt='Guía Registrar Residencial 3'
        />
      </view>
      <p>Listo, tu localidad ha sido creada, ahora invita a residentes y guardias a formar parte de ella.</p>
      <p>Recuerda que es recomendable hacer el proceso de verificación, te recomendamos leas la guía "Verificar mi localidad"</p>
    </div>
  )
}
export default GuiaRegistrarResidencial

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import AdP from "../../assets/pdfs/AdP-Welcomi.pdf";
// import AllPagesPDFViewer from "src/utils/all-pages.js";
import SinglePagePDFViewer from "../../utils/single-page";
import "../../components/Residencial/AdStyles/pdfviewer.css";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const privacidad = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {children}
          <div className="Appp">
            <h2>Aviso de Privacidad</h2>
            <div className="all-page-container">
              <SinglePagePDFViewer pdf={AdP} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

privacidad.propTypes = propTypes;
privacidad.defaultProps = defaultProps;

export default privacidad;

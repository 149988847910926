import React, { useContext, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { GuardContext } from '../../../Context/GuardContext';

var finish = false;
var firstTimeAsked = false;

const Dashboard = () => {
    const {
        guardDocument,
        getGuardDocument,
        guardActiveLocality,
        guardConf,
        isLoading,
        isLoggedIn,
        setIsLoading,
        getLocalityConf,
        getLocalityAddresses,
        _getRTInvitations,
        _getRTFastServices,
    } = useContext(GuardContext);


    /*
    Get ip, country, lon, lat, but is not exactly
    is not usefull this ip for us, but maybe the country
    const res = await fetch('https://geolocation-db.com/json/')
        const data = await res.json()
        const {IPv4} = data
    */

    useEffect(() => {
        const getGuardInfo = async () => {
            await getGuardDocument()
            await getLocalityConf()
        }

        setIsLoading(true)
        if (isLoggedIn) {
            if (!guardDocument) {
                if (!finish) {
                    getGuardInfo()
                    finish = true
                }
            }
        } else {
            // window.location.href = '/'
        }
    }, [])

    useEffect(() => {
        const getResidentialData = async () => {
            if (guardActiveLocality && guardConf && finish && !firstTimeAsked) {
                if (guardDocument.doc_data[guardActiveLocality[0].locality_ID].locality_range === "lobby-guard") {
                    if (guardConf.setup.access_control_invs === "1" || guardConf.setup.access_control_invs === "2") {
                        firstTimeAsked = true
                        await getLocalityAddresses()
                        await _getRTInvitations()
                        await _getRTFastServices()
                    }
                }
            }
        }

        getResidentialData()

        if (guardActiveLocality && guardConf && finish && firstTimeAsked) {
            setIsLoading(false)
        }

    }, [guardConf])



    if (isLoading || !guardDocument || !guardActiveLocality) {
        return <CircularProgress color='primary' />
    }

    return (

        <div>
            <p>Dashbord</p>
            <p></p>
            <p>{guardDocument.doc_data.personal_info.name}</p>
            <p>{guardActiveLocality[0].locality_name}, {guardActiveLocality[0].locality_ID}</p>
        </div>
    );
}

export default Dashboard;

import React from "react"
// import W_triangle from "../../../assets/svgs/W_triangle.svg"

const GuiaQueEsWelcomi = (props) => {
  return (
    <div style={{ marginRight: "5%", marginLeft: "2%" }}>
      <h2>Donde surge Welcomi?</h2>
      <p>
        La idea de Welcomi nace de 2 jóvenes que han estudiado distintas zonas residenciales a lo largo del mundo, han sido residentes y visitantes, han trabajado con el cuerpo de seguridad, con la administración o mesa directiva.
      </p>
      <p>
        Gracias a lo anterior está por entendido cuáles son las necesidades, prioridades y la revolución tecnológica que una zona residencial necesita.
      </p>
      <h2>Que es Welcomi?</h2>
      <p>
        Welcomi es el canal perfecto para la correcta operación de una zona residencial usando la tecnología.
      </p>
      <p>
        Nuestra plataforma está dividida en 3 áreas las cuales están conectadas entre sí: Welcomi - Welcomi Captain - Welcomi Guard.
      </p>
      {/* <img
          src={W_triangle}
          style={{ width: "20%", textAlign: "center" }}
      /> */}
      <p>
        En <span className="text-color-primary">Welcomi</span> tanto residentes como visitantes podrán gozar de numerosas funcionalidades para administrar su hogar, el residente tendrá el plus de poder llevar la zona residencial en la que habita, ahora en su smartphone.
        <br />
        <br />
        Nuestra plataforma funciona mediante un <strong><i>sistema de invitaciones</i></strong> el cual ayuda a <strong><i>descentralizar la seguridad residencial</i></strong>, estos dos conceptos son los que <u>diferencian a Welcomi de la competencia</u>, ambos serán explicados más adelante a detalle.
        <br /><br />
        <span className="text-color-primary">Welcomi Captain</span> está diseñada para los administradores de zonas residenciales. Podrás encontrar un sin fin de funcionalidades para administrar tu residencial, estar en contacto con el mismo, entre muchas otras. Más adelante hablaremos a detalle sobre cada una de estas funcionalidades, como funcionan y para qué sirven.
        <br />
        <br />
        <span className="text-color-primary">Welcomi Guard</span> es especificamete para trabajadores de una zona residencial, pensado principalmente para el cuerpo de seguridad que labora en el control de accesos del residencial, aunque a su vez, puede ser utilizado por el resto del cuerpo de seguridad, personal de mantenimiento o cualquier otro trabajador de clase obrera que labore dentro del residencial.
        <br />
      </p>
      <p>
        Nuestros objetivos principales son:
      </p>
      <ul>
        <li>La seguridad residencial.</li>
        <li>La comunicación Capitán - Cuerpo de seguridad - Residente - Visitante.</li>
        <li>La administración residencial.</li>
        <li>Ser inclusivos.</li>
        <li>Mantenerlo simple.</li>
      </ul>
      <p>Puedes empezar a usar Welcomi de manera gratuita en tu residencial. Welcomi es modular, no importa si es un residencial pequeño o grande, está diseñado para todos.</p>
      <p style={{ color: "gray" }}>Te invitamos a seguir leyendo nuestras guías para que conozcas a profundidad como operamos.</p>
    </div>
  )
}
export default GuiaQueEsWelcomi

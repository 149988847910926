import { Avatar, Button, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import Constants from '../../../utils/Constants';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckIcon from '@material-ui/icons/Check';
import TodayIcon from '@material-ui/icons/Today';
import SettingsIcon from '@material-ui/icons/Settings';
import Invitations_Modal from './Invitations_Modal';
import Invitations_DenyModal from './Invitations_DenyModal';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

const InvitationsContainer = (props) => {
    const classes = useStyles();

    // useEffect(() => {
    //     console.log(props)
    // }, [])

    const {
        type
    } = props.doc_data.aalpha

    const {
        invitation_date,
        message,
        random_number,
        certified
    } = props.doc_data.data

    const {
        profile_image,
        ID_image,
        name
    } = props.doc_data.receiver

    const {
        // s_location,
        locality_name,
        apartment_reference,
        apartment_number,
        // phone_number
    } = props.doc_data.sender

    const {
        call_when_arrive,
        car_services,
        // deny_access_date,
        // deny_access_unpunctuality
        extra_people,
        extra_people_quantity
    } = props.doc_data.permissions

    const [accessModal, setAccessModal] = useState(false)
    const [denyModal, setDenyModal] = useState(false)

    const isSavedGuest = type.includes('_saved_guest') ? true : false;

    // let date = !props.searchedByQR ? invitation_date.toDate() : new Date(invitation_date._seconds * 1000)
    let date = invitation_date.toDate();
    return (
        <ListItem
            alignItems="flex-start"
        >
            <div className='fullWidthWelcomiCard'>
                <div style={styles.profileContainer}>
                    <Avatar alt="Welcomi user" src={!isSavedGuest ? profile_image : ID_image} className={classes.large} />
                </div>

                <div>
                    <Typography
                        variant='h6'
                        style={styles.fineText}>
                        {name}
                    </Typography>

                    {
                        !isSavedGuest ?
                            <Typography
                                variant='subtitle1'
                                style={{ color: "gray", fontStyle: "italic", marginBottom: 3 }}
                            >
                                {type === "invitation_residential" ? "Visita tradicional" : "Visita de trabajador"}
                            </Typography>
                            :
                            <Typography
                                variant='subtitle1'
                                style={{ color: "gray", fontStyle: "italic", marginBottom: 3 }}
                            >
                                {type === "invitation_residential_saved_guest" ? "Visita tradicional" : "Visita de trabajador"}
                            </Typography>
                    }

                    <Typography
                        variant='subtitle1'
                        style={{ color: Constants.colors.secondaryColor, textAlign: "right", fontWeight: "bold", marginVertical: 5 }}
                    >
                        Invitación ID: {random_number}
                    </Typography>

                    {
                        type === "invitation_worker_residential" &&
                        <div
                            style={styles.row}
                        >
                            <SettingsIcon color='primary' />
                            <Typography
                                variant='subtitle1'
                                style={styles.infoText}
                            >
                                {props.doc_data.receiver.worker_info.role}
                            </Typography>
                        </div>
                    }

                    <div
                        style={styles.row}
                    >
                        <LocationOnIcon color='primary' />
                        <Typography
                            variant='subtitle1'
                            style={styles.infoText}
                        >
                            {locality_name}, {apartment_reference}, {apartment_number}
                        </Typography>
                    </div>

                    <div
                        style={styles.row}
                    >
                        <CheckIcon color='primary' />
                        <Typography
                            variant='subtitle1'
                            style={styles.infoText}
                        >
                            {props.doc_data.sender.name}
                        </Typography>
                    </div>

                    <div
                        style={styles.row}
                    >
                        <TodayIcon color='primary' />
                        <Typography
                            variant='subtitle1'
                            style={styles.infoText}
                        >
                            {date.getDate() + "/" + Constants.months[date.getMonth()] + "/" + date.getFullYear()}

                        </Typography>
                    </div>

                    <div style={{ marginTop: 10 }} />
                    {
                        call_when_arrive &&
                        <Typography
                            variant='body2'
                            style={styles.permissionText}
                        >
                            - Llamar al residente para informar llegada del visitante.
                        </Typography>
                    }

                    {
                        car_services &&
                        <Typography
                            variant='body2'
                            style={styles.permissionText}
                        >
                            - Permitido llegar en servicio de transporte.
                        </Typography>
                    }

                    {
                        extra_people &&
                        <Typography
                            variant='body2'
                            style={styles.permissionText}
                        >
                            - Permitido entrar con {extra_people_quantity} personas.
                        </Typography>
                    }

                    {
                        message &&
                        <Typography
                            variant='body1'
                            style={styles.messageText}
                        >
                            El residente dice: {message}
                        </Typography>
                    }
                    {
                        isSavedGuest &&
                        <Typography
                            variant='body2'
                            style={styles.alertText}
                        >
                            Usuario registrado por el residente manualmente, no es usuario Welcomi ⚠️</Typography>
                    }

                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            style={{ color: "white", fontWeight: "bold", marginBottom: 15 }}
                            onClick={() => setAccessModal(true)}
                        >
                            Validar datos
                        </Button>
                    </div>

                    {
                        accessModal &&
                        <Invitations_Modal
                            cbClose={() => setAccessModal(false)}
                            user_uid={props.doc_data.receiver.user_uid}
                            inv_data={props.doc_data}
                            inv_id={props.doc_id}
                            isSavedGuest={isSavedGuest}
                        />
                    }

                    <div>
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={() => setDenyModal(true)}
                        >
                            Negar acceso
                        </Button>
                    </div>

                    {
                        denyModal &&
                        <Invitations_DenyModal
                            cbClose={() => setDenyModal(false)}
                            inv_id={props.doc_id}
                        />
                    }
                    {
                        certified &&
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <img
                                alt='Welcomi certified'
                                src={require('../../../assets/images/welcomi-certified.png')}
                                style={{ width: 32, height: 32 }}

                            />
                        </div>
                    }
                </div>
            </div>
        </ListItem>
    );
}

export default InvitationsContainer;

const styles = {
    profileContainer: {
        display: "flex",
        justifyContent: "center"
    },
    invitationInfo: {
        flexDirection: "column",
        width: "75%"
    },
    fineText: {
        fontWeight: "bold",
        color: Constants.colors.primaryColor
    },
    row: {
        display: "flex",
        marginTop: 2.5,
        marginbottom: 2.5,
        flexDirection: "row"
    },
    infoText: {
        alignSelf: "center",
        flexWrap: "wrap",
        width: "90%"
    },
    permissionText: {
        display: "flex",
        marginTop: 2.5,
        marginbottom: 2.5
    },
    alertText: {
        display: "flex",
        marginTop: 2.5,
        marginbottom: 2.5,
        color: "red",
    },
    messageText: {
        color: "gray",
        fontStyle: "italic",
        fontWeight: "500",
        marginTop: 10
    }
}
import { Avatar, Button, List, makeStyles, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { DemoContext } from '../../../Context/DemoContext';
import Constants from '../../../utils/Constants';
import DPollContainer from './DPollContainer';
import DPostsContainer from './DPostsContainer';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

const DPosts = () => {
    const classes = useStyles();
    const { posts, captain, addPost } = useContext(DemoContext)

    const [message, setMessage] = useState("")

    const submit = () => {
        const data = {
            created_at: new Date(),
            date: new Date(),
            hide: false,
            likes: 0,
            message: message,
            photo_URL: "",
            posted_by: captain.name,
            profile_image: captain.profile_image,
            type: "post",
            updated_at: null,
            user_email: captain.email,
            user_range: captain.user_range,
            user_uid: captain.user_uid,
        }

        addPost(data)

        setMessage("")
    }

    const renderPosts = () => {
        return posts.map((item, index) => {
            if (item.type === "post") {
                return <DPostsContainer {...item} key={index} />
            }

            if (item.type === "poll") {
                return <DPollContainer {...item} key={index} />
            }
        }
        )
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center'
        }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div className='postWithImageWelcomiCard'>
                    <div style={{
                        marginTop: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "row",
                    }}>

                        <Avatar
                            alt="Welcomi user"
                            src={captain.profile_image}
                            className={classes.large}
                        />

                        <TextField
                            name='message'
                            id="message"
                            label="Escribe un comunicado..."
                            variant="outlined"
                            type='text'
                            onChange={e => setMessage(e.target.value)}
                            value={message}
                            fullWidth
                            style={{ marginLeft: 10 }}
                        />
                    </div>

                    <div className='alignRight'>
                        <Button
                            variant="contained"
                            style={!message ? styles.disableButton : styles.submitButton}
                            onClick={submit}
                            disabled={!message}
                        >
                            Publicar comunicado
                        </Button>
                    </div>
                </div>
            </div>

            <List
                component="div"
                disablePadding
            >
                {renderPosts()}
            </List>
        </div>
    );
}

export default DPosts;

const styles = {
    submitButton: {
        backgroundColor: Constants.colors.primaryColor,
        color: "white",
        fontWeight: "bold",
        marginRight: 10
    },
    disableButton: {
        backgroundColor: "gray",
        color: "white",
        fontWeight: "bold",
        marginRight: 10
    }
}
import { Button, ListItem, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import Constants from '../../../utils/Constants';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckIcon from '@material-ui/icons/Check';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FastServicesModal from './FastServicesModal';
import { GuardContext } from '../../../Context/GuardContext';
import { updateData } from '../../../cases/Firecases/updateData';
import { deleteDocument } from '../../../cases/Firecases/deleteDocument';
const FastServicesContainer = (props) => {
    const { guardDocument, guard } = useContext(GuardContext);

    const {
        company,
        fast_service,
        resident_name,
        resident_apartment_reference,
        resident_apartment,
        is_here
    } = props.doc_data

    const [accessModal, setAccessModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const grantCheckout = async () => {
        setLoading(true)
        let data = {
            "is_gone": true,
            "is_gone_date": new Date(),
            "is_gone_auth": guardDocument.doc_data.personal_info.name,
            "is_gone_auth_email": guardDocument.doc_data.account_info.email,
            "is_gone_auth_id": guard.uid,
            "is_gone_auth_range": "guard"
        }

        let response = await updateData("fast_services", props.doc_id, data)

        let done = response[0]
        let error = response[1]

        if (done) {
            alert("Éxito ✅, se ha registrado su salida")
        }
        if (error) {
            alert("Error ❌, sucedio un error, intente nuevamente.")
        }

        setLoading(false)
    }

    const denyAccess = async () => {
        if (window.confirm("Seguro que deseas negar el acceso?") === true) {
            setLoading(true)

            const response = await deleteDocument("fast_services", props.doc_id)

            let done = response[0]
            let error = response[1]

            if (done) { }
            if (error) {
                alert("Error ❌, sucedio un error, intente nuevamente.")
            }
        }
    }

    return (
        <ListItem
            alignItems="flex-start"
        >
            <div className='fullWidthWelcomiCard'>

                <div>
                    <Typography
                        variant='h6'
                        style={styles.fineText}>
                        {company}
                    </Typography>
                </div>

                <div style={styles.row}>
                    <LocalShippingIcon color='primary' />
                    <Typography
                        variant='subtitle1'
                        style={styles.infoText}
                    >
                        {fast_service}
                    </Typography>
                </div>

                <div style={styles.row}>
                    <LocationOnIcon color='primary' />
                    <Typography
                        variant='subtitle1'
                        style={styles.infoText}
                    >
                        {resident_apartment_reference}, {resident_apartment}
                    </Typography>
                </div>

                <div style={styles.row}>
                    <CheckIcon color='primary' />
                    <Typography
                        variant='subtitle1'
                        style={styles.infoText}
                    >
                        {resident_name}
                    </Typography>
                </div>

                {
                    !is_here &&
                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            style={{ color: "white", fontWeight: "bold", marginBottom: 15 }}
                            onClick={() => setAccessModal(true)}
                        >
                            Validar Datos
                        </Button>

                        <Button
                            variant='contained'
                            fullWidth
                            onClick={denyAccess}
                        >
                            Negar acceso
                        </Button>

                    </div>
                }

                {
                    accessModal &&
                    <FastServicesModal
                        cbClose={() => setAccessModal(false)}
                        inv_data={props.doc_data}
                        inv_id={props.doc_id}
                    />
                }

                {
                    is_here &&
                    <Button
                        variant='contained'
                        fullWidth
                        style={{
                            backgroundColor: "green",
                            color: "white",
                            fontWeight: "bold",
                            marginBottom: 15
                        }}
                        onClick={grantCheckout}
                        disabled={loading}
                    >
                        Dar Salida
                    </Button>
                }
            </div>
        </ListItem>
    );
}

export default FastServicesContainer;

const styles = {
    fineText: {
        fontWeight: "bold",
        color: Constants.colors.primaryColor
    },
    row: {
        display: "flex",
        marginTop: 2.5,
        marginbottom: 2.5,
        flexDirection: "row"
    },
}
import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { db } from '../../../../Fire';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Constants from '../../../../utils/Constants';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import StarIcon from '@material-ui/icons/Star';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: Constants.colors.secondaryColor,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 100,
    },
});

const options = ['Información', 'Foto de identificación'];

const AAllLocalities = () => {
    const classes = useStyles();

    const [localities, setLocalities] = React.useState([]);
    const [firstDoc, setFirstDoc] = React.useState(null);
    const [lastDoc, setLastDoc] = React.useState(null);
    const limit = 5;
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    useEffect(() => {
        retrieveNextLocalities()
        return () => {
            setLocalities([])
            setLastDoc(null)
            setFirstDoc(null)
        }
    }, [])

    const retrieveNextLocalities = async () => {
        await db.collection("localities")
            .orderBy("created_date", "desc")
            .startAfter(lastDoc === null ? "" : lastDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const retrievePreviousLocalities = async () => {
        await db.collection("localities")
            .orderBy("created_date", "asc")
            .startAfter(firstDoc)
            .limit(limit)
            .get()
            .then(snapshot => setDocs(snapshot.docs.reverse()))
            .catch(error => {
                console.log("Error getting documents: ", error);
            })
    }

    const setDocs = async (docs) => {
        if (docs.length === 0) {
            alert("No hay más localidades para revisar")
        }

        if (docs.length > 0) {
            setLastDoc(docs[docs.length - 1])
            setFirstDoc(docs[0])

            let snapshot_localities = []
            docs.forEach((doc) => {
                snapshot_localities.push({ doc_id: doc.id, doc_data: doc.data(), kind: doc.data().type })
            })
            setLocalities(snapshot_localities)
        }
    }

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Localidades
            </Typography>

            {
                localities.length > 0 ?
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ width: 190 }}>Imagen</StyledTableCell>
                                        <StyledTableCell>Información</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        localities.map((locality) => (
                                            <StyledTableRow key={locality.doc_id}>

                                                <StyledTableCell component="th" scope="row">
                                                    <img src={locality.doc_data.locality_image}
                                                        style={{
                                                            width: 190,
                                                            height: 190
                                                        }}
                                                    />
                                                </StyledTableCell>


                                                <StyledTableCell scope='row'>

                                                    <Typography variant='h4'>
                                                        {locality.doc_data.locality_name}
                                                    </Typography>

                                                    <Typography variant='body1'>
                                                        {locality.doc_data.id}
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Creada el: {locality.doc_data.created_date.toDate().getDate()}/{months[locality.doc_data.created_date.toDate().getMonth()]}/{locality.doc_data.created_date.toDate().getFullYear()}
                                                    </Typography>

                                                    <br />
                                                    <Typography variant='body2'>
                                                        Contacto:
                                                    </Typography>

                                                    <div style={styles.row}>
                                                        <PersonIcon style={styles.iconPrimary} />
                                                        <p style={styles.contact}>{locality.doc_data.contact.contractor}</p>
                                                    </div>

                                                    <div style={styles.row}>
                                                        <WorkIcon style={styles.iconPrimary} />
                                                        <p style={styles.contact}>{locality.doc_data.contact.contractor_position}</p>
                                                    </div>

                                                    <div style={styles.row}>
                                                        <EmailIcon style={styles.iconPrimary} />
                                                        <p style={styles.contact}>{locality.doc_data.contact.email}</p>
                                                    </div>

                                                    <div style={styles.row}>
                                                        <PhoneIcon style={styles.iconPrimary} />
                                                        <p style={styles.contact}>{locality.doc_data.contact.phone_number}</p>
                                                    </div>

                                                    <br />

                                                    <Typography variant='body2'>
                                                        Ubicación:
                                                    </Typography>

                                                    <div style={styles.row}>
                                                        <LocationOnIcon />
                                                        <p style={styles.contact}>{locality.doc_data.location.formattedAddress}</p>
                                                    </div>

                                                    <br />
                                                    <Typography variant='body2'>
                                                        Especificaciones:
                                                    </Typography>
                                                    <div style={styles.row}>
                                                        {
                                                            locality.doc_data.setup.has_addresses ?
                                                                <CheckIcon style={{ color: "green" }} />
                                                                :
                                                                <ClearIcon style={styles.badIcon} />
                                                        }

                                                        <p style={styles.contact}>{locality.doc_data.setup.has_addresses ? "Direcciones creadas" : "Direcciones NO creadas"}</p>
                                                    </div>

                                                    <div style={styles.row}>
                                                        <HomeWorkIcon style={styles.iconPrimary} />
                                                        <p style={styles.contact}>{locality.doc_data.subscription.kind}</p>
                                                    </div>

                                                    {
                                                        locality.doc_data.subscription.type === "1" &&
                                                        <div style={styles.row}>
                                                            <SentimentVeryDissatisfiedIcon style={styles.badIcon} />
                                                            <p style={styles.contact}>Subscripción free</p>
                                                        </div>
                                                    }

                                                    {
                                                        locality.doc_data.subscription.type === "2" &&
                                                        <div style={styles.row}>
                                                            <StarIcon style={styles.iconPrimary} />
                                                            <p style={styles.planPlus}>Subscripción plus</p>
                                                        </div>
                                                    }


                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "right",
                                                            marginRight: 10
                                                        }}
                                                    >
                                                        <Button
                                                            variant='contained'
                                                            color="secondary"
                                                        // onClick={}
                                                        >Más información</Button>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div
                            style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}
                        >
                            <Button
                                variant='contained'
                                color="primary"
                                onClick={retrievePreviousLocalities}
                            >Anterior</Button>

                            <Button
                                variant='contained'
                                color="primary"
                                onClick={retrieveNextLocalities}
                            >Siguiente</Button>
                        </div>
                    </div>
                    :
                    <div>
                        <Typography variant="subtitle1" gutterBottom>
                            Sin localidades por el momento
                        </Typography>
                    </div>
            }
        </div >
    );
}

export default AAllLocalities;

const styles = {
    contact: {
        marginBottom: 0,
        fontSize: 16,
        alignSelf: "center"
    },
    planPlus: {
        marginBottom: 0,
        fontSize: 16,
        alignSelf: "center",
        color: "green",
        fontWeight: "bold"
    },
    row: {
        flexDirection: "row",
        display: "flex",
        marginLeft: 5,
    },
    iconPrimary: {
        color: Constants.colors.primaryColor,
        marginRight: 5
    },
    badIcon: {
        color: Constants.colors.cancelColor,
        marginRight: 5
    }
}
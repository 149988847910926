import React from "react"
import { Helmet } from "react-helmet";
import HeroResidentialLanding from "./HeroResidentialLanding";
import Targets from "./Targets";
import {
    Element,
} from "react-scroll";
import ContactNow from "./ContactNow";
import Prices from "../Pricing/Prices";
const ResidentialLanding = () => {
    return (
        <>
            <Helmet>
                <title>Control de accesos, seguridad residencial</title>
                <meta name="description"
                    content="Control de accesos, seguridad revolucionada, comunicados y mucho más. Welcomi es la mejor opción para tu zona residencial, pruebala ahora, es gratis!!" />
                {/* <!-- Set the base URL for all relative URLs within the document --> */}
                {/* <base href="https://welcomi.app/"></base> */}
            </Helmet>


            <HeroResidentialLanding />

            <Element name="Targets" className="element">
                <Targets />
            </Element>

            <Element name="Prices" className="element">
                <Prices no_inner={true} />
            </Element>

            <ContactNow />
        </>
    )
}

export default ResidentialLanding;
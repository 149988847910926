import { Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { updateData } from '../../../../cases/Firecases/updateData';
import { functions } from '../../../../Fire';
import Constants from '../../../../utils/Constants';
import AReviewImgModal from './AReviewImgModal';

const AReviewPPContainer = ({
    user
}) => {
    const {
        profile_image
    } = user.doc_data.account_info

    const [showPPModal, setShowPPModal] = useState(false);
    const [status, setStatus] = useState(user.doc_data.account_info.profile_image_verified);
    const [remembered, setRemembered] = useState(false);

    const reject = async () => {

        if (window.confirm("¿Estas seguro de que deseas rechazar la imagen de perfil?")) {
            const data = {
                "account_info.profile_image_verified": "rejected",
                "account_info.profile_image_verified_date": new Date()

            }
            const response = await updateData("users", user.doc_id, data)

            const done = response[0]
            const error = response[1]

            if (done) {
                setStatus("rejected");
            }

            if (error) {
                console.log(error)
                alert("Error al rechazar la imagen de perfil")
            }
        }
    }

    const accept = async () => {
        if (window.confirm("¿Estas seguro de que deseas aceptar la imagen de perfil?")) {
            const data = {
                "account_info.profile_image_verified": "accepted",
                "account_info.profile_image_verified_date": new Date()

            }
            const response = await updateData("users", user.doc_id, data)

            const done = response[0]
            const error = response[1]

            if (done) {
                setStatus("accepted");
            }

            if (error) {
                console.log(error)
                alert("Error al aceptar la imagen de perfil")
            }
        }
    }

    const remember = async () => {
        functions.httpsCallable("w_pushNotificationByUserUID")({
            user_uid: user.doc_id,
            heading_en: "Do not forget to upload your profile picture",
            heading_es: "No olvides subir tu foto de perfil",
            deeplink: "welcomi://app/Stack_NLProfile",
            data_en: `Remember that your profile picture will grant you access to residentials.`,
            data_es: `Recuerda que tu foto de perfil te permitirá acceder a los residenciales que utilicen Welcomi.`,
        })
            .then(() => setRemembered(true))
            .catch(error => {
                console.log(error)
            })

        alert("Notificación enviada")
    }

    return (
        <div>
            <img
                src={profile_image}
                alt="Welcomi"
                width={200}
                height={200}
                onClick={() => {
                    setShowPPModal(true)
                }}
            />

            {
                showPPModal &&
                <AReviewImgModal
                    img={profile_image}
                    cbClose={() => setShowPPModal(false)}
                />
            }

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}

            >
                {
                    status === "0" &&
                    <Typography variant='caption'>
                        Status: <span style={{ color: "blue", fontWeight: "bold" }}>Pendiente</span>
                    </Typography>
                }

                {
                    status === "rejected" &&
                    <Typography variant='caption'>
                        Status: <span style={{ color: "red", fontWeight: "bold" }}>Rechazada</span>
                    </Typography>
                }

                {
                    status === "accepted" &&
                    <Typography variant='caption'>
                        Status: <span style={{ color: "green", fontWeight: "bold" }}>Aceptada</span>
                    </Typography>
                }

                {
                    status !== "rejected" &&
                    <Button
                        variant="contained"
                        onClick={reject}
                        style={{
                            backgroundColor: Constants.colors.cancelColor,
                            color: "white",
                            marginTop: 10,
                            marginBottom: 10
                        }}
                    >
                        Rechazar
                    </Button>
                }

                {
                    status !== "accepted" &&
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={accept}
                    >
                        Aceptar
                    </Button>
                }

                {
                    (!profile_image && !remembered) &&
                    <Button
                        variant="contained"
                        onClick={remember}
                        style={{
                            marginTop: 10,
                            marginBottom: 10
                        }}
                    >
                        Recordar subir foto
                    </Button>
                }
            </div>
        </div>
    )
}

export default AReviewPPContainer;
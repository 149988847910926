import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { useHistory } from 'react-router-dom';


function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const AUsers = ({
    params,
}) => {
    const history = useHistory();

    return (
        <div>
            <p>Menu de usuarios</p>

            <List component="nav" aria-label="main">
                <ListItemLink
                    onClick={() => history.push("/users/review")}
                >
                    <ListItemIcon>
                        <RecentActorsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Revisar imagenes de perfil e identificación" />
                </ListItemLink>
            </List>
        </div>
    )
};

export default AUsers;

import React from "react"

const GuiaDiferenciaResidenteParticular = () => {
  return (
    <div>
      <h2>Diferencia entre..</h2>
    </div>
  )
}
export default GuiaDiferenciaResidenteParticular

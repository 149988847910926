import { Avatar, Button, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../Context/UserContext';
import classNames from "classnames";
import Constants from '../../../utils/Constants';
import { validateImage } from '../../../cases/Generalcases/validateImage';
import Fire, { auth, storage } from '../../../Fire';
import { uploadImage } from '../../../cases/uploads/uploadImage';
import { updateData } from '../../../cases/Firecases/updateData';

function Account({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) {
    const outerClasses = classNames(
        "section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const { isLoggedIn, getUserDocument, logout, userDocument, user } = useContext(UserContext);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (isLoggedIn && !userDocument) {
            getUserDocument()
        }
    }, [])

    useEffect(() => {
        if (userDocument) {
            setLoading(false)
        }
    }, [userDocument])

    const upload = async (e, kind) => {
        setLoading(true)

        const isValid = validateImage(e.target)
        if (isValid) {


            let datePicture = new Date();
            //Hour_minutes_seconds-day-month-year
            let hour = String(datePicture.getHours());
            let min = String(datePicture.getMinutes());
            let sec = String(datePicture.getSeconds());
            let dd = String(datePicture.getDate()).padStart(2, "0");
            let mm = String(datePicture.getMonth() + 1).padStart(2, "0");
            let yyyy = datePicture.getFullYear();

            let name, resized_name;

            if (kind === 'profile_image') {
                name = storage.ref(`Users/PP/${user.uid}/${"PP-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec}`)
                resized_name = storage.ref(`Users/PP/${user.uid}/${"PP-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec + "_500x500"}`)
            }

            if (kind === "ID_image") {
                name = storage.ref(`Users/IDs/${user.uid}/${"ID-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec}`)
                resized_name = storage.ref(`Users/IDs/${user.uid}/${"ID-" + yyyy + "-" + mm + "-" + dd + "-" + hour + "_" + min + "_" + sec + "_500x500"}`)
            }

            let response = await uploadImage(e.target.files[0], name, resized_name)

            let link = response[0]
            let error = response[1]

            if (link) {
                if (kind === 'profile_image') {
                    let data = {
                        "account_info.profile_image": link,
                        "account_info.profile_image_verified": "0"
                    };

                    auth.currentUser.updateProfile({
                        photoURL: link,
                    })
                    let responseUpdateUserDocument = await updateData("users", user.uid, data)

                    let errorUpdate = responseUpdateUserDocument[1]
                    if (errorUpdate) {
                        error = true;   // Para que borre las imagenes del servidor
                    }
                }

                if (kind === "ID_image") {
                    let data = {
                        "account_info.ID_image": link,
                        'account_info.ID_image_verified': "0"
                    }

                    let responseUpdateUserDocument = await updateData("users", user.uid, data)

                    let errorUpdate = responseUpdateUserDocument[1]
                    if (errorUpdate) {
                        error = true;   // Para que borre las imagenes del servidor
                    }
                }
            }

            if (error) {
                try {
                    await Fire.deleteImages(name).catch(error => {
                        // console.log(error)
                    })
                } catch { }
                try {
                    await Fire.deleteImages(resized_name).catch(error => {
                        // console.log(error)
                    })
                } catch { }
            }

            await getUserDocument()
            setLoading(false)
        }

        if (!isValid) {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <div>
                <h1>Cargando...</h1>
            </div>
        )
    }

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className='switchRowColumn'>
                    <Avatar
                        alt="Welcomi user"
                        src={userDocument.doc_data.account_info.profile_image}
                        style={{
                            width: "200px",
                            height: "200px",
                            alignSelf: "center"
                        }}
                    />

                    <div className='switchMarginRowColumn'>
                        <Typography variant='h5' style={{ color: Constants.colors.primaryColor }}>
                            {userDocument.doc_data.personal_info.first_name} {userDocument.doc_data.personal_info.last_name}
                        </Typography>

                        <Typography variant='body1'>
                            {userDocument.doc_data.account_info.email}
                        </Typography>
                        <Typography variant='body1'>
                            {userDocument.doc_data.personal_info.gender}
                        </Typography>

                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            multiple={false}
                            type="file"
                            onChange={(e) => upload(e, "profile_image")}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span"
                                style={styles.submitButton}
                            >
                                Cambiar foto de perfil
                            </Button>
                        </label>

                    </div>
                </div>

                <div style={{ marginTop: "2%" }}>
                    <Typography>Identificación:</Typography>
                    <Typography style={{ color: "gray" }}>Tu identificación es obligatoria para acceder a las zonas residenciales que cuenten con Welcomi</Typography>
                    <Typography style={{ color: "gray" }}>Nunca más vuelvas a perder tu identificación!</Typography>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    {
                        userDocument.doc_data.account_info.ID_image ?
                            <div>
                                <img
                                    src={userDocument.doc_data.account_info.ID_image}
                                    style={{ width: 400, height: 600 }}
                                    alt="Welcomi user identification"
                                />

                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="contained-change-id-image-button-file"
                                    multiple={false}
                                    type="file"
                                    onChange={(e) => upload(e, "ID_image")}
                                />
                                <label htmlFor="contained-change-id-image-button-file">
                                    <Button variant="contained" component="span"
                                        style={styles.submitButton}
                                    >
                                        Cambiar identificación
                                    </Button>
                                </label>

                            </div>
                            :
                            <div>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="contained-id-image-button-file"
                                    multiple={false}
                                    type="file"
                                    onChange={(e) => upload(e, "ID_image")}
                                />
                                <label htmlFor="contained-id-image-button-file">
                                    <Button variant="contained" component="span"
                                        style={styles.submitButton}
                                    >
                                        Subir identificación!!
                                    </Button>
                                </label>
                            </div>
                    }
                </div>

                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                    <Button
                        onClick={logout}
                        style={styles.logout}
                    >
                        Cerrar Sesión
                    </Button>
                </div>
            </div>
        </section>
    );
}

export default Account;

const styles = {
    personalContainer: {
        display: "flex",
        flexDirection: "row",
    },
    submitButton: {
        backgroundColor: "#ffa31a",
        color: "white",
        fontWeight: "bold",
    },
    logout: {
        marginTop: "2%",
        backgroundColor: Constants.colors.secondaryColor,
        color: "white",
        fontWeight: "bold"
    }
}
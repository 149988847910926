import React from "react"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import Constants from "../../../utils/Constants"
import Captain_rounded from "../../../assets/svgs/captain_rounded.svg"
import "./TimeLine_R.css"
import appstore from "../../../assets/svgs/available-on-the-app-store.svg"
import playstore from "../../../assets/svgs/get-it-on-google-play.svg"
import HomeIcon from '@material-ui/icons/Home'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import DescriptionIcon from '@material-ui/icons/Description'
import Guard_rounded from "../../../assets/svgs/guard_rounded.svg"
import Welcomi_rounded from "../../../assets/images/welcomi_rounded.png"
import LifeStyle from "../../../assets/svgs/LifeStyle.svg"
import CheckIcon from '@material-ui/icons/Check'
import GetAppIcon from '@material-ui/icons/GetApp'
import "./Hero_R.css"

const TimeLineR = () => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2 >¿Como comenzar a usar <span className="text-color-primary">Welcomi</span> en tu residencial?</h2>
      </div>
      <VerticalTimeline lineColor={Constants.colors.secondaryColor}>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: Constants.colors.primaryColor, color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(255, 163, 26)' }}
          iconStyle={{ background: Constants.colors.primaryColor, color: 'black' }}
          icon={<GetAppIcon style={{ color: "white" }} fontSize="large" />}
        >
          <h3 className="vertical-timeline-element-title titleColor">Descarga Welcomi Captain</h3>
          {/* <p className="vertical-timeline-element-subtitle subtitleSpacer">La encuentras en la Apple Store o Play Store</p> */}
          <div style={{ marginTop: "2%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "25%" }}>
              <img src={Captain_rounded} alt="Welcomi Captain" />
            </div>
          </div>
          <div className="download_row">
            <div className="playstorelogo">
              <div className="download_col">
                <a href={Constants.download_links.android_captain} target="_blank" rel="noopener noreferrer">
                  <img src={playstore} alt="tienda de google" />
                </a>
              </div>
            </div>
            <div className="appstorelogo">
              <div className="download_col">
                <a href={Constants.download_links.ios_captain} target="_blank" rel="noopener noreferrer">
                  <img src={appstore} alt="Welcomi apple" />
                </a>
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: Constants.colors.primaryColor, color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(255, 163, 26)' }}
          iconStyle={{ background: Constants.colors.primaryColor, color: 'black' }}
          icon={<DescriptionIcon style={{ color: "white" }} fontSize="large" />}
        >
          <h3 className="vertical-timeline-element-title titleColor">Crea tu cuenta y registra tu localidad.</h3>
          <p className="">Rellena los campos solicitados con la información correspondiente.</p>
          <HomeIcon fontSize="large" />
          <LocationOnIcon fontSize="large" />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: Constants.colors.primaryColor, color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(255, 163, 26)' }}
          iconStyle={{ background: Constants.colors.primaryColor, color: 'black' }}
          icon={<CheckIcon style={{ color: "green", fontWeight: "bold" }} />}
        >
          <h3 className="vertical-timeline-element-title titleColor">¡Listo! Ya puedes disfrutar de Welcomi en tu residencial.</h3>
          <p className="">Invita a residentes y guardias a formar parte de tu localidad y vivan una experiencia inigualable.</p>
          <div style={{ marginTop: "2%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
            <div style={{ width: "25%" }}>
              <img src={Welcomi_rounded} alt="Welcomi" />
            </div>
            <div style={{ width: "25%" }}>
              <img src={Guard_rounded} alt="Welcomi Guard" />
            </div>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: Constants.colors.secondaryColor, color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(13, 188, 211)' }}
          iconStyle={{ background: Constants.colors.secondaryColor, color: 'black' }}
        >
          <h3 className="vertical-timeline-element-title titleColor">Welcomi es para todos, no lo pienses más empieza ahora!!</h3>
          <img src={LifeStyle} alt="Welcomi lifestyle" style={{ width: "95%", stroke: "white" }} />
          <div style={{ marginTop: "3%", alignSelf: "flex-end", display: "flex", justifyContent: "flex-end" }}>
            <button
              className="startNowBttn"
              type="submit"
              onClick={(e) => {
                e.preventDefault()
                window.location.href = '/precios'
              }}
            >
              COMENZAR AHORA
            </button>
          </div>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  )
}

export default TimeLineR
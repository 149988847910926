import React from 'react';

import { Button, Input, TextField } from '@material-ui/core';
import { useState } from 'react';

const Invitations_DifferentCar = ({ cbGetDiffCar }) => {

    const [plate, setPlate] = useState("")
    const [brand, setBrand] = useState("")
    const [model, setModel] = useState("")
    const [year, setYear] = useState("")
    const [plate_state, setPlateState] = useState("")
    const [plate_country, setPlateCountry] = useState("")
    const [color, setColor] = useState("")

    return (
        <div>
            <div style={styles.inline}>
                <TextField
                    name="plate"
                    id="plate"
                    label="Placa *"
                    type="text"
                    onChange={(e) => setPlate(e.target.value)}
                    value={plate}
                    size='small'
                />
                <TextField
                    name="brand"
                    id="brand"
                    label="Marca"
                    type="text"
                    onChange={(e) => setBrand(e.target.value)}
                    value={brand}
                    size='small'
                />
            </div>

            <div style={styles.inline}>
                <TextField
                    name="model"
                    id="model"
                    label="Modelo"
                    type="text"
                    onChange={(e) => setModel(e.target.value)}
                    value={model}
                    size='small'
                />
                <TextField
                    name="year"
                    id="year"
                    label="Año"
                    type="number"
                    onChange={(e) => setYear(e.target.value)}
                    value={year}
                    size='small'
                />
            </div>

            <div style={styles.inline}>
                <TextField
                    name="state"
                    id="state"
                    label="Estado"
                    type="text"
                    onChange={(e) => setPlateState(e.target.value)}
                    value={plate_state}
                    size='small'
                />
                <TextField
                    name="country"
                    id="country"
                    label="País"
                    type="text"
                    onChange={(e) => setPlateCountry(e.target.value)}
                    value={plate_country}
                    size='small'
                />
            </div>

            <div style={styles.inline}>
                <TextField
                    name="color"
                    id="color"
                    label="Color"
                    type="text"
                    onChange={(e) => setColor(e.target.value)}
                    value={color}
                    size='small'
                />

                <Button
                    variant="contained"
                    disabled={!plate}
                    style={{
                        backgroundColor: !plate ? null : "green",
                        color: !plate ? "gray" : "white",
                    }}
                    onClick={() => {
                        cbGetDiffCar(
                            {
                                plate,
                                brand,
                                model,
                                year,
                                plate_state,
                                plate_country,
                                color
                            }
                        )
                    }}
                >
                    Agregar
                </Button>
            </div>
        </div>
    );
}

export default Invitations_DifferentCar;

const styles = {
    inline: {
        display: "flex",
        justifyContent: "space-between",
    }
}
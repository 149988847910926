import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import Constants from '../../../utils/Constants';
import "./MktMailBox_R.css"
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        width: 300,
    },


}));
const CssTextField = withStyles({
    root: {
        color: "white",
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: "black",
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

const MktMailBoxR = ({
    status, message, onValidated
}) => {
    const classes = useStyles();

    const { register, /*control,*/ errors, handleSubmit, reset/* , reset, watch */ } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
        },
    });

    const onSubmit = (data) => {
        data.email &&
            data.name &&
            data.phone &&
            data.email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: data.email,
                MERGE1: data.name,
                MERGE3: data.phone
            });

        alert("Éxito ✅. Recibiras todas las noticias al instante.")
        reset()
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate={true}
            style={{ backgroundColor: 'rgb(250, 141, 29)', marginTop: 0, paddingBottom: 15 }}
        >
            <div className="mkt_Container">
                <div>
                    <CssTextField
                        name="name"
                        label="Tu nombre"
                        type="text"
                        className={classes.margin}
                        InputLabelProps={{ style: { color: "white" } }}
                        variant="outlined"
                        id="custom-css-outlined-input"
                        size="large"
                        inputRef={register({
                            required: {
                                value: true,
                                message: "Introduzca su nombre y apellidos",
                            },
                        })}
                    />
                    {errors.name && (
                        <span className="errorMessage">
                            {errors.name.message}
                        </span>
                    )}
                </div>

                <div>
                    <CssTextField
                        name="email"
                        label="Email"
                        type="email"
                        className={classes.margin}
                        InputLabelProps={{ style: { color: "white" } }}
                        variant="outlined"
                        id="custom-css-outlined-input"
                        size="large"
                        inputRef={register({
                            required: {
                                value: true,
                                pattern:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Por favor introduzca su direccion email",
                            },
                            maxLength: {
                                value: 320,
                                message: "Por favor use menos de 320 caracteres",
                            },
                        })}
                    />
                    {errors.email && (
                        <span className="errorMessage">
                            {errors.email.message}
                        </span>
                    )}
                </div>

                <div>
                    <CssTextField
                        name="phone"
                        label="Celular"
                        type="phone"
                        className={classes.margin}
                        InputLabelProps={{ style: { color: "white" } }}
                        variant="outlined"
                        id="custom-css-outlined-input"
                        size="large"
                        inputRef={register({
                            required: {
                                value: true,
                                pattern:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Por favor introduzca su celular",
                            },
                            maxLength: {
                                value: 15,
                                message: "Por favor use menos de 15 caracteres",
                            },
                        })}
                    />
                    {/* {errors.phone && (
                        <span className="errorMessage">
                            {errors.phone.message}
                        </span>
                    )} */}
                </div>

                <div className="mkt_SendButton">
                    <Button
                        style={{
                            backgroundColor: Constants.colors.primaryColor,
                            width: "auto",
                            color: "white",
                            fontWeight: "bold",
                            marginTop: "2%",
                            marginBottom: "2%",
                        }}
                        type="submit"
                    >
                        Enviar
                    </Button>
                </div>
            </div>
        </form>
    )
};

export default MktMailBoxR;

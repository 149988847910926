import React from 'react';

const GetProfile = () => {
    return (
        <div>
            <p>Profile</p>
        </div>
    );
}

export default GetProfile;
import React from "react"

import Guia_perfil from "../../../assets/svgs/Guia_perfil.svg"
import Guia_ChangeID from "../../../assets/svgs/Guia_ChangeID.svg"

const GuiaSubirIdentificacion = () => {
  return (
    <div>
      <h2>¿Cómo subir mi ID? </h2>
      <ul>
        <li>Dirígete hacia la pestaña “Perfil”.</li>
      </ul>
      <view style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={Guia_perfil}
          style={{ width: "25%", height: "25%" }}
          alt={"Guia Welcomi"}
        />
      </view>
      <ul>
        <li>Da click en Mí Identificación.</li>
        <li>En la siguiente pantalla podrás subir una foto de tú ID dando click al botón “Subir Identificación”, esta debe ser legible y clara.</li>
      </ul>
      <view style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={Guia_ChangeID}
          style={{ width: "25%", height: "25%" }}
          alt='Guía Subir Identificación'
        />
      </view>
      <ul>
        <li>En esta misma pantalla podrás cambiar la foto de tú ID si es que la tienes que actualizar o subir una foto más legible.</li>
        <li><span role='img' aria-label='check'>✅</span> Listo, ya tienes registra tu identificación para poder acceder a las localidades que requieren este tipo de autenticación..</li>
      </ul>
    </div>
  )
}
export default GuiaSubirIdentificacion

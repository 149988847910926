import React, { useEffect } from 'react';

import { Card, CardContent, Typography } from '@material-ui/core';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import "./Pricing.css"
import AppleLogo from "../../../assets/images/appstore.png"
import AndroidLogo from "../../../assets/images/playstorelog.png"

import Constants from "../../../utils/Constants"
import IOSTrack from '../../../cases/GoogleAnalytics.js/IOSTrack';
import AndroidTrack from '../../../cases/GoogleAnalytics.js/AndroidTrack';


const PricingCard1 = (props, {
    params,
}) => {
    useEffect(() => {
        // console.log(props.offer)

    }, [])

    const listFeatures = () => (
        props.offer.map((feature, key) => (
            <>
                <div style={{ flexDirection: "row", display: "flex" }} key={key}>
                    {
                        feature.available ?
                            <CheckCircleIcon
                                titleAccess="Check"
                                fontSize="small"
                                style={{ color: "green", alignSelf: "center" }}
                                className="check"
                            />
                            :
                            <CancelIcon
                                titleAccess="Cross"
                                fontSize="small"
                                style={{ color: "red" }}
                                className="cross"
                            />
                    }

                    <Typography style={{ fontSize: 16, marginTop: 10, marginBottom: 10, marginLeft: 10, fontWeight: feature.bold ? "bold" : "normal" }}>{feature.feature}</Typography>
                </div>

            </>

        ))
    )

    const handleTrack = (trackEvent) => {
        if (trackEvent === "iosDownloadTrack") {
            IOSTrack()
        }

        if (trackEvent === "AndroidDownloadTrack") {
            AndroidTrack()
        }
    }

    return (
        <Card elevation={5}>
            <CardContent>

                <Typography variant="h5" component="div" className="text-color-primary">
                    {props.title}
                </Typography>

                <Typography
                    style={{ fontSize: 18, color: "gray", marginTop: 10, marginBottom: props.downloadable ? 15 : 0, marginLeft: 2 }}
                    gutterBottom
                >
                    {props.description}
                </Typography>

                {listFeatures()}


                <Typography style={{ color: "gray", fontSize: 16 }}>{props.licensesText}</Typography>
            </CardContent>


            <Typography style={{ textAlign: "center", color: props.downloadable ? "green" : "black", fontWeight: "bold", fontSize: 24 }}>{props.price}</Typography>

            <div className='storesDiv'>
                <div className="storesLogoDiv">
                    <a
                        href={Constants.download_links.android_captain}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={AndroidLogo} className='storesLogo'
                            onClick={() => handleTrack("AndroidDownloadTrack")}
                        />
                    </a>
                </div>

                <div className="storesLogoDiv">
                    <a
                        href={Constants.download_links.ios_captain}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={AppleLogo} className='storesLogo' onClick={() => handleTrack("iosDownloadTrack")} />
                    </a>
                </div>
            </div>
            <a href="/precios_detalles">
                <Typography style={{ textAlign: "center", marginTop: 25, color: "gray" }}>Más información</Typography>
            </a>
        </Card>
    )
};

export default PricingCard1;

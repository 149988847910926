import React, { useState } from "react";

export const DemoContext = React.createContext();

// Laura pp: https://img.freepik.com/foto-gratis/retrato-hermoso-mujer-joven-posicion-pared-gris_231208-10760.jpg?w=2000
export function DemoProvider(props) {
  const [captain, setCaptain] = useState({
    name: "Laura Gonzalez",
    profile_image: "https://img.freepik.com/foto-gratis/retrato-hermoso-mujer-joven-posicion-pared-gris_231208-10760.jpg?w=2000",
    email: "laura@gmail.com",
    user_uid: "l1a2u3r4a5",
    user_range: "Captain",
  });

  const [posts, setPosts] = useState([
    {
      created_at: new Date(),
      date: new Date(),
      hide: false,
      likes: 23,
      message: "Esta semana le estarán dando mantenimiento a los elevadores, favor de usar las escaleras.",
      photo_URL: "https://cdn.vertika.com.mx/wp-content/uploads/2022/02/18174752/sized-01.jpg",
      posted_by: "Laura Gonzales",
      profile_image: "https://img.freepik.com/foto-gratis/retrato-hermoso-mujer-joven-posicion-pared-gris_231208-10760.jpg?w=2000",
      type: "post",
      updated_at: null,
      user_email: "laura@gmail.com",
      user_range: "Captain",
      user_uid: "123asd",
    },
    {
      answers: [
        {
          choice: "Pintar canchas",
          id: 0,
          votes: 3,
        },
        {
          choice: "Hacer barda lateral",
          id: 1,
          votes: 5,
        },
      ],
      created_at: new Date(),
      date: new Date(),
      hide: false,
      poll_description: "Ponemos a votación que opción elegir ya que solo tenemos presupuesto para una de las dos.",
      poll_image: "",
      poll_question: "¿En que prefieren que se utilice el presupuesto de este mes?",
      posted_by: "Laura Gonzales",
      type_poll: "multiple",
      profile_image: "https://img.freepik.com/foto-gratis/retrato-hermoso-mujer-joven-posicion-pared-gris_231208-10760.jpg?w=2000",
      type: "poll",
      updated_at: null,
      user_email: "laura@gmail.com",
      user_range: "Captain",
      user_uid: "123asd",
    },
    {
      created_at: new Date(),
      date: new Date(),
      hide: false,
      likes: 13,
      message: "Queremos compartir con todos nuestros residentes que hemos empezado a utilizar Welcomi para ordenar nuestras finanzas.",
      photo_URL: "https://concepto.de/wp-content/uploads/2012/03/finanzas-e1552605926805.jpg",
      posted_by: "Laura Gonzales",
      profile_image: "https://img.freepik.com/foto-gratis/retrato-hermoso-mujer-joven-posicion-pared-gris_231208-10760.jpg?w=2000",
      type: "post",
      updated_at: null,
      user_email: "laura@gmail.com",
      user_range: "Captain",
      user_uid: "123asd",
    },
    {
      created_at: new Date(),
      date: new Date(),
      hide: false,
      likes: 15,
      message: "Hola, el lunes se realizará el mantenimiento a los asadores de la terraza, por lo que su uso queda suspendido por ese día.",
      photo_URL: "https://www.asadoresmonterrey.com/images/VAQUERO_1.png",
      posted_by: "Roberto Juarez",
      profile_image: "https://www.labsaenzrenauld.com/wp-content/uploads/2020/10/Perfil-hombre-ba%CC%81sico_738242395.jpg",
      type: "post",
      updated_at: null,
      user_email: "roberto@gmail.com",
      user_range: "Captain",
      user_uid: "1234asd",
    },
    {
      created_at: new Date(),
      date: new Date(),
      hide: false,
      likes: 10,
      message: "Feliz Día de las madres a todas las madres de nuestra comunidad, que tengan un excelente día.",
      photo_URL: "https://i0.wp.com/alcaldiacuauhtemoc.mx/wp-content/uploads/2019/05/Dia-de-las-madres-destacada.jpg?fit=528%2C453&ssl=1",
      posted_by: "Roberto Juarez",
      profile_image: "https://www.labsaenzrenauld.com/wp-content/uploads/2020/10/Perfil-hombre-ba%CC%81sico_738242395.jpg",
      type: "post",
      updated_at: null,
      user_email: "roberto@gmail.com",
      user_range: "Captain",
      user_uid: "1234asd",
    },
    {
      created_at: new Date(),
      date: new Date(),
      hide: false,
      likes: 23,
      message: "Esta semana es la última para realizar el pago del seguro anual, favor de realizarlo para evitar penalizaciones.",
      photo_URL: "https://revista.condusef.gob.mx/wp-content/uploads/2016/10/images_psd_199_cargar.jpg",
      posted_by: "Laura Gonzales",
      profile_image: "https://img.freepik.com/foto-gratis/retrato-hermoso-mujer-joven-posicion-pared-gris_231208-10760.jpg?w=2000",
      type: "post",
      updated_at: null,
      user_email: "laura@gmail.com",
      user_range: "Captain",
      user_uid: "123asd",
    },
    {
      created_at: new Date(),
      date: new Date(),
      hide: false,
      likes: 10,
      message: "Estaremos renovando las tarjetas de acceso a las amenidades, favor de pasar a recepción a realizar el cambio.",
      photo_URL: "https://solucioneseurosoft.com/wp-content/uploads/2022/05/tarjetas-de-control-de-acceso.jpeg",
      posted_by: "Roberto Juarez",
      profile_image: "https://www.labsaenzrenauld.com/wp-content/uploads/2020/10/Perfil-hombre-ba%CC%81sico_738242395.jpg",
      type: "post",
      updated_at: null,
      user_email: "roberto@gmail.com",
      user_range: "Captain",
      user_uid: "1234asd",
    },

    {
      answers: [
        {
          choice: "Invertir",
          id: 0,
          votes: 3,
        },
        {
          choice: "No Gastar",
          id: 1,
          votes: 5,
        },
      ],
      created_at: new Date(),
      date: new Date(),
      hide: false,
      poll_description: "Descripción de la encuesta",
      poll_image: "",
      poll_question: "Votación para decidir si invertir en nuevas cámaras de seguridad o no",
      posted_by: "Roberto Juarez",
      type_poll: "multiple",
      profile_image: "https://www.labsaenzrenauld.com/wp-content/uploads/2020/10/Perfil-hombre-ba%CC%81sico_738242395.jpg",
      type: "poll",
      updated_at: null,
      user_email: "roberto@gmail.com",
      user_range: "Captain",
      user_uid: "1234asd",
    },
  ]);
  const [residents, setResidents] = useState([
    {
      demo_locality_id: {
        apartment_number: "1",
        apartmennt_reference: "Torre A",
        phone_number: "+52833123123",
      },
      account_info: {
        ID_image: "", // PONER IMAGEN DE LA IDENTIFICACION
        created_date: new Date(),
        email: "luisquii@exmail.com",
        profile_image: "", // PONER IMAGEN DE PERFIL
      },
      locality_info: [
        {
          accepted: true,
          locality_ID: "demo_locality_id",
          locality_name: "Torre de piedra",
        },
      ],
    },
  ]);
  const [guards, setGuards] = useState([]);

  const addPost = (post) => {
    setPosts([post, ...posts]);
  };

  return (
    <DemoContext.Provider
      value={{
        captain,
        posts,
        residents,
        guards,
        addPost,
      }}
    >
      {props.children}
    </DemoContext.Provider>
  );
}


import React, { useRef, useEffect, /* useContext */ } from "react"
import { useLocation, Switch } from "react-router-dom"
import AppRoute from "./utils/AppRoute"
import ScrollReveal from "./utils/ScrollReveal"
import ReactGA from "react-ga"
import ReactPixel from "react-facebook-pixel"
import TagManager from 'react-gtm-module'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { createMuiTheme, ThemeProvider, /* responsiveFontSizes */ } from "@material-ui/core"
import LayoutDefault from "./layouts/LayoutDefault"

import { UserProvider } from "./Context/UserContext"
import { GuardProvider } from "./Context/GuardContext"
import GuardNav from "./Navigators/GuardNav"
import Constants from "./utils/Constants"
import { AdminProvider } from "./Context/AdminContext"
import AdminNav from "./Navigators/AdminNav"
import SelectLogin from "./components/Residencial/Logged/SelectLogin"
import { CaptainProvider } from "./Context/CaptainContext"
import CaptainNav from "./Navigators/CaptainNav"
import UserNav from "./Navigators/UserNav"
import { DemoProvider } from "./Context/DemoContext"
import DemoNav from "./Navigators/DemoNav"
import ResidentialNav from "./Navigators/ResidentialNav"
import OpenChatTrack from "./cases/GoogleAnalytics.js/OpenChatTrack"

const trackPage = (page) => {
  ReactGA.set({ page })
  ReactGA.pageview(page)
}

const App = () => {
  const childRef = useRef()
  let location = useLocation()

  useEffect(() => {
    var host = window.location.hostname
    if (host === "localhost" || host === "grdacc.localhost") {
      // Not staring pixel, analytics or tag manager
    }
    else {
      // Initialize Google Analytics
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS)
      TagManager.initialize({ gtmId: process.env.REACT_APP_TAG_MANAGER })
      const advancedMatching = { em: "some@email.com" } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      }

      ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL, advancedMatching, options)

      ReactPixel.pageView() // For tracking page view
      // ReactPixel.track(event, data) // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
      // ReactPixel.trackSingle(process.env.REACT_APP_FACEBOOK_PIXEL, event, data) // For tracking default events.
      // ReactPixel.trackCustom(event, data) // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
      // ReactPixel.trackSingleCustom(process.env.REACT_APP_FACEBOOK_PIXEL, event, data) // For tracking custom events.
    }

  }, [])

  useEffect(() => {
    document.body.classList.add("is-loaded")
    childRef.current.init()
    const page = location.pathname + location.search

    var host = window.location.hostname

    if (host === "localhost" || host === "grdacc.localhost" || host === "user.localhost" || host === "captain.localhost" || host === "roluis.localhost" || host === "demo.localhost") {
      // do nothing
    }
    else {
      trackPage(page)
      // console.log("PAGE: ", page)
    }
  }, [location])


  let theme = createMuiTheme({
    palette: {
      primary: {
        main: Constants.colors.primaryColor,
      },
      secondary: {
        main: Constants.colors.secondaryColor,
      },
      error: {
        main: Constants.colors.cancelColor,
      },
    },
    typography: {
      h4: {
        fontSize: '1.2rem',
        '@media (min-width:600px)': {
          fontSize: '1.5rem',
        },
      },
      h6: {
        fontSize: '1.2rem',
        '@media (min-width:600px)': {
          fontSize: '1.5rem',
        },
      },
      subtitle1: {
        fontSize: '0.6rem',
        '@media (min-width:600px)': {
          fontSize: '0.9rem',
        },
      }
    }
  })

  if (window.location.host.split(".")[0] === "demo") {
    return (
      <ScrollReveal
        ref={childRef}
        children={() => (
          <DemoProvider>
            <ThemeProvider theme={theme}>
              <Switch>
                <DemoNav />
              </Switch>
            </ThemeProvider>
          </DemoProvider>
        )}
      />

    )
  }

  if (window.location.host.split(".")[0] === "captain") {
    return (
      <ScrollReveal
        ref={childRef}
        children={() => (
          <CaptainProvider>
            <ThemeProvider theme={theme}>
              <Switch>
                <CaptainNav />
              </Switch>
            </ThemeProvider>
          </CaptainProvider>
        )}
      />

    )
  }

  if (window.location.host.split(".")[0] === "user") {
    return (
      <ScrollReveal
        ref={childRef}
        children={() => (
          <UserProvider>
            <ThemeProvider theme={theme}>
              <Switch>
                <UserNav />
              </Switch>
            </ThemeProvider>
          </UserProvider>
        )}
      />

    )
  }

  if (window.location.host.split(".")[0] === "grdacc") {
    return (
      <ScrollReveal
        ref={childRef}
        children={() => (
          <GuardProvider>
            <ThemeProvider theme={theme}>
              <Switch>
                <GuardNav />
              </Switch>
            </ThemeProvider>
          </GuardProvider>
        )}
      />

    )
  }

  if (window.location.host.split(".")[0] === "roluis") {
    return (
      <ScrollReveal
        ref={childRef}
        children={() => (
          <AdminProvider>
            <ThemeProvider theme={theme}>
              <Switch>
                <AdminNav />
              </Switch>
            </ThemeProvider>
          </AdminProvider>
        )}
      />
    )
  }

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          {
            window.location.hostname === "localhost" ?
              <ResidentialNav />
              :
              <>
                <MessengerCustomerChat
                  pageId="342707920364422"
                  appId="614594212998202"
                  // htmlRef="<REF_STRING>"
                  themeColor="#000000"//#ffa31a
                  onCustomerChatDialogShow={OpenChatTrack}
                />
                <ResidentialNav />
              </>
          }
        </Switch>
      )}
    />
  )
}

export default App
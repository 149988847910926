import Fire from "../../Fire";

export async function updateData(colection, document, data) {

    var succeed = null;
    var thrownError = null
    await Fire.updateData(colection, document, data)
        .then(() => {
            succeed = true
            thrownError = false
        })
        .catch((error) => {
            succeed = false
            thrownError = error
        })

    return [succeed, thrownError]
}
import React from 'react'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'

export const GuiasCaptain = () => {
  const location = useLocation()
  const titleArr = location.pathname.split('/guias/')[1].split('_')
  const title = titleArr.join(' ').toLocaleUpperCase()

  const video =
    location.pathname === '/guias/registrar_areas_comunes' ? 'https://www.youtube.com/shorts/-iOdJamkwVU'
  : location.pathname === '/guias/registrar_residentes' ? 'https://www.youtube.com/shorts/CB9L0dkuFOc'
  : location.pathname === '/guias/registrar_guardias' && 'https://www.youtube.com/shorts/lYv5uA75uC8'

  return (
    <div style={{ marginRight: "5%", marginLeft: "2%" }}>
      <h2>{title}</h2>
      <ReactPlayer
          url={video}
          style={{ marginInline: 'auto', marginBlock: 32, maxWidth: '100%' }}
          progressInterval={true}
          className="react-player"
      />
    </div>
  )
}

import "./Hero_R.css"
import React from "react"
// import classNames from "classnames"
import { SectionProps } from "../../../utils/SectionProps"
import AndroidIcon from '@material-ui/icons/Android'
import AppleIcon from '@material-ui/icons/Apple'
import Constants from "../../../utils/Constants"
import IOSTrack from "../../../cases/GoogleAnalytics.js/IOSTrack"
import AndroidTrack from "../../../cases/GoogleAnalytics.js/AndroidTrack"
import WelcomiLogo from "../../../assets/images/welcomi.png"

const propTypes = {
  ...SectionProps.types,
}

const defaultProps = {
  ...SectionProps.defaults,
}

const HeroR = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const handleTrack = (trackEvent) => {
    if (trackEvent === "iosDownloadTrack") {
      IOSTrack()
    }

    if (trackEvent === "AndroidDownloadTrack") {
      AndroidTrack()
    }
  }

  return (
    <section {...props} className="outerClasses">
      <div className="fullHeightTopBanner banner_background">
        <div className="banner_row">
          <div className="banner_col">
            <img src={WelcomiLogo} className="banner_logo" alt='Welcomi Logo' />
          </div>

          <div className="banner_col">
            <div >
              <h2 className="banner_title spacer" style={{ textAlign: 'center' }}>Administración, seguridad, estilo de vida</h2>
              <h4 className="banner_subtitle spacer" style={{ textAlign: 'center' }}>Torre departamental,privada, fraccionamiento o mixto </h4>
              <p className="banner_text spacer" style={{ textAlign: 'center' }}>Administra los pagos de los residentes <span style={{ fontWeight: "bold" }}>|</span> Control de accesos resindencial a la medida <span style={{ fontWeight: "bold" }}>|</span> Empieza ahora</p>
              <div style={{ marginLeft: "3%", alignSelf: "flex-end", display: "flex", justifyContent: "center" }}>
                <button
                  className="viewMoreBttn"
                  onClick={(e) => {
                    e.preventDefault()
                    handleTrack("AndroidDownloadTrack")
                    window.location.href = Constants.download_links.android_captain
                  }}
                >
                  <AndroidIcon
                    fontSize="small"
                    style={{ color: "white" }}
                  />
                  Descarga Android
                </button>
                <button
                  className="startNowBttn"
                  onClick={(e) => {
                    e.preventDefault()
                    handleTrack("iosDownloadTrack")
                    window.location.href = Constants.download_links.ios_captain
                  }}
                >
                  <AppleIcon
                    fontSize="small"
                    style={{ color: "white" }}
                  />
                  Descarga Apple
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

HeroR.propTypes = propTypes
HeroR.defaultProps = defaultProps

export default HeroR
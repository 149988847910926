import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, List, Typography } from '@material-ui/core';
import Invitations_DifferentCar from './Invitations_DifferentCar';

const Invitations_SelectCar = ({
    cars,
    cbUserEnteredby,
    cbValidate
}) => {
    const [value, setValue] = React.useState(cars.length ? cars[0].plate : "TaxiService");
    const [showDiffCar, setShowDiffCar] = useState(false);
    const [diffCar, setDiffCar] = useState(null)

    const renderCars = () => {
        return cars.map((item, index) => (
            <FormControlLabel
                value={item.plate}
                control={<Radio />}
                label={`${item.brand} ${item.model} ${item.year} ${item.color} ${item.plate} ${item.plate_state} ${item.plate_country}`}
                key={index}
            />
        ))
    }

    const handleChange = (e) => {
        setDiffCar(null)
        setShowDiffCar(false)
        setValue(e.target.value)

        if (e.target.value === "ExtraCar" || e.target.value === "TaxiService") {
            setShowDiffCar(true)
        }
        cbValidate()
    }

    const handleValidate = () => {
        let data
        if (value === "ExtraCar") {
            data = {
                ...diffCar,
                type: "ExtraCar"
            }
        } else if (value === "TaxiService") {
            data = {
                ...diffCar,
                type: "TaxiService"
            }
        } else if (value === "Walking") {
            data = {
                type: "Walking"
            }
        } else {
            cars.forEach(car => {
                if (car.plate === value) {
                    data = {
                        brand: car.brand,
                        model: car.model,
                        year: car.year,
                        plate: car.plate,
                        plate_state: car.plate_state,
                        plate_country: car.plate_country,
                        color: car.color,
                        type: "OwnCar"
                    }
                }
            })
        }

        cbUserEnteredby(data)
    }

    const cbGetDiffCar = (newCar) => {
        setShowDiffCar(false)
        setDiffCar(newCar)
    }

    return (
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">Selecciona el método de acceso</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <List
                        component="div"
                        disablePadding
                    >
                        {renderCars()}
                    </List>
                    <FormControlLabel value="ExtraCar" control={<Radio />} label="Otro vehículo propio" />
                    {
                        (value === "ExtraCar" && showDiffCar) &&
                        <Invitations_DifferentCar
                            cbGetDiffCar={cbGetDiffCar}
                        />
                    }
                    {
                        (value === "ExtraCar" && diffCar) &&
                        <Typography>
                            {diffCar.brand} {diffCar.model} {diffCar.year} {diffCar.color} {diffCar.plate} {diffCar.plate_state} {diffCar.plate_country}
                        </Typography>
                    }
                    <FormControlLabel value="TaxiService" control={<Radio />} label="Servicio de transporte" />
                    {
                        (value === "TaxiService" && showDiffCar) &&
                        <Invitations_DifferentCar
                            cbGetDiffCar={cbGetDiffCar}
                        />
                    }

                    <FormControlLabel value="Walking" control={<Radio />} label="Caminando" />
                </RadioGroup>
            </FormControl>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={handleValidate}
                    style={{
                        color: "white",
                        backgroundColor: "green"
                    }}
                >
                    Validar
                </Button>
            </div>

        </div>
    );
}

export default Invitations_SelectCar;
import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import "../Residencial/AdStyles/General.css"
import Constants from '../../utils/Constants';
import { UserContext } from '../../Context/UserContext';
const propTypes = {
    children: PropTypes.node,
    ...SectionProps.types,
};

const defaultProps = {
    children: null,
    ...SectionProps.defaults,
};

const ULogin = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        "section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const { login } = React.useContext(UserContext)

    const [email, setEmail] = React.useState('');
    const [pwd, setPwd] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const submit = async () => {
        setLoading(true)
        setError('')
        if (!email || !pwd) {
            setError("Por favor, rellene todos los campos");
            setLoading(false)
            return
        }

        await login(email, pwd, setError);
    }

    return (
        <section {...props} className={outerClasses}>
            <div className="container">


                <div className='welcomiCard'>
                    <Typography
                        variant="h4"
                        style={{ color: Constants.colors.primaryColor }}
                        gutterBottom>
                        Inicia Sesión
                    </Typography>

                    <Typography>
                        Inicio de sesión para usuarios.
                    </Typography>

                    <div className="textInputSpacer">
                        <TextField
                            name='email'
                            id="email"
                            label="Email"
                            variant="outlined"
                            type='email'
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            fullWidth
                        />
                    </div>

                    <div>
                        <TextField
                            name='pwd'
                            id="pwd"
                            label="Contraseña"
                            variant="outlined"
                            type='password'
                            onChange={e => setPwd(e.target.value)}
                            value={pwd}
                            fullWidth
                        />
                    </div>
                    {
                        error &&
                        <Typography style={styles.error}>
                            {error}
                        </Typography>
                    }

                    <div className='alignRight'>
                        <Button
                            variant="contained"
                            style={styles.submitButton}
                            onClick={submit}
                        >
                            Iniciar
                        </Button>
                        {
                            loading &&
                            <CircularProgress color='primary' />
                        }
                    </div>

                    <Typography style={{ textAlign: "center", color: "gray" }}>
                        Para crear tu cuenta o recuperar tu contraseña, descarga Welcomi en tu celular.
                    </Typography>
                </div>

            </div>
        </section>
    );
}

ULogin.propTypes = propTypes;
ULogin.defaultProps = defaultProps;
export default ULogin;

const styles = {
    submitButton: {
        backgroundColor: "#ffa31a",
        color: "white",
        fontWeight: "bold",
    },
    error: {
        color: "red",
        textAlign: "center"
    }
}
import React from "react"
import Constants from "../../../utils/Constants"
import Targets_Container1 from "./Targets_Container1"
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AccessibiltyIcon from '@material-ui/icons/Accessibility';
import SecurityIcon from '@material-ui/icons/Security';
import LockIcon from '@material-ui/icons/Lock';
import Design_CarsQueue from "../../../assets/svgs/Design_CarsQueue.svg"
import Design_Papeleria from "../../../assets/svgs/Design_Papeleria.svg"
import Design_Thief from "../../../assets/svgs/Design_Thief.svg"
import Design_GuardConfusedIDs from "../../../assets/svgs/Design_GuardConfusedIDs.svg"

const Targets = () => {
    return (
        <div>
            <Targets_Container1
                title="Olvida esas largas filas en tu caseta o recepción"
                bgColor={Constants.colors.secondaryColor}
                description={"Nuestro control de accesos es el más seguro, rápido y eficiente que existe en el mercado."}
                img={Design_CarsQueue}
                imgalt="Welcomi filas en caseta"
            />

            <Targets_Container1
                title="Ahorra tiempo y espacio, actualízate y deja la papelería"
                bgColor={Constants.colors.primaryColor}
                description={"Mantén un orden de todos los datos de tu administración y los historiales ordenados a la perfección."}
                img={Design_Papeleria}
                imgalt="Welcomi evita la papeleria"
            />

            <Targets_Container1
                title="Toda la comunidad participando en la seguridad"
                bgColor={"#F25F23"}
                description={"Contamos con un sistema de invitaciones, un registro de trabajadores, reservaciones y servicios rápidos, de esta manera los residentes apoyan al cuerpo de seguridad y participan para mejorar la seguridad del residencial."}
                img={Design_GuardConfusedIDs}
                imgalt="Welcomi olvida las creedenciales"
            />

            <Targets_Container1
                title="Elimina a los intrusos y protege a los residentes"
                bgColor={"#481AFF"}
                description={"Con nuestro sistema, solo ingresa quien realmente debe acceder a la localidad. Nunca antes la seguridad fue tan fácil."}
                img={Design_Thief}
                imgalt="Welcomi mejora de seguridad residencial"
            />
        </div>
    )
}

export default Targets
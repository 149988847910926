import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import catchErrors from "../cases/Firecases/catchErrors";
import Fire, { db, functions } from "../Fire";

export const GuardContext = React.createContext();

var invitationsUnsuscribeRef;
var fservicesUnsuscribeRef;
var guardLocality = null;

export function GuardProvider(props) {
  const [guard, setGuard] = useState(null);
  const [guardDocument, setGuardDocument] = useState(null);
  const [guardActiveLocality, setGuardActiveLocality] = useState(null);
  const [guardConf, setGuardConf] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Addresses
  const [addresses, setAddresses] = useState([]);

  // Invitations and fast services
  const emtpyInvitations = [
    {
      title: "Invitados", // Si cambio este valor, cambiarlo en la searchbar
      data: [],
    },
    {
      title: "Trabajadores invitados", // Si cambio este valor, cambiarlo en la searchbar
      data: [],
    },
  ];
  const [invitations, setInvitations] = useState(emtpyInvitations);
  const [fastServices, setFastServices] = useState([]);

  var dateQuery = new Date();
  dateQuery.setHours(0, 0, 0, 0);

  useEffect(() => {
    console.log("INICIANDO TIMER");

    // El setinterval cuando ejecuta changeTime,
    // esta en otro contexto, por eso hacemos global
    // invitationsUnsuscribeRef y guardActiveLocality (guardLocality)
    const timerIntetval = setInterval(changeTime, 45000);
    // Valdria la pena evitar que esten en contextos diferentes
    // could help:
    // https://stackoverflow.com/questions/57641411/why-does-this-custom-react-hook-using-usecontext-useeffect-and-setinterval-fail

    return () => {
      clearInterval(timerIntetval);
      try {
        invitationsUnsuscribeRef();
        fservicesUnsuscribeRef();
      } catch (e) {}
    };
  }, []);

  const changeTime = () => {
    let updateQueryDate = new Date();
    updateQueryDate.setHours(0, 0, 0, 0);
    if (dateQuery.getDate() === updateQueryDate.getDate()) {
      // console.log("Misma fecha, no hay necesidad de actualizar");
    } else {
      // console.log("Cambiando fecha");
      dateQuery = updateQueryDate;

      // try delete firebase realtime
      // call it again with new date.
      try {
        // console.log("Eliminando suscriber pasado");
        invitationsUnsuscribeRef();
        fservicesUnsuscribeRef();
        _getRTInvitations();
        _getRTFastServices();
      } catch (e) {
        // console.log(e);
        logout();
        alert("Error inesperado, por favor vuelva a iniciar sesión");
      }
    }
  };

  const login = async (email, password, setError) => {
    // Firebase login request
    await Fire.handleLogin(email, password)
      .then(async (user) => {
        //  On web we are not validating user range
        setGuard(user);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        catchErrors(error, setError);
        setGuard(null);
        setIsLoggedIn(false);
      });
  };

  const logout = async () => {
    await Fire.handleSignOut()
      .then(() => {
        // window.location.href = "/";
        setGuard(null);
        setGuardDocument(null);
        setGuardActiveLocality(null);
        guardLocality = null;
        setAddresses([]);
        setInvitations([]);
        setFastServices([]);
        setGuardConf(null);
        setIsLoggedIn(false);
      })
      .catch((error) => {
        try {
          // console.log("Eliminando suscriber");
          invitationsUnsuscribeRef();
          fservicesUnsuscribeRef();
        } catch (e) {}
      });
  };

  const verifyAuth = async () => {
    await Fire.verifyAuth()
      .then((guard) => {
        setGuard(guard);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        logout();
      });
  };

  const getGuardDocument = async () => {
    let snapshot = await db
      .collection("guards")
      .doc(guard.uid)
      .get()
      .catch((error) => {
        logout();
        alert("Datos del guardia no encontrados, por favor intente desde la aplicación en su smartphone.");
      });

    if (!snapshot.exists) {
      logout();
      alert("Datos del guardia no encontrados, por favor intente desde la aplicación en su smartphone.");
    }

    if (snapshot.exists) {
      await setGuardDocument({ doc_id: snapshot.id, doc_data: snapshot.data() });

      let doc_data = snapshot.data();

      if (doc_data.locality_info.length === 0) {
        setGuardActiveLocality(null);
      }

      if (doc_data.locality_info.length > 0) {
        for (let i = 0; i < doc_data.locality_info.length; i++) {
          console.log("ciclo");

          if (doc_data.locality_info[i].accepted) {
            let data = [doc_data.locality_info[i], doc_data[doc_data.locality_info[i].locality_ID]];
            setGuardActiveLocality(data);
            guardLocality = data;
            return;
          }
        }
      }
    }
  };

  const getLocalityConf = async () => {
    // Get locality conf
    await functions
      .httpsCallable("getLocalityConf")({
        locality_ID: guardLocality[0].locality_ID,
        userRange: guardLocality[1].locality_range,
      })
      .then((answ) => {
        setGuardConf(answ.data);
        console.log(answ.data);
      })
      .catch((error) => {
        alert("No pudimos obtener los permisos de tu localidad, inicia sesión nuevamente.");
        logout();
      });
  };

  const getLocalityAddresses = async () => {
    let snapshot = await db
      .collection("localities")
      .doc(guardLocality[0].locality_ID)
      .collection("addresses")
      .get()
      .catch((error) => {
        alert("No pudimos obtener las direcciones de tu localidad, inicia sesión nuevamente.");
        setAddresses([]);
        logout();
      });

    if (snapshot.empty) {
      alert("Tu localidad no tiene direcciones, reportalo al administrador de tu localidad.");
      setAddresses([]);
    }

    if (!snapshot.empty) {
      let snapshot_addresses = [];

      await snapshot.docs.forEach((doc) => {
        snapshot_addresses.push({
          doc_id: doc.id,
          doc_data: doc.data(),
        });
      });

      setAddresses(snapshot_addresses);
    }
  };

  const _getRTInvitations = async () => {
    // console.log("obteniendo ivitacions del server...");

    invitationsUnsuscribeRef = await db
      .collection("invitations")
      .where("sender.locality_ID", "==", guardLocality[0].locality_ID)
      .where("access_denied.denied", "==", false)
      .where("data.is_here", "==", false)
      .where("data.invitation_date", "==", dateQuery)
      .limit(150)
      .onSnapshot(
        async (querySnapshot) => {
          if (!querySnapshot.docs.length) {
            setInvitations(emtpyInvitations);
            // console.log("Sin invitaciones.");
          }

          if (querySnapshot.docs.length) {
            let guests = {
              title: "Invitados", // Si cambio este valor, cambiarlo en la searchbar
              data: [],
            };
            let workers = {
              title: "Trabajadores invitados", // Si cambio este valor, cambiarlo en la searchbar
              data: [],
            };

            await querySnapshot.forEach(async (doc) => {
              if (doc.data().aalpha.type === "invitation_residential") guests.data.push({ doc_id: doc.id, doc_data: doc.data(), kind: "guest" });

              if (doc.data().aalpha.type === "invitation_worker_residential") workers.data.push({ doc_id: doc.id, doc_data: doc.data(), kind: "worker" });

              // No welcomi user
              if (doc.data().aalpha.type === "invitation_residential_saved_guest") guests.data.push({ doc_id: doc.id, doc_data: doc.data(), kind: "guest_saved_guest" });

              if (doc.data().aalpha.type === "invitation_worker_residential_saved_guest") workers.data.push({ doc_id: doc.id, doc_data: doc.data(), kind: "worker_saved_guest" });
            });

            // Si meto publicidad tengo que validar
            // la filtracion en el searchbar.

            console.log([guests, workers]);
            setInvitations([guests, workers]);
          }
        },
        (error) => {
          setInvitations(emtpyInvitations);
        }
      );
  };

  const _getRTFastServices = async () => {
    fservicesUnsuscribeRef = await db
      .collection("fast_services")
      .where("service_date", "==", dateQuery)
      .where("resident_locality_ID", "==", guardLocality[0].locality_ID)
      .where("is_gone", "==", false)
      .limit(75)
      .onSnapshot(
        async (querySnapshot) => {
          if (!querySnapshot.docs.length) {
            setFastServices([]);
          }

          if (querySnapshot.docs.length) {
            let snapshot_fastServices = [];

            await querySnapshot.forEach(async (doc) => {
              snapshot_fastServices.push({ doc_id: doc.id, doc_data: doc.data() });
            });

            console.log(snapshot_fastServices);
            setFastServices(snapshot_fastServices);
          }
        },
        (error) => {
          setFastServices([]);
        }
      );
  };

  return (
    <GuardContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        guard,
        guardDocument,
        guardActiveLocality,
        guardConf,
        addresses,
        invitations,
        fastServices,
        login,
        logout,
        verifyAuth,
        getGuardDocument,
        setIsLoading,
        getLocalityConf,
        getLocalityAddresses,
        _getRTInvitations,
        _getRTFastServices,
      }}
    >
      {props.children}
    </GuardContext.Provider>
  );
}

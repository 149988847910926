import React, { useState } from "react";
import catchErrors from "../cases/Firecases/catchErrors";
import Fire from "../Fire";

export const CaptainContext = React.createContext();

export function CaptainProvider(props) {
  const [captain, setCaptain] = useState(null);
  const [captainDocument, setCaptainDocument] = useState(null);
  const [captainActiveLocality, setCaptainActiveLocality] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async (email, password, setError) => {
    // Firebase login request
    await Fire.handleLogin(email, password)
      .then(async (captain) => {
        setCaptain(captain);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        catchErrors(error, setError);
        setCaptain(null);
        setIsLoggedIn(false);
      });
  };

  const logout = async () => {
    await Fire.handleSignOut().then(() => {
      setIsLoggedIn(false);
      setCaptain(null);
      setCaptainDocument(null);
    });
  };

  const verifyAuth = async () => {
    await Fire.verifyAuth()
      .then(async (captain) => {
        setCaptain(captain);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        setCaptain(null);
        setIsLoggedIn(false);
      });
  };

  return (
    <CaptainContext.Provider
      value={{
        captain,
        captainDocument,
        captainActiveLocality,
        isLoggedIn,
        login,
        logout,
        verifyAuth,
      }}
    >
      {props.children}
    </CaptainContext.Provider>
  );
}

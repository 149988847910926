import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import Guard_rounded from '../../../assets/svgs/guard_rounded.svg';
import { GuardContext } from '../../../Context/GuardContext';
const GLogin = () => {
    const { login } = useContext(GuardContext)

    const [email, setEmail] = React.useState('');
    const [pwd, setPwd] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const submit = async () => {
        setLoading(true);
        setError('')
        if (!email || !pwd) {
            setError("Por favor, rellene todos los campos");
            setLoading(false);
            return
        }

        await login(email, pwd, setError);
        setLoading(false);
    }

    return (
        <div className='welcomiCard'>

            <div style={{ display: "flex", width: "100%" }}>
                <img
                    src={Guard_rounded}
                    alt="Welcomi Guard"
                    style={{ width: "15%", height: "15%", marginRight: "10px" }}
                />
                <Typography
                    variant="h4"
                    style={{ alignSelf: "center" }}
                    color='primary'
                    gutterBottom>
                    Inicio de sesión para guardias.
                </Typography>
            </div>



            <div className="textInputSpacer">
                <TextField
                    name='email'
                    id="email"
                    label="Email"
                    variant="outlined"
                    type='email'
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    fullWidth
                />
            </div>

            <div>
                <TextField
                    name='pwd'
                    id="pwd"
                    label="Contraseña"
                    variant="outlined"
                    type='password'
                    onChange={e => setPwd(e.target.value)}
                    value={pwd}
                    fullWidth
                />
            </div>

            {
                error &&
                <Typography style={styles.error}>
                    {error}
                </Typography>
            }

            <div className='alignRight'>
                <Button
                    variant="contained"
                    style={styles.submitButton}
                    onClick={submit}
                    disabled={loading}
                >
                    Iniciar
                </Button>
                {
                    loading &&
                    <CircularProgress color='primary' />
                }
            </div>

            <Typography
                variant='subtitle1'
                style={{ textAlign: "center", color: "grey" }} >
                Para crear tu cuenta o recuperar tu contraseña, descarga Welcomi Guard en tu celular.
            </Typography>
        </div>
    );
}

export default GLogin;

const styles = {
    submitButton: {
        backgroundColor: "#ffa31a",
        color: "white",
        fontWeight: "bold",
    },
    error: {
        color: "red",
        textAlign: "center"
    }
}
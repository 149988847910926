import React from 'react';
import About from '../components/Residencial/About';
import Contact from '../components/Residencial/Contact/Contact';
import Descargas from '../components/Residencial/Downloads/Descargas';
import Guias from '../components/Residencial/Guides/Guias';
import HomeResidencial from '../components/Residencial/Home/HomeResidencial';
import RequestPlanForm_R_Thanks from '../components/Residencial/Home/RequestPlanForm_R_Thanks';
import ResidentialLanding from '../components/Residencial/Landings/ResidentialLanding';
import SelectLogin from '../components/Residencial/Logged/SelectLogin';
import Prices from '../components/Residencial/Pricing/Prices';
import PricesExplained from '../components/Residencial/Pricing/PricesExplained';
import privacidad from '../components/Residencial/privacidad';
import RegisterByEmail from '../components/Residencial/RegisterByEmail/RegisterByEmail';
import TyC from '../components/Residencial/TyC';
import LayoutDefault from '../layouts/LayoutDefault';
import AppRoute from '../utils/AppRoute';

const ResidentialNav = () => {
    return (
        <>
            <AppRoute
                exact
                path="/"
                component={HomeResidencial}
                layout={LayoutDefault}
            />

            <AppRoute
                exact
                path="/controldeaccesos"
                component={ResidentialLanding}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/terminosycondiciones"
                component={TyC}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/privacidad"
                component={privacidad}
                layout={LayoutDefault}
            />

            <AppRoute
                path='/login'
                component={SelectLogin}
                layout={LayoutDefault} />

            <AppRoute
                path="/contacto"
                component={Contact}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/requestform_submitted"
                component={RequestPlanForm_R_Thanks}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/guias/:guide?"
                component={Guias}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/about"
                component={About}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/precios"
                component={Prices}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/precios_detalles"
                component={PricesExplained}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/RegisterByEmail/:email?"
                component={RegisterByEmail}
                layout={LayoutDefault}
            />

            <AppRoute
                path="/Descargas"
                component={Descargas}
                layout={LayoutDefault}
            />
        </>
    );
}

export default ResidentialNav;
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Route, Switch } from 'react-router-dom'

import GuiasListMenu from './GuiasListMenu'
import { SectionProps } from '../../../utils/SectionProps'
import GuiaQueEsWelcomi from './GuiaQueEsWelcomi'
import GuiaConvertirseGuardia from './GuiaConvertirseGuardia'
import GuiaSubirIdentificacion from './GuiaSubirIdentificacion'
import GuiaRegistrarResidencial from './GuiaRegistrarResidencial'
import GuiaDiferenciaResidenteParticular from './GuiaDiferenciaResidenteParticular'

import './Guias.css'
import { GuiasCaptain } from './GuiasCaptain'

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
}

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
}

const Guias = ({
  match,
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  return (
    <section {...props} className={outerClasses}>
      {children}
      <div className="guides_row">
        <div className="guides_col">
          <GuiasListMenu  />
        </div>
        <div className="guides_col2">
            <Switch>
              <Route path='/guias/que_es_welcomi' component={GuiaQueEsWelcomi} />
              <Route path='/guias/registrar_residencial' component={GuiaRegistrarResidencial} />
              <Route path='/guias/subir_identificacion' component={GuiaSubirIdentificacion} />
              <Route path='/guias/convertirse_guardia' component={GuiaConvertirseGuardia} />
              <Route path='/guias/diferencia_residente_particular' component={GuiaDiferenciaResidenteParticular} />

              <Route path='/guias/registrar_areas_comunes' component={GuiasCaptain} />
              <Route path='/guias/registrar_residentes' component={GuiasCaptain} />
              <Route path='/guias/registrar_guardias' component={GuiasCaptain} />
            </Switch>
        </div>
      </div>
    </section>
  )
}

Guias.propTypes = propTypes
Guias.defaultProps = defaultProps

export default Guias

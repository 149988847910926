import { Paper } from '@material-ui/core';
import React, { useContext } from 'react';
import Split from 'react-split'
import AccessCameras from './AccessCameras';
import AccessWelcomi from './AccessWelcomi';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import IconButton from '@material-ui/core/IconButton';
import '../Styles/GuardStyles.css'
import { GuardContext } from '../../../Context/GuardContext';
import { makeStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.secondary.main,
    },
}));

const Access = () => {

    const { isLoading } = useContext(GuardContext);

    const originalHorizontalValues = [100]

    const [horizontalSizes, setHorizontalSizes] = React.useState(originalHorizontalValues);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                // textColor="primary"
                >
                    <Tab
                        label="Acceso local"
                        {...a11yProps(0)}
                        style={styles.tabStyle}
                    />
                    <Tab
                        label="Acceso Welcomi"
                        {...a11yProps(1)}
                        style={styles.tabStyle}
                    />
                    {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
            </Paper>

            <TabPanel value={value} index={0}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton
                        color="secondary"
                        variant="contained"
                        style={{ padding: 0 }}
                        onClick={() => {
                            setHorizontalSizes(originalHorizontalValues)
                        }}
                    >
                        <ViewColumnIcon />
                    </IconButton>
                </div>
                <Split
                    sizes={horizontalSizes}
                    minSize={100}
                    gutterSize={10}
                    gutterAlign="center"
                    snapOffset={30}
                    dragInterval={1}
                    direction="horizontal"
                    cursor="col-resize"
                    className='columnSplitter'
                    onDrag={(size) => {
                        setHorizontalSizes([size[0]])
                        // setHorizontalSizes([size[0], size[1]])
                    }}
                >
                    <AccessCameras />
                </Split>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AccessWelcomi />
            </TabPanel>
        </>
    )
}

export default Access;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    column: {
        width: "33%"
    },
    tabStyle: {
        color: "white",
        fontWeight: "500"
    }
}
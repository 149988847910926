import React, { useContext } from 'react';
import { GuardContext } from '../../../Context/GuardContext';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Today from '@material-ui/icons/Today';
import BuildIcon from '@material-ui/icons/Build';
import "../Styles/GuardStyles.css"
import InvitationsContainer from './InvitationsContainer';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import FastServicesContainer from './FastServicesContainer';

const AccessWelcomi = () => {
    const { invitations, fastServices } = useContext(GuardContext);

    const [openInvitations, setOpenInvitations] = React.useState(false);
    const [openWorkers, setOpenworkers] = React.useState(false);
    const [openFServices, setOpenFServices] = React.useState(false);


    const renderGuest = () => {
        return invitations[0].data.map((item, index) =>
            <InvitationsContainer {...item} key={index} />
        )
    }
    const renderWorkers = () => {
        return invitations[1].data.map((item, index) =>
            <InvitationsContainer {...item} key={index} />
        )
    }

    const renderFServices = () => {
        return fastServices.map((item, index) =>
            <FastServicesContainer {...item} key={index} />
        )
    }

    if (!invitations.length && !fastServices.length) {
        return (
            <p>No hay invitaciones por el momento</p>
        )
    }

    return (
        <div
            className='splitContainer'
        >
            <div style={{ maxHeight: '100%', overflow: 'auto' }}>
                {/* className='insideSplitContainer' */}
                <ListItem button onClick={() => setOpenInvitations(!openInvitations)}>
                    <ListItemIcon>
                        <Today />
                    </ListItemIcon>
                    <ListItemText primary="Invitaciones" />
                    {openInvitations ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openInvitations} timeout="auto" unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                    >
                        {
                            invitations[0].data.length > 0 &&
                            renderGuest()
                        }
                    </List>
                </Collapse>

                {/* trabajadores */}
                <ListItem button onClick={() => setOpenworkers(!openWorkers)}>
                    <ListItemIcon>
                        <BuildIcon />
                    </ListItemIcon>
                    <ListItemText primary="Trabajadores" />
                    {openWorkers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openWorkers} timeout="auto" unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                    >
                        {
                            invitations[1].data.length > 0 &&
                            renderWorkers()
                        }
                    </List>
                </Collapse>


                {/* Fast services */}
                <ListItem button onClick={() => setOpenFServices(!openFServices)}>
                    <ListItemIcon>
                        <MotorcycleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Servicios Rápidos" />
                    {openFServices ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openFServices} timeout="auto" unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                    >
                        {renderFServices()}
                    </List>
                </Collapse>
            </div>
        </div>
    );
}

export default AccessWelcomi;
import { CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Account from '../components/Residencial/Logged/Account';
import { UserContext } from '../Context/UserContext';
import LayoutDefault from '../layouts/LayoutDefault';
import AppRoute from '../utils/AppRoute';
import ULogin from "../components/Users/ULogin"
import { Redirect } from 'react-router-dom';
import Constants from '../utils/Constants';

const UserNav = () => {
    const { verifyAuth, isLoggedIn } = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        validateSession();
    }, [])

    const validateSession = async () => {
        await verifyAuth();
        setLoading(false)
    }

    if (loading) {
        return <CircularProgress style={{ color: Constants.colors.primaryColor }} />
    }

    return (
        !isLoggedIn ?
            <>
                <Redirect to='/' />
                <AppRoute
                    path='/'
                    component={ULogin}
                />
            </>
            :
            <>
                <Redirect to="/account" />
                <AppRoute
                    path="/account"
                    component={Account}
                />
            </>
    );
}

export default UserNav;
import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";


const StyledTableCell = withStyles((theme) => ({
    head: {
        background: " #FAA13A ",
        color: theme.palette.common.white,
        textIndent: 20,
    },
    body: {
        fontSize: 16,
        textIndent: 10,
        background: "#FFFFFF",
        paddingRight: 5,
    },
}))(TableCell);

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});

function createData(name, wfree, wplus, desc) {
    return {
        name,
        wfree,
        wplus,
        description: desc,
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(true);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        style={{ alignSelf: "right" }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>

                <TableCell align="right">{row.wfree}</TableCell>

                <TableCell align="right" style={{ paddingRight: 20 }}>
                    {row.wplus}
                </TableCell>
            </TableRow>


            {/* <TableRow> */}
            <TableCell style={{ paddingBottom: 30, paddingTop: 30 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {/* <Box margin={1}> */}
                    <div style={{ backgroundColor: "white" }}>
                        <Table size="small" aria-label="purchases">


                            <TableBody>
                                {row.description.map((data) => (
                                    <TableRow key={data.text}>
                                        {/* <TableCell /> */}
                                        <TableCell align="right" style={{ width: "70%" }}>{data.text}</TableCell>
                                        <TableCell align="right" style={{ width: "15%" }}>{data.available1}</TableCell>
                                        <TableCell align="right" style={{ width: "15%" }}>{data.available2}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* </Box> */}

                    </div>
                </Collapse>
            </TableCell>
            {/* </TableRow> */}
        </React.Fragment>
    );
}

const rows = [
    createData("Control de acceso", "", "", [
        {
            text: "Registro de visitantes",
            available1: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Registro de trabajadores",
            available1: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Registro de servicios rápidos",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Invitaciones Especiales",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Invitaciones Frecuentes",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
    ]),
    createData(
        "Comunicados", "", "",
        [
            {
                text: "Escribir comunicados",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Notificaciones",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Multimedia",
                available1: (
                    <CancelIcon
                        titleAccess="Cross"
                        fontSize="small"
                        style={{ color: "red" }}
                        className="cross"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Marcar comunicado como leído",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
        ]
    ),
    createData(
        "Reservaciones de Áreas Comúnes", "", "",
        [
            {
                text: "Notificaciones de Reservas",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Reservar",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
        ]
    ),
    createData(
        "Añadir", "", "",
        [
            {
                text: "Anadir o eliminar residentes",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Anadir o eliminar guardias",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
        ]
    ),
    createData(
        "Plantillas", "", "",
        [
            {
                text: "Plantilla de residentes",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Plantilla de guardias",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
        ]
    ),
    createData(
        "Paquetería", "", "",
        [
            {
                text: "Registrar paquete",
                available1: (
                    <CancelIcon
                        titleAccess="Cross"
                        fontSize="small"
                        style={{ color: "red" }}
                        className="cross"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Notificar llegada al residente",
                available1: (
                    <CancelIcon
                        titleAccess="Cross"
                        fontSize="small"
                        style={{ color: "red" }}
                        className="cross"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
        ]
    ),
    createData("Tags", "", "", [
        {
            text: "Registro de Tags",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Listado de propietarios de los Tags",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
    ]),
    createData("Historiales", "Un Mes", "Ilimitado", [
        {
            text: "Historial de Visitas",
            available1: (
                <CheckCircleIcon
                    titleAccess="check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Historial de Trabajadores",
            available1: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Historial de Servicios Rapidos",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Historial de Paquetes",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
        {
            text: "Historial de Reservaciones",
            available1: (
                <CancelIcon
                    titleAccess="Cross"
                    fontSize="small"
                    style={{ color: "red" }}
                    className="cross"
                />
            ),
            available2: (
                <CheckCircleIcon
                    titleAccess="Check"
                    fontSize="small"
                    style={{ color: "green" }}
                    className="check"
                />
            ),
        },
    ]),
    createData("Extras", "", "",
        [
            {
                text: "Contactar Personal",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Wellmates",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Ver personal de la localidad",
                available1: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
            {
                text: "Reglamento de la Localidad",
                available1: (
                    <CancelIcon
                        titleAccess="Cross"
                        fontSize="small"
                        style={{ color: "red" }}
                        className="cross"
                    />
                ),
                available2: (
                    <CheckCircleIcon
                        titleAccess="Check"
                        fontSize="small"
                        style={{ color: "green" }}
                        className="check"
                    />
                ),
            },
        ]),
];

const PricesExplained = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        "section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    return (
        <div className={innerClasses}>
            <div className="tabladePrecios">
                <div className="container">
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>

                                    <StyledTableCell />
                                    <StyledTableCell />
                                    <StyledTableCell align="right">Plan Gratuito</StyledTableCell>
                                    <StyledTableCell align="right" style={{ paddingRight: 20, fontWeight: "bold" }}>
                                        Plan Plus
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rows.map((row) => (
                                    <Row key={row.name} row={row} />
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
}

export default PricesExplained;
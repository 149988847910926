import React from "react";
import "./Targets.css"

const Targets_Container1 = ({
    title,
    bgColor,
    description,
    img,
    imgalt
}) => {

    return (
        <div
            style={{ backgroundColor: bgColor }}
            className="reveal-from-bottom bgMargin"
            data-reveal-delay="50"
        >

            <div className="targetContainer" >

                <div className="targetTextContainer">
                    <h3 className="targetTitle">{title}</h3>

                    <div className="explanationContainer" >
                        <p className="targetExplanation">{description}</p>
                    </div>
                </div>

                <div style={{ width: "5%" }} />

                <div className="targetImage" >
                    <img src={img} alt={imgalt} style={{ width: "95%" }} />
                </div>
            </div>
        </div>
    )
}

export default Targets_Container1;
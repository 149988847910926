import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstorelog.png";
import "./Descargas.css";
const DownloadCard = (props) => {

    return (
        <div style={{
            marginTop: 20,
            marginBottom: 30,
            boxShadow: "2px 5px 9px #000000",
            borderRadius: 15
        }}>
            <div style={{ marginRight: "1%", marginLeft: "1%", marginTop: "4%", marginBottom: "6%" }}>

                <h3>{props.title}</h3>

                <div style={{ display: "flex", flexDirection: "row", marginBottom: 15, paddingBottom: 15 }}>
                    <div style={{ width: "25%" }}>
                        <img src={props.app_img} alt="Welcomi" />
                    </div>

                    <div style={{ width: "70%", marginLeft: 15 }}>
                        <p style={{ color: "gray" }}>{props.description}</p>

                        <p style={{ textAlign: "center" }}>¡Descárgala ahora!</p>

                        <div className="download_row">
                            <div className="playstorelogo">
                                <div className="download_col">
                                    <a href={props.href_android} target="_blank" rel="noopener noreferrer">
                                        <img src={playstore} alt="tienda de google" />
                                    </a>
                                </div>
                            </div>

                            <div className="appstorelogo">
                                <div className="download_col">
                                    <a href={props.href_ios} target="_blank" rel="noopener noreferrer">
                                        <img src={appstore} alt="Welcomi apple" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadCard
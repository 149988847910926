import React, { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AdminContext } from '../Context/AdminContext';
import ExitToApp from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Dashboard from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { Collapse } from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ListIcon from '@material-ui/icons/List';
import EmailIcon from '@material-ui/icons/Email';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const LayoutAdmin = ({ children }) => {
    const { isLoading, adminDocument } = useContext(AdminContext);

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [openUsers, setOpenUsers] = React.useState(false);
    const [openLocalities, setOpenLocalities] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const mainPages = [
        {
            text: "Dashboard",
            icon: <Dashboard />,
            path: "/dashboard",
            isCollapsive: false,
            isCollapsed: false,
            kind: null
        },
        !isLoading &&
        {
            text: "Localidades",
            icon: <ApartmentIcon />,
            path: "/localities",
            isCollapsive: true,
            isCollapsed: openLocalities,
            kind: "localitiesCollapsive"
        },
        !isLoading &&
        {
            text: "Usuarios",
            icon: <AccountCircleIcon />,
            path: "/users",
            isCollapsive: true,
            isCollapsed: openUsers,
            kind: "usersCollapsive"
        },
    ]

    const userPages = [
        {
            text: "PP & ID's",
            icon: <RecentActorsIcon />,
            path: '/users/review'
        }
    ]

    const localitiesPages = [
        {
            text: "Localidades",
            icon: <ListIcon />,
            path: "/localities/all"
        },
        {
            text: "Not in Mailchimp",
            icon: <EmailIcon />,
            path: "/mails_mailchimp"
        }
    ]

    const secondaryPages = [
        {
            text: "Cerrar sesión",
            icon: <ExitToApp />,
            path: "/signout",
        }
    ]


    const handleOpenUsers = () => {
        setOpenUsers(!openUsers);
    };

    const handleOpenLocalities = () => {
        setOpenLocalities(!openLocalities)
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >

                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Welcomi Admin
                    </Typography>

                    {/* {
                        guardDocument &&
                        <div>
                            <Avatar alt="Guard image" src={guardDocument.doc_data.account_info.profile_image} />
                        </div>
                    } */}
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />

                <List>
                    {
                        mainPages.map((page) => (
                            <>
                                <ListItem
                                    button
                                    key={page.text}
                                    onClick={() => {
                                        if (page.isCollapsive) {
                                            page.kind === "usersCollapsive" && handleOpenUsers();
                                            page.kind === "localitiesCollapsive" && handleOpenLocalities()
                                            if (!open)
                                                history.push(page.path)
                                        }

                                        if (!page.isCollapsive) {
                                            history.push(page.path)
                                        }
                                    }}
                                >
                                    <ListItemIcon>{page.icon}</ListItemIcon>
                                    <ListItemText primary={page.text} />

                                    {
                                        // This if only works with ? insted of &&
                                        page.isCollapsive ?
                                            page.isCollapsed ? <ExpandLess /> : <ExpandMore />
                                            :
                                            null
                                    }
                                </ListItem>


                                {
                                    page.kind === "usersCollapsive" &&
                                    (openUsers && open) && (
                                        page.isCollapsed &&
                                        <Collapse in={page.isCollapsed} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {
                                                    userPages.map(subpage => (
                                                        <ListItem
                                                            button
                                                            className={classes.nested}
                                                            onClick={() => history.push(subpage.path)}
                                                        >
                                                            <ListItemIcon>
                                                                {subpage.icon}
                                                            </ListItemIcon>
                                                            <ListItemText primary={subpage.text} />
                                                        </ListItem>

                                                    ))
                                                }
                                            </List>
                                        </Collapse>
                                    )
                                }

                                {
                                    page.kind === "localitiesCollapsive" &&
                                    (openLocalities && open) && (
                                        page.isCollapsed &&
                                        <Collapse in={page.isCollapsed} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {
                                                    localitiesPages.map(subpage => (
                                                        <ListItem
                                                            button
                                                            className={classes.nested}
                                                            onClick={() => history.push(subpage.path)}
                                                        >
                                                            <ListItemIcon>
                                                                {subpage.icon}
                                                            </ListItemIcon>
                                                            <ListItemText primary={subpage.text} />
                                                        </ListItem>

                                                    ))
                                                }
                                            </List>
                                        </Collapse>
                                    )
                                }

                            </>
                        ))
                    }
                </List>
                <Divider />

                <List>
                    {secondaryPages.map((page) => (
                        <ListItem
                            button
                            key={page.text}
                            onClick={() => history.push(page.path)}
                        >
                            <ListItemIcon>{page.icon}</ListItemIcon>
                            <ListItemText primary={page.text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            {/* main content */}
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    );
}

export default LayoutAdmin
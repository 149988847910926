import React, { useEffect, useState } from 'react';
import { TextField } from "@material-ui/core"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Controller, useForm } from "react-hook-form";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./Registro.css"
import Constants from '../../../utils/Constants';
import { auth, functions } from '../../../Fire';

const RegistroUpdateProfile = ({
    onSubmitToFirebase,
}) => {

    const { register, handleSubmit, setError, setValue, control, errors } = useForm({
        defaultValues: {
            first_name: "",
            last_name: "",
            second_last_name: "",
            birthday: new Date(),
            profilePicture: "",
            idPicture: ""

        }
    })
    useEffect(() => {
        functions.httpsCallable("mailAfterWebSignUp")({
            dest: auth.currentUser.email
        }).catch(error => { console.log(error) })
    }, [])

    const [gender, setGender] = React.useState('Mujer');
    const [loading, set_loading] = useState(false)

    const handleChange = e => {
        setGender(e.target.value)
    }

    const onSubmit = (data) => {
        let birthdate = new Date(data.birthday)
        birthdate.setDate(birthdate.getDate() + 1)
        // console.log(date)

        set_loading(true)
        onSubmitToFirebase(data, gender, birthdate, set_loading, setError)
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField
                        name="first_name"
                        id="standard-full-width"
                        label="Nombre(s)"
                        inputRef={register({
                            required: {
                                value: true,
                                message: "Introduzca su nombre(s)."
                            },
                        })}
                        margin="normal"
                        fullWidth
                    />
                    {errors.first_name && (
                        <span className="errorMessage">{errors.first_name.message}</span>
                    )}
                </div>


                <div>
                    <TextField
                        name="last_name"
                        id="standard-full-width"
                        label="Primer apellido"
                        inputRef={register({
                            required: {
                                value: true,
                                message: "Introduzca su primer apellido."
                            },
                        })}
                        margin="normal"
                        fullWidth
                    />
                    {errors.last_name && (
                        <span className="errorMessage">{errors.last_name.message}</span>
                    )}
                </div>

                <div>
                    <TextField
                        name="second_last_name"
                        id="standard-full-width"
                        label="Segundo apellido"
                        inputRef={register({
                            required: {
                                value: true,
                                message: "Introduzca su segundo apellido."
                            },
                        })}
                        margin="normal"
                        fullWidth
                    />
                    {errors.second_last_name && (
                        <span className="errorMessage">{errors.second_last_name.message}</span>
                    )}
                </div>

                <div>
                    < FormControl component="fieldset" margin="normal" >
                        <FormLabel component="legend">Genero</FormLabel>
                        {/* <Controller
                            rules={{ required: true }}
                            control={control}
                            defaultValue="Mujer"
                            name="gender"
                            as={ */}
                        <RadioGroup
                            aria-label="gender"
                            value={gender}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="Mujer" control={<Radio color="secondary" />} label="Femenino" />
                            <FormControlLabel value="Hombre" control={<Radio color="secondary" />} label="Masculino" />
                            <FormControlLabel value="No especificar" control={<Radio color="secondary" />} label="No especificar" />
                        </RadioGroup>
                        {/* }
                        /> */}
                    </FormControl>
                </div>

                <div className="inputs_container">
                    <label className="labels">Fecha de nacimiento:</label>
                    <input
                        type="date"
                        name="birthday"
                        placeholder="Fecha de nacimiento"
                        rows="1"
                        ref={register({
                            required: {
                                value: true,
                                message: " Por favor introduzca su fecha de nacimiento",
                                pattern: "d{1,2}/d{1,2}/d{4}",
                            },
                            maxLength: {
                                value: 30,
                                message: " Por favor use menos de 30 caracteres",
                            },
                        })}
                    />
                    {errors.birthday && (
                        <span className="errorMessage">
                            {errors.birthday.message}
                        </span>
                    )}
                </div>

                <div className="inputs_container">
                    <label className="labels">Foto de Perfil (donde unicamente aparezcas tu)</label>
                    <input
                        type="file"
                        name="profilePicture"
                        lang='es'
                        placeholder="Foto de perfil"
                        id="profilePicture"
                        accept=".jpg,.jpeg"
                        multiple={false}
                        onChange={e => {

                            //Hacerlo mejor como dice aqui: https://stackoverflow.com/questions/4234589/validation-of-file-extension-before-uploading-file

                            let fileName = e.target.value;
                            let idxDot = fileName.lastIndexOf(".") + 1;
                            let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

                            if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
                                setError("profilePicture",
                                    {
                                        type: "",
                                        message: ""
                                    })
                            } else {
                                setError("profilePicture", {
                                    type: "manual",
                                    message: "Extension invalida"
                                })
                                setValue("profilePicture", "", { shouldValidate: true })
                            }
                        }}
                        ref={register({
                            required: {
                                value: true,
                                message: "Seleccione una imagen"
                            }
                        })}
                        required
                    />
                    {errors.profilePicture && (
                        <span className="errorMessage">
                            {errors.profilePicture.message}
                        </span>
                    )}
                </div>

                <div className="inputs_container">
                    <label className="labels">Identificación (INE, Pasaporte, Licencia de conducir, Credencial de Institución Educativa con foto o Documento oficial de otro país)</label>
                    <input
                        type="file"
                        name="idPicture"
                        lang='es'
                        accept=".jpg,.jpeg"
                        onChange={e => {

                            //Hacerlo mejor como dice aqui: https://stackoverflow.com/questions/4234589/validation-of-file-extension-before-uploading-file

                            let fileName = e.target.value;
                            let idxDot = fileName.lastIndexOf(".") + 1;
                            let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

                            if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
                                setError("idPicture",
                                    {
                                        type: "",
                                        message: ""
                                    })
                            } else {
                                setError("idPicture", {
                                    type: "manual",
                                    message: "Extension invalida"
                                })
                                setValue("idPicture", "", { shouldValidate: true })
                            }
                        }}
                        ref={register({
                            required: {
                                value: true,
                                message: "Seleccione una imagen"
                            }
                        })}
                        required
                    />
                    {errors.idPicture && (
                        <span className="errorMessage">
                            {errors.idPicture.message}
                        </span>
                    )}
                </div>


                <div style={{ marginTop: 20 }}>
                    <p>Todos los datos anteriores son solicitados para garantizar tu acceso a las zonas residenciales. Asegúrate de rellenar todos los campos correctamente, de lo contrario podría dificultar tu acceso a las zonas residenciales.</p>
                    <p>Este formulario solo se podrá llenar una vez, si requieres hacer cambios, descarga la aplicación de Welcomi en tu celular.</p>
                </div>

                {
                    loading &&
                    <div className="centerCircularProgress">
                        <CircularProgress style={{ 'color': "#ffa31a" }} />
                    </div>
                }

                <div style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                    <button
                        disabled={loading}
                        style={{
                            backgroundColor: Constants.colors.primaryColor,
                            color: "white",
                            borderWidth: 0,
                            padding: 20,
                            borderRadius: 15
                        }}
                    >Enviar formulario</button>
                </div>

                <p style={{ color: "gray", textAlign: "right", marginTop: 15 }}>Al enviar este formulario aceptas nuestras politicas de privacidad y nuestros terminos y condiciones.</p>

            </form>
        </div>
    )
}

export default RegistroUpdateProfile;

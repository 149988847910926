import { CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ADashboard from '../components/Admin/Dashboard/ADashboard';
import AAllLocalities from '../components/Admin/Localities/AllLocalities/AAllLocalities';
import ALocalities from '../components/Admin/Localities/Localities/ALocalities';
import AMailchimp from '../components/Admin/Localities/Mailchimp/AMailchimp';
import ALogin from '../components/Admin/Login/ALogin';
import ALogout from '../components/Admin/Login/ALogout';
import AReview from '../components/Admin/Users/Review/AReview';
import AUsers from '../components/Admin/Users/Users/AUsers';
import { AdminContext } from '../Context/AdminContext';
import LayoutAdmin from '../layouts/LayoutAdmin';
import AppRoute from '../utils/AppRoute';
import Constants from '../utils/Constants';

function AdminNav() {
    const { verifyAuth, isLoggedIn, adminDocument } = useContext(AdminContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        validateSession();
    }, [])

    const validateSession = async () => {
        await verifyAuth();
        setLoading(false)
    }

    if (loading) {
        return <CircularProgress style={{ color: Constants.colors.primaryColor }} />
    }

    return (
        !isLoggedIn && !adminDocument ?
            <>
                <Redirect to='/' />
                <AppRoute
                    exact
                    path="/"
                    component={ALogin} />
            </>
            :
            <LayoutAdmin>
                <Redirect to="/localities" />

                <AppRoute
                    exact
                    path="/dashboard"
                    component={ADashboard}
                />

                <AppRoute
                    exact
                    path="/users"
                    component={AUsers}
                />

                <AppRoute
                    exact
                    path="/users/review"
                    component={AReview}
                />

                <AppRoute
                    exact
                    path="/localities"
                    component={ALocalities}
                />

                <AppRoute
                    exact
                    path="/localities/all"
                    component={AAllLocalities}
                />

                <AppRoute
                    exact
                    path="/mails_mailchimp"
                    component={AMailchimp}
                />

                <AppRoute
                    exact
                    path="/signout"
                    component={ALogout}
                />
            </LayoutAdmin>
    )
}

export default AdminNav;